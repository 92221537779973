// Utils
import { isActionPermitted } from './utils';

const AccessControl = ({
  userPages = [],
  targetPageName,
  targetActions,
  noAccessComponent = null,
  children,
}) => {
  const isPermitted = isActionPermitted(
    userPages,
    targetPageName,
    targetActions
  );
  return isPermitted ? children : noAccessComponent;
};

export default AccessControl;
