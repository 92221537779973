import React from 'react';
import {
  PolygonWorkspaceLoader,
  PolygonWorkspaceLoaderArea,
  PolygonWorkspaceWrapper,
} from './PolygonWorkspace.styles';
import { Heading6 } from '../fonts';
import { COLORS } from '../colors';

export const PolygonWorkspace = ({ children, isLoading, loadingMessage }) => {
  return (
    <PolygonWorkspaceWrapper id='hotcqvtnhw'>
      {isLoading && (
        <PolygonWorkspaceLoaderArea id='grtballjgj'>
          <PolygonWorkspaceLoader id='aylroqaqhm'>
            <div />
            <div />
            <div />
          </PolygonWorkspaceLoader>
          <Heading6 id='cyygolflqy' style={{ color: COLORS.White }}>
            {loadingMessage}
          </Heading6>
        </PolygonWorkspaceLoaderArea>
      )}
      {children}
    </PolygonWorkspaceWrapper>
  );
};
