export const API_URL = {
  // USERS
  getUserInit: () => `/users/init`,
  getUsers: ({ search = '' }) => `/users/list?search=${search}`,
  getUserLogs: id => `/users/${id}/logs`,
  getAllUserIdsInDateRange: () => `/users/all_ids`,
  getAllUserEmailsInDateRange: () => `/users/all_emails`,
  checkUser: email => `/users/${email}/check-user`,
  passwordReset: email => `/users/${email}/password-reset`,
  addUser: () => `/users/add`,
  updateUser: id => `/users/${id}/update`,
  deleteUser: id => `/users/${id}/delete`,
  getTimezone: (city, state) =>
    `locations/timezone?city=${city}&state=${state}`,
  createAgreedTermsLog: () => `/users/terms-agreed`,

  // ROLES
  getRoles: search => `/roles/list?search=${search}`,
  getRoleDetails: id => `/roles/${id}`,
  createRole: () => '/roles/create',
  updateRole: id => `/roles/${id}/update`,
  deleteRole: id => `/roles/${id}/delete`,
  getPagesActions: () => '/roles/pages-actions/list',
  getCompanyRoles: () => '/roles/all',
  getAllInstallerRole: () => `/locations/get_all_installer_role`,

  // LOCATIONS
  getLocationsList: ({ search = '', orderBy = '' }) =>
    `/locations/list?search=${search}&order_by=${orderBy}`,
  getUserTypesStatusesOrganizations: () => `/users/types-statuses-orgs`,
  getUserTypeRole: id => `/roles/${id}/user-type-role`,
  // Locations
  createLocation: () => '/locations/create',
  updateLocation: id => `/locations/${id}/update`,
  deleteLocation: id => `/locations/${id}/delete`,
  getCompanyUsers: () => '/users/all',
  setCompany: id => `/users/set_company/${id}`,
  getLocationEventsList: id => `/locations/${id}/list_events`,
  createLocationEvent: () => '/locations/create_event',
  updateLocationEvent: location_event_id =>
    `/locations/${location_event_id}/update_event`,
  deleteLocationEvent: location_event_id =>
    `/locations/${location_event_id}/delete_event`,
  getAllUserLocations: () => '/locations/all-user',
  getCompanyLocations: () => '/locations/all',

  // GROUPS
  getGroupsList: ({ search = '' }) => `/groups/list?search=${search}`,
  createGroup: () => '/groups/create',
  updateGroup: id => `/groups/${id}/update`,
  deleteGroup: id => `/groups/${id}/delete`,
  getCompanyGroups: () => '/groups/all',

  // CAMERAS
  getCameraList: search => `/cameras/list?search=${search}`,
  getCameraById: id => `/cameras/${id}`,
  getCamerasByLocation: id => `/locations/${id}/cameras`,
  getCamerasListByLocation: (location_id, camera_view) =>
    `/locations/${location_id}/cameras/list?camera_view=${camera_view}`,
  getCameraLiveImage: (id, server) =>
    `/cameras/${id}/live-image?server=${server}`,
  getCameraFootfallImage: id => `/cameras/${id}/footfall-image`,
  createCamera: () => '/cameras/create',
  updateCamera: id => `/cameras/${id}/update`,
  deleteCamera: id => `/cameras/${id}/delete`,
  getCameraTemplateImage: id => `/cameras/${id}/template-image`,
  bulkCameraInsert: () => `/cameras/bulk-camera-insert`,
  bulkUpdateCredentials: () => `cameras/bulk-update-cameras-credentials`,
  getCameraRTSPStatus: id => `/cameras/${id}/rtsp-status`,
  findTemplate: () => '/templates/find',
  cameraPlaneRegistration: id => `/cameras/${id}/camera-plane-registration`,

  // REFERENCE IMAGE
  getLatestReferenceImage: camera_id =>
    `/reference_image/${camera_id}/get_latest_version`,
  getReferenceImage: id => `/reference_image/${id}/get`,
  uploadReferenceImage: id => `/reference_image/${id}/upload`,
  deleteReferenceImage: id => `/reference_image/${id}/delete`,
  savePlaneCalibration: id => `/reference_image/${id}/save_plane`,
  getPlaneLatestCalibration: camera_id =>
    `/reference_image/${camera_id}/get_latest_plane`,
  getPlaneCalibration: id => `/reference_image/${id}/get_plane`,
  getReferenceImageVersions: camera_id =>
    `/reference_image/${camera_id}/get_versions`,

  // PIPELINES
  getPipelineVersions: (search, timestamp_from, timestamp_to) =>
    `/versions/list?search=${search}&timestamp_from=${timestamp_from}&timestamp_to=${timestamp_to}`,

  // CAMERA ASSIST API
  updateCameraAssist: id => `/cameras/${id}/update/camera-assist`,
  getCameraAssist: id => `/cameras/${id}/get/camera-assist`,

  // PLANE CALIBRATION
  updatePlaneCalibration: id => `/cameras/${id}/update/plane-calibration`,

  // SERVERS
  getServerList: search => `/servers/list?search=${search}`,
  createServer: () => '/servers/create',
  updateServer: () => '/servers/update',
  deleteServer: id => `/servers/${id}/delete`,
  prebootstrapServer: id => `/servers/${id}/prebootstrap`,
  getPrebootstrapLog: id => `/servers/${id}/get_prebootstrap_log`,

  //LOCATION STATUS (Older: LOCATION HEALTH)
  getStatusDataByLocation: id => `/locations/${id}/status`,
  getHealthDataByCamera: id => `/cameras/${id}/health`,
  getAdvancedHealthCheck: id => `/cameras/${id}/advanced-health-check`,
  saveLocationStatusFlow: id => `/locations/${id}/save_status_flow`,
  getLocationStatusFlow: id => `/locations/${id}/get_status_flow`,
  getLocationEnvironments: id =>
    `/locations/${id}/get_status_flow_environments`,
  getLocationRuntimeStatus: id => `/locations/${id}/get_runtime_status`,
  getLocationPipelineStatus: id => `/locations/${id}/get_pipeline_status`,

  // CAMERA VALIDATION
  getValidationsByLocation: id => `/validation/location/${id}`,
  requestCameraValidation: id => `/validation/request?camera_id=${id}`,
  sendValidationSupportEmail: cameras =>
    `validation/support-request?cameras=${cameras}`,
  cameraValidated: id => `validation/verified?camera_id=${id}`,
  getCameraValidationComments: id => `validation/comments?camera_id=${id}`,
  addNewComment: id => `/validation/adjustment-required?camera_id=${id}`,
  rollbackValidationForCamera: id => `/validation/camera/${id}/rollback`,

  // VIEW MANAGEMENT:
  getCameraPolygons: (category, cameraId) =>
    `/cameras/polygons/${category}/${cameraId}`,
  updateCameraPolygons: (category, cameraId) =>
    `/cameras/polygons/save/${category}/${cameraId}`,
  getProjectionPolygons: (category, cameraId) =>
    `/cameras/polygons/${category}/${cameraId}`,
  updateProjectionPolygons: (category, cameraId) =>
    `/cameras/polygons/save/${category}/${cameraId}`,

  // TEMPLATE MANAGEMENT
  getTemplates: (id, limit, offset, camera_view, by) =>
    `/${by}/${id}/templates?limit=${limit}&offset=${offset}&camera_view=${camera_view}`,
  getTemplateDetails: templateId => `/templates/${templateId}`,
  deleteTemplate: id => `/templates/${id}/delete`,
  createTemplate: () => '/templates/create',
  createTemplateFork: () => '/forks/create',
  updateTemplate: templateId => `/templates/${templateId}/update`,
  updateTemplateFork: forkId => `/forks/${forkId}/update`,
  getPolygonsByTemplate: id => `/cameras/polygons/camera_objectives/${id}`,
  getCompanyTemplatesBy: (by, id) => `${by}/${id}/all-templates`,
  getTemplateImage: templateId => `templates/${templateId}/image`,
  getTemplateThumbnail: templateId => `templates/${templateId}/thumbnail`,

  // INSTALLATION SUPPORT
  updateInstallationSupport: id => `/installation_support/${id}/update`,
  deleteInstallationSupport: id => `/installation_support/${id}/delete`,

  // STORE LAYOUT
  uploadInstallationPicture: id =>
    `/store_layout/${id}/upload_installation_pictures`,
  getInstallationPictures: (id, level) =>
    `/store_layout/${id}/${level}/get_installation_pictures`,

  // USER WRITE LOGS
  getLogs: (search, startDate, endDate) =>
    `/activities/list?search=${search}&start_date=${startDate}&end_date=${endDate}`,

  // PLANS
  getPlans: () => `/plans/list`,

  // FLOORPLANS
  getEditorFloorplans: id => `/locations/${id}/get_floorplans_for_editor`,
  getFloorplans: id => `/locations/${id}/get_floorplans`,
  uploadFloorplan: id => `/locations/${id}/upload_floorplan`,
  derivateFloorplan: id => `/locations/${id}/derivate_floorplan`,
  updateFloorplan: id => `/floorplan/${id}/update_floorplan`,
  deleteFloorplan: id => `/floorplan/${id}/delete_floorplan`,

  // DEBUG TESTs
  debugTest_1: () => '/users/test1', // :333:
  debugTest_2: () => '/users/test2',

  // Access Keys
  getAccessKeys: () => '/access-keys/list',
  deleteAccessKey: id => `/access-keys/delete/${id}`,
  activateAccessKey: id => `/access-keys/activate/${id}`,
  createAccessKey: () => `/access-keys/create`,

  // ShopAssist Health
  getSAHealth: location_id => `/shop-assist/${location_id}/health`,
};
