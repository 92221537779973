export const SelectComponentCustomStyle = {
  option: provided => ({
    ...provided,
    borderBottom: '1px solid #9BA5B3',
    color: '#03053d80',
  }),
  control: () => ({
    position: 'relative',
    top: '-15px',
    cursor: 'pointer',
  }),
  menu: provided => ({
    ...provided,
    width: 240,
    position: 'absolute',
    right: 30,
    paddingLeft: '10px',
    paddingRight: '10px',
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    transition: 'all .2s ease',
    color: 'black',
    marginTop: 0,
    transform: state.selectProps.menuIsOpen ? 'rotate(-90deg)' : null,
  }),
  indicatorSeparator: base => ({
    ...base,
    opacity: 0,
  }),
  input: base => ({
    ...base,
    opacity: 0,
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0 : 0;
    const transition = 'opacity 300ms';
    return { ...provided, opacity, transition };
  },
};

export const FLOORPLAN_PAGES = {
  CAMERA_VIEW_ASSIST: 'camera_view_assist',
  PLANE_CALIBRATION: 'plane_calibration',
  INSTALL_SUPPORT: 'installation_support',
  PLANE_AND_NORMAL: 'plane_and_normal',
  DETECTION_POLYGON: 'detection_polygon',
  AOI_SELECTION: 'aoi_selection',
  FLOOR_PROJECTION: 'floor_projection',
  FOOTFALL_PROJECTION: 'footfall_projection',
};

export const COLLAPSE_ACTIONS = {
  USER_NOT_INTERACTED: 0,
  COLLAPSED: 1,
  UNFOLD: 2,
};
