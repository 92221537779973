// Libs
import styled from 'styled-components';

// SECTION
// props:
// vertical = 'center', 'start', 'end'
// horizontal = 'center', 'left', 'right'
export const Section = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: ${props =>
    props.vertical === 'center' ? 'center' : `flex-${props.vertical}`};
  align-items: ${props =>
    props.horizontal === 'center' ? 'center' : `flex-${props.horizontal}`};
`;
