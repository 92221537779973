import React from 'react';
import PropTypes from 'prop-types';
import * as icons from './iconList';
import { IconWrapper } from './Icon.style';

export const Icon = ({
  iconName,
  size,
  fill,
  stroke,
  hoverColor,
  onClick,
  ...props
}) => {
  if (!iconName) {
    return null;
  }

  const IconComponent = () => icons[iconName];
  return (
    <IconWrapper
      id='uanmpmaitx'
      onClick={onClick}
      size={size}
      fill={fill}
      stroke={stroke}
      hoverColor={hoverColor}
      {...props}
    >
      <IconComponent id='aqlspcqtdq' />
    </IconWrapper>
  );
};

Icon.propTypes = {
  iconName: PropTypes.string.isRequired,
  size: PropTypes.string,
  fill: PropTypes.string,
  stroke: PropTypes.string,
  hoverColor: PropTypes.string,
  onClick: PropTypes.func,
};

Icon.defaultProps = {
  onClick: null,
  size: 'small',
  fill: 'black',
  stroke: '',
  hoverColor: '',
};
