import styled from 'styled-components';

export const FlexContainer = styled.div`
  justify-content: ${({ justify }) => justify};
  align-items: ${({ align }) => align};
  width: ${({ fluid }) => (fluid ? '100%' : 'auto')};
  width: ${({ width }) => width};
  display: ${({ display }) => display ?? 'flex'};
  gap: ${({ gap }) => gap ?? 0};
  flex-wrap: ${({ wrap }) => wrap && 'wrap'};
  flex-direction: ${({ direction }) => direction ?? 'row'};
  height: ${({ height }) => height};
  z-index: ${({ zindex }) => zindex};
  color: ${({ color }) => color};
`;
