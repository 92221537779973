export const QUERY_KEYS = {
  SERVERS: 'servers',
  CAMERAS: 'cameras',
  CAMERA: 'camera',
  CAMERA_POLYGONS: 'camera polygons',
  LOCATION: 'location',
  LOCATION_EVENTS: 'location events',
  TEMPLATES: 'templates',
  TEMPLATE_DETAILS: 'template details',
  USERS: 'users',
  TYPES_STATUSES_ORGS: 'types-statuses-organizations',
  COMPANY_USERS: 'company users',
  USER_INFO: 'userInfo',
  LOCATIONS: 'locations',
  TIMEZONE: 'timezone',
  GROUPS: 'groups',
  COMPANY_GROUPS: 'company groups',
  LIVE_IMAGE: 'liveImage',
  FOOTFALL_IMAGE: 'footfallImage',
  LIVE_IMAGES: 'liveImages',
  GET_CAMERA_ASSIST: 'getCameraAssist',
  UPDATE_CAMERA_ASSIST: 'updateCameraAssist',
  GLOBAL_LOCATION: 'global locations',
  GLOBAL_TEMPLATE: 'global templates',
  LOCATION_PIPELINE_STATUS: 'location_pipeline_status',
  USER_LOCATIONS: 'user locations',
  USER_GROUPS: 'user groups',
  CAMERA_PIPELINE: 'camera pipeline',
  LOCATION_CAMERAS: 'location camera',
  CAMERAS_LIST: 'cameras list',
  CAMERA_TEMPLATE: 'camera template',
  CAMERA_TEMPLATES: 'camera templates',
  LOCATION_STATUS_FLOW: 'location status flow',
  CAMERA_HEALTH: 'camera health',
  ROLES: 'roles',
  COMPANY_ROLES: 'company roles',
  PAGES_AND_ACTIONS: 'pages and actions',
  COMPANY_LOCATIONS: 'company locations',
  LOGS: 'LOGS',
  BMC_SERVER: 'BMC_SERVER',
  PIPELINE_VERSIONS: 'PIPELINE_VERSIONS',
  TEMPLATE_POLYGONS: 'TEMPLATE_POLYGONS',
  TEMPLATES_POLYGONS_LIST: 'TEMPLATES_POLYGONS_LIST',
  TEMPLATE_IMAGE: 'TEMPLATE_IMAGE',
  TEMPLATE_THUMBNAIL: 'TEMPLATE_THUMBNAIL',
  PLANS: 'PLANS',
  COMPANIES: 'COMPANIES',
  RTSP_STATUS: 'RTSP_STATUS',
  GET_PLANE_CALIBRATION: 'getPlaneCalibration',
  UPDATE_PLANE_CALIBRATION: 'updatePlaneCalibration',
  TERMS: 'TERMS',
  REFERENCE_IMAGE: 'REFERENCE_IMAGE',
  REFERENCE_IMAGE_VERSIONS: 'REFERENCE_IMAGE_VERSIONS',
  REFERENCE_IMAGES: 'REFERENCE_IMAGES',
  CAMERA_VALIDATIONS: 'CAMERA_VALIDATIONS',
  STATUS_DATA: 'status data',
  ACCESS_KEYS: 'access_keys',
  ACCESS_KEYS_ACTIVATE: 'access_keys_activate',
  ACCESS_KEYS_DEACTIVATE: 'access_keys_decativate',
  SHOP_ASSIST: 'shop_assist',
};
