import React from 'react';
import { Range as ReactRang } from 'react-range';
import { StyledBodyText, Wrapper } from './styles';

const Range = ({
  step = 1,
  min = 0,
  max = 100,
  value = [0],
  disabled,
  onChange,
  showStatus,
}) => {
  return (
    <Wrapper id='lmgbmhfdvh'>
      <ReactRang
        id='gdgwwxeriw'
        step={step}
        min={min}
        max={max}
        values={value}
        disabled={disabled}
        onChange={values => onChange(values)}
        renderTrack={({ props, children }) => (
          <div
            {...props}
            style={{
              ...props.style,
              background: `${disabled ? 'gray' : '#1D7AFD'}`,
              height: '7px',
              width: '118px',
              position: 'relative',
              borderRadius: '10px',
            }}
          >
            <div
              style={{
                position: 'absolute',
                top: 0,
                right: '-2px',
                height: '110%',
                width: `${100 - (value[0] * 100) / max}%`,
                border: '1px solid #E5E5E5',
                borderRadius: '10px',
                backgroundColor: `${disabled ? 'gray' : '#fff'}`,
              }}
            >
              {' '}
            </div>
            {children}
          </div>
        )}
        renderThumb={({ props }) => (
          <div
            {...props}
            style={{
              ...props.style,
              height: '16px',
              width: '16px',
              borderRadius: '50%',
              background: `${disabled ? 'gray' : '#1D7AFD'}`,
              backgroundColor: `${disabled ? 'gray' : '#1D7AF'}`,
            }}
          />
        )}
      />
      {showStatus && (
        <StyledBodyText id='fcztvssmvg'>{`${
          value[0] * showStatus
        }%`}</StyledBodyText>
      )}
    </Wrapper>
  );
};

export default Range;
