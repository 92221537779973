import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useRef, useState } from 'react';
import cookie from 'react-cookies';
import { Typography } from '@mui/material';
import { useAppContext } from '../../AppContext';
import { variables } from '../../utils/variables';
import { ChevronIcon, LogoutIcon } from '../icons';
import { FlexContainer } from '../flexContainer';
import { FlexItem } from '../flexItem';
import {
  PopperWrapper,
  SemiBoldHeading6,
  StyledBodyText,
  UserMenuPopper,
  UserMenuWrapper,
} from './styles';

import { COOKIE_COMPANY_ID } from '../../constants';

const UserMenu = () => {
  const { logout, user } = useAuth0();
  const [isOpen, setIsOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const ref = useRef();

  const { userInfo, removeLocalStorageCacheKey } = useAppContext();

  useEffect(() => {
    if (isOpen) {
      const clickOutside = e => {
        const ignoreClickOnMeElement = ref.current;
        const isClickInsideElement = ignoreClickOnMeElement?.contains(e.target);
        if (!isClickInsideElement) {
          setIsOpen(false);
        }
      };
      document.addEventListener('click', clickOutside);
      return () => document.removeEventListener('click', clickOutside);
    }
  }, [isOpen]);

  const handleOpenMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = () => {
    localStorage.clear();
    cookie.remove(COOKIE_COMPANY_ID);
    removeLocalStorageCacheKey();
    logout({ returnTo: variables().redirectUri });
  };

  const UnclassifiefUser = () => (
    <Typography color='red' variant='body2'>
      Unclassified User
    </Typography>
  );
  const SuperAdminUser = () => (
    <Typography color='red' variant='body2'>
      Super Admin
    </Typography>
  );

  const ShowUserType = ({ ...props }) => {
    if (props.user_info?.delegated_user_type_role)
      return (
        <Typography color='grey' variant='body2'>
          {props.user_info?.delegated_user_type_role}
        </Typography>
      );
    if (userInfo?.is_superadmin) return <SuperAdminUser />;
    return <UnclassifiefUser />;
  };

  return (
    <FlexItem id='prkhyxtjua'>
      <PopperWrapper id='hpaddeimfk' ref={ref}>
        <UserMenuWrapper
          id='spmjghbdjl'
          data-testid='clickable_wrapper'
          onClick={handleOpenMenu}
        >
          <FlexContainer id='vdxibksiux' align='center' gap='20px'>
            <FlexItem id='hgdnnwuxur'>
              <SemiBoldHeading6
                id='dkohwvtkkb'
                title={userInfo?.company?.project_name}
              >
                {user?.email ?? ''}
              </SemiBoldHeading6>
              <Typography align='center' style={{ color: 'grey' }}>
                <ShowUserType user_info={userInfo} />
              </Typography>
            </FlexItem>
            <FlexContainer id='buadsliums'>
              <ChevronIcon
                id='uxeridrhjl'
                color={isHovered ? 'blue' : 'softBlack'}
                size='extraTiny'
                rotateDegrees={isOpen ? 'neg180' : '0'}
              />
            </FlexContainer>
          </FlexContainer>
        </UserMenuWrapper>
        {isOpen && (
          <UserMenuPopper
            id='vzaddvtqvn'
            onClick={handleLogout}
            onMouseOver={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            isHovered={isHovered}
            data-testid='logoutLabel'
          >
            <FlexContainer id='zlkqiyfiqo' align='center'>
              <FlexItem id='digycqvyux' style={{ marginRight: '10px' }}>
                <LogoutIcon
                  id='mdbpvpnval'
                  size='small'
                  color={isHovered ? 'blue' : 'black'}
                />
              </FlexItem>
              <FlexItem id='nfuxxtczyu'>
                <StyledBodyText id='qtitnqccfc' isHovered={isHovered}>
                  Logout
                </StyledBodyText>
              </FlexItem>
            </FlexContainer>
          </UserMenuPopper>
        )}
      </PopperWrapper>
    </FlexItem>
  );
};

export default UserMenu;
