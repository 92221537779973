// Libs
import styled from 'styled-components';
// Constants
import { COLORS } from '../colors';

export const PolygonsEditorWrapper = styled.div`
  width: 100%;
`;

export const PolygonsCameraImage = styled.img`
  width: 100%;
  height: auto;
`;

export const PolygonsWorkspace = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const PolygonsStage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
`;

export const PolygonsInfo = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(27, 39, 51, 0.15);
`;

export const PolygonItemWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 21px;

  &:first-child {
    padding-top: 16px;
  }
  &:last-child {
    padding-bottom: 20px;
  }
`;

export const PolygonInputWrapper = styled.div`
  position: relative;
`;

export const PolygonItemColorRect = styled.div`
  height: 32px;
  width: 32px;
  margin-right: 16px;
  background-color: ${({ color }) => color};
  border: 1px solid #9ba5b3;
  border-radius: 6px;
`;

export const PolygonItemColorPickableRect = styled.input`
  height: 32px;
  width: 32px;
  margin-right: 16px;
  background-color: ${({ color }) => color};
  border: 1px solid #9ba5b3;
  border-radius: 6px;
`;

export const PolygonItemActions = styled.div`
  display: flex;
  gap: 8px;
  margin-left: 16px;
  & svg {
    cursor: pointer;
  }
  &:hover {
    background-color: ${COLORS.SecondaryRed};
  }
`;

export const PolygonItemDeleteAction = styled.div`
  margin-left: 16px;
  margin-top: 12px;
  & svg {
    cursor: pointer;
  }
  &:hover {
    background-color: ${COLORS.SecondaryRed};
  }
`;

export const PolygonCTAWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  z-index: 1000;
`;

export const PolygonCTACircle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 120px;
  height: 120px;

  text-align: center;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  padding: 14px;
`;

export const PolygonErrorText = styled.span`
  position: absolute;
  bottom: -14px;
  display: block;
  width: 100%;
  font-size: 12px;
  text-align: left;
  line-height: 14px;
  font-weight: 400;
  letter-spacing: 0.5px;
  color: #ff3b30;
`;

export const ControlElement = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const Controls = styled.div`
  display: flex;
  flex-direction: row;
  gap: 6px;
  padding: 20px 24px;
`;

export const PolygonsInfoAOI = styled.div`
  display: flex;
  justify-content: space-between;
  border: 1px solid rgba(27, 39, 51, 0.15);
`;

export const Polygons = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ToggleWrapper = styled.div`
  margin-top: 4px;
  margin-left: 5px;
`;

export const RangeTitleWrapper = styled.div`
  margin-left: 10px;
`;
