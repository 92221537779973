import React, { useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styled from 'styled-components';
import { useAppContext } from '../../AppContext';
import Message from './Message';

const StyledToaster = styled(ToastContainer)`
  &&&.Toastify__toast-container {
    width: 400px;
    border-radius: 3px;
  }
`;

const Toaster = () => {
  const { activeNotify, setActiveNotify } = useAppContext();

  const onToastClose = () => {
    setActiveNotify(null);
  };

  useEffect(() => {
    if (activeNotify) {
      toast(<Message id='zztjsuhrzf' {...activeNotify} />, {
        onClose: onToastClose,
      });
    }
  }, [activeNotify]);

  return (
    <StyledToaster
      id='lqmgroihol'
      position='bottom-right'
      autoClose={5000}
      hideProgressBar
      newestOnTop
      closeOnClick
      draggable
      pauseOnHover
    />
  );
};

export default Toaster;
