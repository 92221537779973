import React from 'react';
import { BodyText, FieldLabel } from '../fonts';
import { FlexContainer } from '../flexContainer';

export const DialogField = ({ label, value, isConflict }) => {
  return (
    <FlexContainer id='nsukeruwic' direction='column' gap='8px' fluid>
      <FieldLabel id='yzspvhegrg'>{label}</FieldLabel>
      {isConflict ? (
        <BodyText id='xxergdnkhv' color='red'>
          {value}
        </BodyText>
      ) : (
        <BodyText id='safoesndjp'>{value}</BodyText>
      )}
    </FlexContainer>
  );
};
