import axios from 'axios';
import { variables } from '../utils/variables';

axios.defaults.withCredentials = true;

export const api = axios.create({
  baseURL: variables().backend || 'http://localhost:8080/api/v1',
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

const apiFetch = async (url, data, config) => {
  const startTime = Math.floor(Date.now());
  return api.post(url, data, config).then(res => {
    const endTime = Math.floor(Date.now());
    const timeDiffMiliSeconds = endTime - startTime;
    if (typeof res.data === 'object') {
      res.data.response_time = timeDiffMiliSeconds;
    }
    return res.data;
  });
};

export default apiFetch;

/*
  Query time Usage:
  if (userInfo?.DEBUG && data?.response_time)
      console.log(`<fetcher>QueryTime [/users/list]: ${data.response_time} ms.`);
*/
