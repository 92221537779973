import React from 'react';
import { SidebarSubLinkWrapper } from './Sidebar.style';

const SidebarSubLink = ({ item = {}, onClick }) => {
  const { isActive } = item;

  return (
    <SidebarSubLinkWrapper
      id='ywxtxdfwdv'
      isActive={isActive}
      onClick={() => onClick(item)}
    >
      {item?.name}
    </SidebarSubLinkWrapper>
  );
};

export default SidebarSubLink;
