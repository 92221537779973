import styled from 'styled-components';

export const FlexItem = styled.div`
  flex: ${({ flex }) => flex};
  width: ${({ fluid }) => (fluid ? '100%' : 'auto')};
  width: ${({ width }) => width};
  flex-basis: ${({ basis }) => basis};
  order: ${({ order }) => order};
  align-self: ${({ alignSelf }) => alignSelf};
  justify-self: ${({ justifySelf }) => justifySelf};
  flex-grow: ${({ grow }) => grow};
  color: ${({ color }) => color};
  height: ${({ height }) => height};
`;
