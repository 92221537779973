import React from 'react';
import PropTypes from 'prop-types';
import { PolygonCTACircle, PolygonCTAWrapper } from './PolygonsEditor.styles';
import { ClickCursorIcon } from '../icons';
import { BodyText2 } from '../fonts';

const PolygonCTA = ({ setIsDummyClicked }) => (
  <PolygonCTAWrapper
    id='nfnkmbyqrs'
    data-testid='PolygonCTA'
    onClick={() => setIsDummyClicked(true)}
  >
    <PolygonCTACircle id='uvofyoyqxb'>
      <ClickCursorIcon
        id='xcqglwtmod'
        color='secondaryBlue'
        hoverColor='secondaryBlue'
        size='small'
      />
      <BodyText2 id='hjhshtokij' style={{ marginTop: '10px' }}>
        Click on the image to start drawing a polygon
      </BodyText2>
    </PolygonCTACircle>
  </PolygonCTAWrapper>
);

PolygonCTA.propTypes = {
  setIsDummyClicked: PropTypes.func.isRequired,
};

export default PolygonCTA;
