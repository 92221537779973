// Libs
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import React, { lazy, Suspense } from 'react';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
// Routes
import { routeService } from './routes/routesList';
import { useAppContext } from './AppContext';
// Components
import ProtectedRoute from './components/protectedRoute/ProtectedRoute';
import Toaster from './components/notification/Notification';
import Loader from './components/Loader/Loader';
import Header from './components/header/Header';
// Styles
import './styles/variables.css';
import './styles/reset.css';
import './styles/animations.css';
import './styles/primereact.min.css';
import './styles/primeflex.min.css';
// import './styles/primeicons-static.css';
import 'primereact/resources/themes/lara-light-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import styles from './App.module.css';

const Home = lazy(() => import('./pages/home'));
const Admin = lazy(() => import('./routes'));
const Welcome = lazy(() => import('./pages/welcome/Welcome'));
const LogoutPage = lazy(() => import('./pages/logout/LogoutPage'));
const NoEmailVerified = lazy(() => import('./pages/noEmailVerified'));
const NoAccess = lazy(() => import('./pages/noAccess'));
const NoAccessUsersPage = lazy(() => import('./pages/noAccessUsersPage'));
const TermsAndConditions = lazy(() => import('./pages/terms'));

const options = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
};

const App = () => {
  const {
    list: {
      home,
      app,
      no_email_verified,
      no_access,
      no_access_userandrole_page,
      terms,
    },
    getRelativePath,
    getPath,
  } = routeService;

  const { isAuthenticated, isLoading } = useAuth0();
  const { userInfo } = useAppContext();

  const { path } = useRouteMatch();

  const homeRoute = getRelativePath({ path, item: home });
  const appRoute = getRelativePath({ path, item: app });
  const noAccessRoute = getPath({ item: no_access });
  const noAccessUsersPageRoute = getPath({ item: no_access_userandrole_page });
  const noEmailVerifiedRoute = getPath({ item: no_email_verified });
  const termsAndConditionsRoute = getPath({ item: terms });

  if (isLoading) {
    return <Loader id='armkjzasay' alt='Loading...' />;
  }

  return (
    <PostHogProvider
      apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
      options={options}
      client={posthog.identify(userInfo?.email)}
    >
      <div className={styles.app}>
        <div className={styles.Content}>
          {isAuthenticated && <Header id='zakxjdbqen' />}
          <Switch id='msfqeimbda'>
            {isAuthenticated && (
              <Redirect id='ckfphrykyq' exact from='/' to={homeRoute} />
            )}
            <Suspense
              id='vhdgfjzjth'
              fallback={<Loader id='mwpvrgdyku' alt='Loading...' />}
            >
              <Route
                id='kafojpsuwy'
                exact
                path='/logout'
                component={LogoutPage}
              />
              <Route id='kafojpsuwy' exact path='/' component={Welcome} />
              <ProtectedRoute
                id='idohjwnzqd'
                exact
                path={homeRoute}
                component={Home}
              />
              <ProtectedRoute
                id='uoisishixn'
                path={appRoute}
                component={Admin}
              />
              <Route
                id='kbnrccixdk'
                path={noEmailVerifiedRoute}
                component={NoEmailVerified}
              />
              <Route
                id='npschebdzi'
                path={noAccessRoute}
                component={NoAccess}
              />
              <Route
                id='npschebdzi'
                path={noAccessUsersPageRoute}
                component={NoAccessUsersPage}
              />
              <Route
                id='ewdppkeced'
                path={termsAndConditionsRoute}
                component={TermsAndConditions}
              />
            </Suspense>
          </Switch>
        </div>
        <Toaster id='eqkfzjeuvd' />
      </div>
    </PostHogProvider>
  );
};

export default App;
