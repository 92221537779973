// Libs
import styled from 'styled-components';
// Constants
import { COLORS } from '../colors';

// INPUT
// props:
// size: normal, small
export const Input = styled.input`
  width: ${({ size }) => (size === 'small' ? '200px' : '100%')};
  height: 46px;

  margin-top: 4px;
  padding: ${({ size }) => (size === 'small' ? '8px 12px' : '14px 12px')};
  background: ${COLORS.White};
  border: 1px solid ${({ error }) => (error ? COLORS.ErrorRed : COLORS.Gray2)};
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: ${({ error }) =>
    error ? `0px 0px 0px 2px ${COLORS.ErrorShadow}` : 'none'};

  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: ${COLORS.TextBlack};
  outline: none;

  opacity: ${props => (props.disabled ? '65%' : '100%')};

  &::placeholder {
    color: ${COLORS.MainBlue};
  }

  &:hover {
    box-shadow: ${({ isValidate }) =>
      !isValidate && `0 0 0 2px ${COLORS.HoverGray}`};
  }

  &:focus {
    box-shadow: 0 0 0 2px ${COLORS.FocusBlue};
    border: 1px solid ${COLORS.MainBlue};
  }

  &:focus::-webkit-contacts-auto-fill-button {
    visibility: hidden;
    position: absolute;
  }

  &:focus::-webkit-credentials-auto-fill-button {
    visibility: hidden;
    position: absolute;
  }
`;

export const Radio = styled.input.attrs({ type: 'radio' })`
  opacity: ${props => (props.disabled ? '65%' : '100%')};
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  margin: 2px 5px 0 0;

  border-radius: 50%;
  width: 16px;
  height: 16px;
  border: 2px solid #dfe1e6;
  transition: 0.2s all linear;
  position: relative;
  cursor: pointer;

  &:checked {
    border: 6px solid #1268fb;
  }
`;
