import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash.isempty';
import { FlexContainer } from '../flexContainer';
import { FlexItem } from '../flexItem';
import {
  ClearButton,
  SearchBarContainer,
  SearchIconWrapper,
  SearchInputWrapper,
} from './SearchBar.style';
import { COLORS } from '../colors';
import { Icon } from '../iconComponent/Icon';
import { Input } from '../inputs';
import { StyledButton } from '../buttons';

const MIN_CHARACTERS = 1;

const SearchBar = ({ onButtonClick, onClearClick, value, onChange }) => {
  const showClearButton = !isEmpty(value);
  const submitEnabled = value.length >= MIN_CHARACTERS;

  const onKeyPress = e => {
    if (e.which === 13 && submitEnabled) {
      onButtonClick(e);
    }
  };

  return (
    <SearchBarContainer id='vprkeomklr'>
      <FlexContainer id='dertdvcaaj' align='center'>
        <FlexItem id='xsqfzgitfl'>
          <SearchInputWrapper id='rftbpluvju'>
            <SearchIconWrapper id='iqgyvcszua'>
              <Icon
                id='uomvrdnjdg'
                size='small'
                iconName='SearchSimpleIcon'
                fill={COLORS.MainBlue}
              />
            </SearchIconWrapper>
            <Input
              id='search-bar'
              name='search-bar'
              placeholder='Search'
              onChange={event => onChange(event.target)}
              onKeyPress={onKeyPress}
              value={value}
              style={{ paddingLeft: '32px', marginTop: 0 }}
            />
            {showClearButton && (
              <ClearButton
                id='eobtlmhggo'
                data-testid='clear-button'
                onClick={onClearClick}
              >
                <Icon
                  id='ncgxbwvlxh'
                  size='small'
                  iconName='CrossIcon'
                  fill={COLORS.Stroke}
                  hoverColor={COLORS.MainBlue}
                />
              </ClearButton>
            )}
          </SearchInputWrapper>
        </FlexItem>
        <FlexItem id='yhxxoiezju'>
          <StyledButton
            label='Search'
            className='p-button-raised mr-2 mb-2'
            onClick={onButtonClick}
            disabled={!submitEnabled}
          />
        </FlexItem>
      </FlexContainer>
    </SearchBarContainer>
  );
};

SearchBar.propTypes = {
  onButtonClick: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onClearClick: PropTypes.func.isRequired,
};

export default SearchBar;
