import styled from 'styled-components';
import { COLORS } from '../colors';
import { BodyText, Heading6 } from '../fonts';
import { SelectComponent } from '../selects';

export const StyledHeader = styled.header`
  width: 100%;
  height: 80px;
  position: absolute;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  padding: 16px 20px;
  background-color: ${COLORS.White};
  box-shadow: 0px 4px 10px ${COLORS.BlackAlpha10};
`;

export const StyledSelect = styled(SelectComponent)`
  width: 200px;
  line-height: normal;
  margin-left: 10px;
`;

export const SemiBoldHeading6 = styled(Heading6)`
  font-weight: 600;
`;

export const UserMenuWrapper = styled.div`
  cursor: pointer;
  position: relative;
`;

export const UserMenuPopper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: start;
  z-index: 1000;
  width: 100%;
  height: 40px;
  padding: 0 15px;
  background-color: ${({ isHovered }) =>
    isHovered ? COLORS.SecondaryBlue4 : COLORS.White};
  transition: background-color 0.5s;
  cursor: pointer;
`;

export const PopperWrapper = styled.div`
  position: relative;
`;

export const StyledBodyText = styled(BodyText)`
  color: ${({ isHovered }) => (isHovered ? COLORS.MainBlue : COLORS.TextBlack)};
  transition: color 0.5s;
`;
