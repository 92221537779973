import styled from 'styled-components';

export const FieldWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const PasswordWrapper = styled.div`
  position: relative;
`;

export const EyeWrapper = styled.div`
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: calc(50% + 2px);
  transform: translate(0, -50%);
`;
