import styled from 'styled-components';
import { COLORS } from '../colors';

const Separator = styled.div`
  display: block;
  width: ${({ vertical }) => (vertical ? '1px' : '100%')};
  height: ${({ vertical }) => (vertical ? '100%' : '1px')};
  background-color: ${COLORS.NeutralGray};
`;

export default Separator;
