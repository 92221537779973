/* eslint-disable no-empty */

import React, { useEffect } from 'react';
import { Layer, Stage, Line, Circle } from 'react-konva';
import debounce from 'lodash.debounce';
import {
  checkIsPointNearStartPoint,
  convertPolygonsToRectangleView,
  convertPolygonsToCoordinatePatch,
  denormalizePoint,
  normalizePoint,
  PIXEL_RADIUS,
  convertPointsToCornerDetection,
} from '../utils';
import { convertToRectangleObject } from '../utilsConverters';
import Rectangles from '../rectangle/Rectangles';

const PlaneStage = ({
  stageWidth,
  stageHeight,
  planeCalibrationOutput,
  showGrid,
  isPolygonDrawing,
  setIsPolygonDrawing,
  polygons,
  setPolygons,
  handleUpdatePlaneCalibration,
  isUserActionPermitted,
  enableCornerSelection,
  cornerPoints,
  setCornerPoints,
  runOrder,
  isUpdatingPlane,
  setRunOrder,
  showLiveImage,
  isReferenceImageAvailable,
  isLastVersion,
}) => {
  const RES_X = 1920;
  const RES_Y = 1440;
  const SCALE_X = RES_X / stageWidth;
  const SCALE_Y = RES_Y / stageHeight;

  const createNewPolygon = point => {
    setIsPolygonDrawing(true);
    const newPolygon = convertToRectangleObject();
    const [normalizedX, normalizedY] = normalizePoint(
      stageWidth,
      stageHeight,
      point
    );

    newPolygon.x = normalizedX;
    newPolygon.y = normalizedY;
    setPolygons([...polygons, newPolygon]);
    setRunOrder([...runOrder, 1]);
  };

  const createNewPoint = point => {
    setIsPolygonDrawing(false);

    const [normalizedX, normalizedY] = normalizePoint(
      stageWidth,
      stageHeight,
      point
    );

    const x = normalizedX;
    const y = normalizedY;
    setCornerPoints([...cornerPoints, [x, y]]);
    setRunOrder([...runOrder, 0]);
  };

  useEffect(() => {
    let tmpPolygonArray;

    try {
      tmpPolygonArray = Array(
        planeCalibrationOutput?.patch_coordinates?.length % 4
      ).fill(convertToRectangleObject());
    } catch (error) {
      tmpPolygonArray = Array(0).fill(convertToRectangleObject());
    }

    for (
      let i = 0;
      i < planeCalibrationOutput?.patch_coordinates?.length;
      i += 4
    ) {
      const newPolygon = convertToRectangleObject();
      const point1 = normalizePoint(1920, 1440, [
        planeCalibrationOutput?.patch_coordinates[i],
        planeCalibrationOutput?.patch_coordinates[i + 1],
      ]);
      const point2 = normalizePoint(1920, 1440, [
        planeCalibrationOutput?.patch_coordinates[i + 2],
        planeCalibrationOutput?.patch_coordinates[i + 3],
      ]);
      // eslint-disable-next-line prefer-destructuring
      newPolygon.x = point1[0];
      // eslint-disable-next-line prefer-destructuring
      newPolygon.y = point1[1];
      // eslint-disable-next-line prefer-destructuring
      newPolygon.finishX = point2[0];
      // eslint-disable-next-line prefer-destructuring
      newPolygon.finishY = point2[1];
      newPolygon.width = point2[0] - point1[0];
      newPolygon.height = point2[1] - point1[1];
      newPolygon.isClosed = true;
      newPolygon.id = i;
      tmpPolygonArray.push(newPolygon);
    }

    setPolygons(tmpPolygonArray);

    let tmpCornerPointArray;

    try {
      tmpCornerPointArray = Array(
        planeCalibrationOutput?.corner_points?.length % 2
      ).fill([]);
    } catch (error) {
      tmpCornerPointArray = Array(0).fill([]);
    }

    for (let i = 0; i < planeCalibrationOutput?.corner_points?.length; i += 2) {
      const point = normalizePoint(1920, 1440, [
        planeCalibrationOutput?.corner_points[i],
        planeCalibrationOutput?.corner_points[i + 1],
      ]);

      tmpCornerPointArray.push(point);
    }
    setCornerPoints(tmpCornerPointArray);
    if (planeCalibrationOutput?.run_order) {
      setRunOrder(planeCalibrationOutput?.run_order);
    } else {
      const tmpRunOrderArray = [];
      for (
        let i = 0;
        i < planeCalibrationOutput?.patch_coordinates?.length;
        i += 4
      )
        tmpRunOrderArray.push(1);
      setRunOrder(tmpRunOrderArray);
    }
  }, [planeCalibrationOutput]);

  const handleMouseDown = event => {
    if (!enableCornerSelection) {
      setIsPolygonDrawing(true);
      try {
        const { x, y } = event?.target?.getStage()?.getPointerPosition();
        createNewPolygon([x, y]);
      } catch (error) {}
    } else {
      setIsPolygonDrawing(false);

      const { x, y } = event?.target?.getStage()?.getPointerPosition();
      createNewPoint([x, y]);
    }
  };
  const debouncingHandleMouseMove = debounce(
    event => handleMouseMove(event),
    4
  );

  const handleMouseMove = event => {
    if (isPolygonDrawing && !enableCornerSelection) {
      const { x, y } = event.target.getStage().getPointerPosition();
      const polygonsCopy = [...polygons];
      const lastPolygon = polygonsCopy[polygonsCopy.length - 1];
      const firstPointX = lastPolygon?.x;
      const firstPointY = lastPolygon?.y;
      const [normalizedCurrentX, normalizedCurrentY] = normalizePoint(
        stageWidth,
        stageHeight,
        [x, y]
      );
      lastPolygon.isClosed = true;
      lastPolygon.x = firstPointX;
      lastPolygon.y = firstPointY;
      lastPolygon.width = normalizedCurrentX - firstPointX;
      lastPolygon.height = normalizedCurrentY - firstPointY;

      setPolygons(polygonsCopy);
    }
  };
  const handleMouseUp = event => {
    if (!enableCornerSelection) {
      setIsPolygonDrawing(false);

      const polygonsCopy = [...polygons];
      const cornerPointsCopy = [...cornerPoints];
      const runOrderCopy = [...runOrder];

      const validPolygons = polygonsCopy.slice(0, -1);
      const validRunOrder = runOrderCopy.slice(0, -1);
      const validCornerPoints = cornerPointsCopy.slice(0, -1);

      const lastPolygon = polygonsCopy[polygonsCopy.length - 1];
      const firstPointX = lastPolygon.x;
      const firstPointY = lastPolygon.y;
      const { x, y } = event.target.getStage().getPointerPosition();
      const [normalizedCurrentX, normalizedCurrentY] = normalizePoint(
        stageWidth,
        stageHeight,
        [x, y]
      );
      // In case if a finish point is near the start point
      // then we draw the small rectangle
      const denormalizedFirstPoint = denormalizePoint(stageWidth, stageHeight, [
        firstPointX,
        firstPointY,
      ]);
      if (checkIsPointNearStartPoint(denormalizedFirstPoint, [x, y])) {
        const newFinishX = normalizedCurrentX + PIXEL_RADIUS / stageWidth;
        const newFinishY = normalizedCurrentY + PIXEL_RADIUS / stageWidth;
        lastPolygon.finishX = newFinishX;
        lastPolygon.finishY = newFinishY;
        lastPolygon.width = newFinishX - firstPointX;
        lastPolygon.height = newFinishY - firstPointY;
      } else {
        lastPolygon.finishX = normalizedCurrentX;
        lastPolygon.finishY = normalizedCurrentY;
        lastPolygon.width = normalizedCurrentX - firstPointX;
        lastPolygon.height = normalizedCurrentY - firstPointY;
      }
      lastPolygon.isClosed = true;
      lastPolygon.x = firstPointX;
      lastPolygon.y = firstPointY;

      setPolygons(polygonsCopy);
      const payloadPolygons = convertPolygonsToCoordinatePatch(
        stageWidth,
        stageHeight,
        polygonsCopy
      );
      const payloadCornerPoints = convertPointsToCornerDetection(
        stageWidth,
        stageHeight,
        cornerPointsCopy
      );

      handleUpdatePlaneCalibration(
        payloadPolygons,
        payloadCornerPoints,
        validPolygons,
        validRunOrder,
        validCornerPoints,
        true
      );
    } else {
      const polygonsCopy = [...polygons];
      const runOrderCopy = [...runOrder];
      const cornerPointsCopy = [...cornerPoints];

      const validPolygons = polygonsCopy.slice(0, -1);
      const validRunOrder = runOrderCopy.slice(0, -1);
      const validCornerPoints = cornerPointsCopy.slice(0, -1);

      const payloadPolygons = convertPolygonsToCoordinatePatch(
        stageWidth,
        stageHeight,
        polygonsCopy
      );
      const payloadCornerPoints = convertPointsToCornerDetection(
        stageWidth,
        stageHeight,
        cornerPointsCopy
      );

      handleUpdatePlaneCalibration(
        payloadPolygons,
        payloadCornerPoints,
        validPolygons,
        validRunOrder,
        validCornerPoints,
        false
      );
    }
  };

  const stageController = () => {
    if (
      isUserActionPermitted &&
      !isUpdatingPlane &&
      !showLiveImage &&
      isReferenceImageAvailable &&
      isLastVersion
    ) {
      return {
        onMouseDown: handleMouseDown,
        onMouseMove: debouncingHandleMouseMove,
        onMouseUp: handleMouseUp,
      };
    }
  };

  return (
    <Stage
      id='tssynumvpz'
      width={stageWidth}
      height={stageHeight}
      {...stageController()}
    >
      <Layer id='jzxzdmuqbs'>
        {planeCalibrationOutput?.grid_x?.map((line, index) => {
          const points = line.split(',');
          points[0] = parseFloat(points[0]) / SCALE_X;
          points[1] = parseFloat(points[1]) / SCALE_Y;
          points[2] = parseFloat(points[2]) / SCALE_X;
          points[3] = parseFloat(points[3]) / SCALE_Y;

          return (
            showGrid && (
              <Line id='coflipfeis' key={index} points={points} stroke='red' />
            )
          );
        })}
        {planeCalibrationOutput?.grid_y?.map((line, index) => {
          const points = line.split(',');
          points[0] = parseFloat(points[0]) / SCALE_X;
          points[1] = parseFloat(points[1]) / SCALE_Y;
          points[2] = parseFloat(points[2]) / SCALE_X;
          points[3] = parseFloat(points[3]) / SCALE_Y;

          return (
            showGrid && (
              <Line id='ggavvppiie' key={index} points={points} stroke='red' />
            )
          );
        })}
        {planeCalibrationOutput?.matched_lines?.map((line, index) => {
          const points = line.split(',');
          points[0] = parseFloat(points[0]) / SCALE_X;
          points[1] = parseFloat(points[1]) / SCALE_Y;
          points[2] = parseFloat(points[2]) / SCALE_X;
          points[3] = parseFloat(points[3]) / SCALE_Y;

          return (
            <Line id='xleplwyoay' key={index} points={points} stroke='green' />
          );
        })}

        {cornerPoints?.map((point, index) => {
          const [x, y] = denormalizePoint(stageWidth, stageHeight, [
            point[0],
            point[1],
          ]);
          return (
            <Circle
              id='hizbesdlsv'
              x={x}
              y={y}
              key={index}
              fill='blue'
              stroke='blue'
              radius='4'
            />
          );
        })}

        <Rectangles
          id='xhgirpblnn'
          polygons={convertPolygonsToRectangleView(
            stageWidth,
            stageHeight,
            polygons
          )}
          stageWidth={stageWidth}
          stageHeight={stageHeight}
          setPolygons={setPolygons}
          isPolygonDrawing={isPolygonDrawing}
          setIsPolygonDrawing={setIsPolygonDrawing}
        />
      </Layer>
    </Stage>
  );
};

export default PlaneStage;
