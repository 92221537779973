import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '../inputs/Checkbox';
import { BodyText } from '../fonts';
import { FlexContainer } from '../flexContainer';

const CheckboxField = ({
  isRequired,
  label,
  name,
  onChange,
  onBlur,
  form: { values },
  ...props
}) => {
  return (
    <FlexContainer id='teblastclg' align='center' gap='4px'>
      <Checkbox
        id={name}
        type={name}
        checked={values[name] ? 'checked' : ''}
        value={values[name]}
        onChange={onChange}
        onBlur={onBlur}
        {...props}
      />
      <BodyText id='xcbawwxyaq'>{label}</BodyText>
    </FlexContainer>
  );
};

CheckboxField.propTypes = {
  name: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
};

CheckboxField.defaultProps = {
  isRequired: false,
  label: '',
};

export default CheckboxField;
