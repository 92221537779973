/* eslint-disable object-shorthand */
/* eslint-disable prefer-destructuring */
/*eslint-disable jsx-a11y/no-static-element-interactions*/
import React, { useState, useEffect } from 'react';
import { Layer, Line, Stage, Text } from 'react-konva';
import Snackbar from '@mui/material/Snackbar';
import Divider from '@mui/material/Divider';
import debounce from 'lodash.debounce';
import { AccessControl, PAGES_NAMES, ROLE_ACTIONS } from '../../../permissions';

import {
  checkIsPointNearStartPoint,
  convertPolygonsToLinesView,
  denormalizePoint,
  normalizePoint,
  getLinkedPolygons,
  angleBetweenPoints,
  getAllPolygonTransformPoints,
  calculateGuidingLines,
  checkIsPointNearAnyTransformationPoint,
  checkIsNearNormPoint,
} from '../utils';
import Lines from './Lines';
import { convertToLineObject } from '../utilsConverters';
import {
  Dropdown,
  DropdownContent,
  Item,
  ContextTextField,
  DropButton,
  EditModeButton,
  EditMode,
  PolygonContextMenu,
  ContextProperty,
  PointContextMenu,
  ContextItem,
} from './Styles';
//Context
import { useAppContext } from '../../../AppContext';
import { useFloorPlanEditorContext } from '../../../pages/templateManagement/floorPlanEditor/floorPlanEditorContext';

const MIN_POINTS_COUNT_TO_CREATE_SHAPE = 3;

const Canvas = ({
  stageWidth,
  stageHeight,
  polygons,
  setPolygons,
  isPolygonDrawing,
  setIsPolygonDrawing,
  sidesPerPolygonLimit,
  polygonsLimit,
  setAreActionsDisabled,
  setWasPointMoved,
  isUserActionPermitted,
  transform,
  imgRealWidth,
  imgRealHeigth,
  isStyledForZone = true,
  showZoneLabels = false,
}) => {
  const {
    setIsLTGdialogOpen,
    newNaming,
    toggleEditMode,
    setToggleEditMode,
    userActionAllowed,
    setUserActionAllowed,
  } = useFloorPlanEditorContext();
  const [isLineDrawing, setIsLineDrawing] = useState(false);
  const [guidingLines, setGuidingLines] = useState([]);
  const [transformationPointRadius, setTransformationPointRadius] =
    useState(10);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState('');
  const [showGrid, setShowGrid] = useState(true);
  const [isRestrictModeOn, setIsRestrictModeOn] = useState(true);
  const [showTransformPoints, setShowTransformPoints] = useState(true);
  const [isMagneticModeOn, setIsMagneticModeOn] = useState(true);
  const [isAnythingMoving, setIsAnythingMoving] = useState(false);
  const [isDisableLines, setIsDisableLines] = useState(false);
  const [isNearPoint, setIsNearPoint] = useState(false);

  const [angleToDisplay, setAngleToDisplay] = useState({});
  const [contextMenu, setContextMenu] = useState({
    x: -100,
    y: -100,
    polygonName: '',
    clickedPolygon: { isInaccessible: false, isEmployeeOnly: false, id: '' },
  });
  const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);
  const [cursorCoordinates, setCursorCoordinates] = useState({
    x: 0,
    y: 0,
    stageX: 0,
    stageY: 0,
  });
  const [transformationPoints, setTransformationPoints] = useState([
    { point: [0, 0] },
  ]);
  const { userPages, setActiveNotify } = useAppContext();

  useEffect(() => {
    if (isUserActionPermitted && toggleEditMode) setUserActionAllowed(true);
    else setUserActionAllowed(false);
  }, [toggleEditMode]);

  useEffect(() => {
    setTransformationPoints(
      getAllPolygonTransformPoints(polygons, isLineDrawing)
    );
  }, [polygons]);

  useEffect(() => {
    // add linkedTo property to overlaid points
    if (!isLineDrawing) {
      const polygonsCopy = [...polygons];
      polygons.forEach((firstPolygon, firstPolygonIndex) => {
        firstPolygon.points.forEach((firstPoint, firstPointIndex) => {
          const [x1, y1] = firstPoint;
          polygons.forEach(secondPolygon => {
            if (firstPolygon.id !== secondPolygon.id) {
              secondPolygon.points.forEach((secondPoint, secondPointIndex) => {
                const [x2, y2] = secondPoint;
                if (x1 === x2 && y1 === y2) {
                  // filter existing linkedTo points
                  const filteredArr = firstPolygon.linkedTo[
                    firstPointIndex
                  ]?.filter(
                    point =>
                      point.polygonId === secondPolygon.id &&
                      point.pointIndex === secondPointIndex
                  );
                  // add linkedTo attribute if point do not exist
                  if (filteredArr.length === 0) {
                    polygonsCopy[firstPolygonIndex].linkedTo[
                      firstPointIndex
                    ].push({
                      polygonId: secondPolygon.id,
                      pointIndex: secondPointIndex,
                    });
                  }
                }
              });
            }
          });
        });
      });
      setPolygons(polygonsCopy);
    }
  }, [isLineDrawing]);

  useEffect(() => {
    const handleKeyDown = event => {
      const { key } = event;
      switch (key) {
        case 'd':
          setShowTransformPoints(prev => !prev);
          break;
        case 'q':
          setTransformationPointRadius(prev => Math.max(1, prev - 1));
          break;
        case 'w':
          setShowTransformPoints(true);
          setTransformationPointRadius(prev => Math.min(10, prev + 1));
          break;
        case 'g':
          setShowGrid(prev => !prev);
          break;
        case 'r':
          setIsRestrictModeOn(prev => !prev);
          break;
        case 'm':
          setIsMagneticModeOn(prev => !prev);
          break;
        default:
          setIsSnackbarOpen(false);
          break;
      }
    };
    const isInputFocused = ['INPUT', 'TEXTAREA'].includes(
      document.activeElement?.tagName
    );
    if (isInputFocused) return;

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [
    showTransformPoints,
    showGrid,
    isRestrictModeOn,
    isMagneticModeOn,
    polygons,
  ]);

  useEffect(() => {
    const snackbarMsg = !showGrid ? 'Hiding Grid' : 'Showing Grid';
    setSnackbarMsg(snackbarMsg);
    setIsSnackbarOpen(true);
  }, [showGrid]);
  useEffect(() => {
    const snackbarMsg = !isMagneticModeOn
      ? 'Disabling Magnetic Mode'
      : 'Enabling Magnetic Mode';
    setSnackbarMsg(snackbarMsg);
    setIsSnackbarOpen(true);
  }, [isMagneticModeOn]);
  useEffect(() => {
    const snackbarMsg = !isRestrictModeOn
      ? 'Disabling Restrict Mode'
      : 'Enabling Restrict Mode';
    setSnackbarMsg(snackbarMsg);
    setIsSnackbarOpen(true);
  }, [isRestrictModeOn]);
  useEffect(() => {
    const snackbarMsg = !showTransformPoints
      ? 'Hiding Transformation Points'
      : 'Showing Transformation Points';
    setSnackbarMsg(snackbarMsg);
    setIsSnackbarOpen(true);
  }, [showTransformPoints]);

  const renderGrid = () => {
    const grid = [];
    if (showGrid) {
      for (let i = 0; i < Math.ceil(stageWidth / 25); i++) {
        grid.push(
          <Line
            key={'x' + i}
            points={[0, i * 25, stageWidth, i * 25]}
            stroke='lightgray'
            strokeWidth={1}
          />
        );
      }
      for (let i = 0; i < Math.ceil(stageWidth / 25); i++) {
        grid.push(
          <Line
            key={'y' + i}
            points={[i * 25, 0, i * 25, stageHeight]}
            stroke='lightgray'
            strokeWidth={1}
          />
        );
      }
    }
    return grid;
  };

  const renderGuideLines = () => {
    const linesToRender = [];
    const lines = guidingLines;

    if (lines.length === 0) return <Line />;

    lines.forEach((line, index) => {
      if (!line.isY)
        linesToRender.push(
          <Line
            key={index + 'x'}
            points={[0, line.renderPoint[1], stageWidth, line.renderPoint[1]]}
            stroke='red'
            strokeWidth='1'
            dash={[4, 6]}
          />
        );
      else
        linesToRender.push(
          <Line
            key={index + 'y'}
            points={[line.renderPoint[0], 0, line.renderPoint[0], stageHeight]}
            stroke='red'
            strokeWidth='1'
            dash={[4, 6]}
          />
        );
    });
    return linesToRender;
  };

  const renderAngleText = angle => {
    if (isLineDrawing) {
      return (
        <Text
          text={'Angle : ' + angle.angle}
          fontFamily='Montserrat'
          x={angle.x1}
          y={angle.y1}
          offsetX={angle.offsetX}
          offsetY={angle.offsetY}
          fontSize='12'
        />
      );
    }
    return <Text fontFamily='Montserrat' x={0} y={0} fontSize='12' />;
  };
  const createNewPolygonZone = (point, linkedPolygons = []) => {
    setIsPolygonDrawing(true);
    setAreActionsDisabled(true);
    const newPolygon = convertToLineObject();
    const normalizedPoint = normalizePoint(stageWidth, stageHeight, point);
    const pointsToPush = [normalizedPoint[0], normalizedPoint[1]];
    if (isRestrictModeOn === true && guidingLines.length > 0) {
      guidingLines.forEach(line => {
        if (line.isY) pointsToPush[0] = line.normPoint[0];
        else pointsToPush[1] = line.normPoint[1];
      });
      newPolygon.points.push(pointsToPush);
    } else newPolygon.points.push(normalizedPoint);
    newPolygon.linkedTo = [linkedPolygons];
    newPolygon.isMoving = [false];
    newPolygon.isInaccessible = false;
    newPolygon.isEmployeeOnly = false;
    setIsLineDrawing(false);
    const polygonsCopy = [...polygons];
    // add new point to the polygons' linkedTo
    linkedPolygons.map(link => {
      polygonsCopy
        .filter(polygon => polygon.id === link.polygonId)[0]
        .linkedTo[link.pointIndex].push({
          polygonId: newPolygon.id,
          pointIndex: 0,
        });
      return link;
    });
    // add new polygon
    polygonsCopy.push(newPolygon);
    setPolygons(polygonsCopy);
  };

  const addNewPointToCurrentPolygon = point => {
    const polygonsCopy = [...polygons];
    const lastPolygon = polygonsCopy[polygonsCopy.length - 1];
    if (!lastPolygon) {
      setActiveNotify({
        preset: 'error',
        title: 'Last polygon error!',
        message: 'Refresh the page...',
      });
      return;
    }
    const { offsetX, offsetY } = lastPolygon;
    const firstPoint = lastPolygon.points?.[0];
    const denormalizedFirstPoint = denormalizePoint(
      stageWidth,
      stageHeight,
      firstPoint
    );
    const [denormalizeOffsetX, denormalizeOffsetY] = denormalizePoint(
      stageWidth,
      stageHeight,
      [offsetX, offsetY]
    );
    const [normalizedCurrentX, normalizedCurrentY] = normalizePoint(
      stageWidth,
      stageHeight,
      point
    );
    const isPointNearStartPoint = checkIsPointNearStartPoint(
      denormalizedFirstPoint,
      [point[0] - denormalizeOffsetX, point[1] - denormalizeOffsetY]
    );
    // Check if near other transormation point
    let isNearOtherTransformationPoint = false;
    const upcomingNeighboors = [];

    if (isMagneticModeOn)
      transformationPoints.forEach(point => {
        if (point.polygonId !== lastPolygon.id)
          if (
            checkIsNearNormPoint(
              [normalizedCurrentX, normalizedCurrentY],
              point.point
            ) &&
            !isNearOtherTransformationPoint
          ) {
            if (lastPolygon.points.length >= MIN_POINTS_COUNT_TO_CREATE_SHAPE) {
              setIsPolygonDrawing(false);
              lastPolygon.points[lastPolygon.points.length - 1] = point.point;
              lastPolygon.isClosed = true;
              isNearOtherTransformationPoint = true;
              // add links to polies
              polygonsCopy.forEach(polygon => {
                if (polygon.id === point.polygonId) {
                  upcomingNeighboors.push({
                    polygonId: point.polygonId,
                    pointIndex: point.pointIndex,
                  });
                  polygon.linkedTo[point.pointIndex].forEach(points => {
                    upcomingNeighboors.push(points);
                  });
                  polygon.linkedTo[point.pointIndex].push({
                    polygonId: lastPolygon.id,
                    pointIndex: lastPolygon.points.length - 1,
                  });
                }
              });
            }
          }
      });
    if (
      isPointNearStartPoint &&
      lastPolygon.points.length > MIN_POINTS_COUNT_TO_CREATE_SHAPE
    ) {
      // remove last point that was added during moving cursor
      lastPolygon.points.splice(-1);
      lastPolygon.isClosed = true;
      setIsPolygonDrawing(false);
      setAreActionsDisabled(false);
    } else {
      if (
        isRestrictModeOn === true &&
        guidingLines.length > 0 &&
        !isNearOtherTransformationPoint
      ) {
        const pointsToPush = [normalizedCurrentX, normalizedCurrentY];
        guidingLines.forEach(line => {
          if (line.isY) pointsToPush[0] = line.normPoint[0];
          else pointsToPush[1] = line.normPoint[1];
        });
        lastPolygon.points[lastPolygon.points.length - 1] = pointsToPush;
      }
      if (!isNearOtherTransformationPoint && guidingLines.length === 0) {
        lastPolygon.points[lastPolygon.points.length - 1] = [
          normalizedCurrentX - offsetX,
          normalizedCurrentY - offsetY,
        ];
      }

      const sidesCount = lastPolygon.points.length - 1;
      const isClosed = sidesPerPolygonLimit <= sidesCount + 1;

      if (isClosed) {
        lastPolygon.isClosed = isClosed;
        setIsPolygonDrawing(false);
        setAreActionsDisabled(false);
      }
      lastPolygon.linkedTo.push(upcomingNeighboors);
      lastPolygon.isMoving.push(false);
    }
    setIsLineDrawing(false);
    setPolygons(polygonsCopy);
  };

  const onMouseDown = event => {
    setIsContextMenuOpen(false);
    const clickedOnEmpty = event.target === event.target.getStage();
    const { x, y } = event.target.getStage().getPointerPosition();
    if (isPolygonDrawing) {
      addNewPointToCurrentPolygon([x, y]);
    } else if (!isPolygonDrawing && clickedOnEmpty) {
      if (polygons.length >= polygonsLimit) return;
      createNewPolygonZone([x, y]);
    }
  };
  const debouncingHandleMouseMove = debounce(
    event => handleMouseMove(event),
    3
  );
  const handleMouseMove = event => {
    try {
      const { x, y } = event.target.getStage().getPointerPosition();
      const denormalizedX = Math.round((x * imgRealWidth) / stageWidth);
      const denormalizedY = Math.round((y * imgRealHeigth) / stageHeight);
      setCursorCoordinates({
        stageX: x,
        stageY: y,
        x: denormalizedX,
        y: denormalizedY,
      });
      if (isPolygonDrawing) {
        const polygonsCopy = [...polygons];
        const lastPolygon = polygonsCopy[polygonsCopy.length - 1];
        if (!lastPolygon) {
          setActiveNotify({
            preset: 'error',
            title: 'Last polygon error!',
            message: 'Refresh the page.',
          });
          return;
        }
        const { offsetX, offsetY } = lastPolygon;
        const { x, y } = event.target.getStage().getPointerPosition();
        const [normalizedCurrentX, normalizedCurrentY] = normalizePoint(
          stageWidth,
          stageHeight,
          [x, y]
        );
        if (isLineDrawing) {
          const angle = angleBetweenPoints(
            lastPolygon.points[lastPolygon.points.length - 2][0],
            lastPolygon.points[lastPolygon.points.length - 2][1],
            lastPolygon.points[lastPolygon.points.length - 1][0],
            lastPolygon.points[lastPolygon.points.length - 1][1]
          );

          const [denormalX, denormalY] = denormalizePoint(
            stageWidth,
            stageHeight,
            [
              lastPolygon.points[lastPolygon.points.length - 2][0],
              lastPolygon.points[lastPolygon.points.length - 2][1],
            ]
          );
          setAngleToDisplay({ ...angle, x1: denormalX, y1: denormalY });
          lastPolygon.points[lastPolygon.points.length - 1] = [
            normalizedCurrentX - offsetX,
            normalizedCurrentY - offsetY,
          ];
        } else {
          lastPolygon.points.push([
            normalizedCurrentX - offsetX,
            normalizedCurrentY - offsetY,
          ]);
          setIsLineDrawing(true);
        }
        setPolygons(polygonsCopy);
        const isNearPoint_ = checkIsPointNearAnyTransformationPoint(
          [normalizedCurrentX, normalizedCurrentY],
          transformationPoints
        );
        setIsNearPoint(isNearPoint_);
      }
      const lines = calculateGuidingLines(
        transformationPoints,
        stageHeight,
        stageWidth,
        imgRealHeigth,
        imgRealWidth,
        cursorCoordinates
      );
      setGuidingLines(lines);
    } catch (error) {
      console.log('Error @L-536: ', error);
    }
  };

  const debouncingOnDragEnd = debounce(id => onDragEnd(id), 3);
  const onDragEnd = id => {
    // remove offset and add offset to points
    const polygonsCopy = [...polygons];
    const linkedPolygons = getLinkedPolygons(polygonsCopy, id, []);
    polygonsCopy.forEach((polygon, polygonIndex) => {
      if (id === polygon.id || linkedPolygons.includes(polygon.id)) {
        polygon.points.forEach((point, pointIndex) => {
          polygonsCopy[polygonIndex].points[pointIndex][0] += polygon.offsetX;
          polygonsCopy[polygonIndex].points[pointIndex][1] += polygon.offsetY;
        });
        polygon.offsetX = 0;
        polygon.offsetY = 0;
      }
    });
    setPolygons(polygonsCopy);
    setIsAnythingMoving(false);
  };
  const debouncingHandleDragMove = debounce(
    (id, event) => handleDragMove(id, event),
    3
  );
  const handleDragMove = (id, event) => {
    if (!toggleEditMode) return null;
    setIsContextMenuOpen(false);
    setIsAnythingMoving(true);
    const polygonsCopy = [...polygons];
    if (userActionAllowed) {
      const newPolygons = polygonsCopy.map(polygon => {
        const linkedPolygons = getLinkedPolygons(polygonsCopy, id, []);
        if (polygon.id === id || linkedPolygons.includes(polygon.id)) {
          const { points } = polygon;
          const xCoordinates = points.map(point => point[0]).flat();
          const yCoordinates = points.map(point => point[1]).flat();
          const minX = Math.min(...xCoordinates);
          const maxX = Math.max(...xCoordinates);
          const minY = Math.min(...yCoordinates);
          const maxY = Math.max(...yCoordinates);

          const targetX = event.target.x();
          const targetY = event.target.y();
          const [normalizedX, normalizedY] = normalizePoint(
            stageWidth,
            stageHeight,
            [targetX, targetY]
          );
          const potentiallyMinX = minX + normalizedX;
          const potentiallyMaxX = maxX + normalizedX;
          const potentiallyMinY = minY + normalizedY;
          const potentiallyMaxY = maxY + normalizedY;

          if (potentiallyMinX <= 0) {
            event.target.x(-minX * stageWidth);
            polygon.offsetX = -minX;
          } else if (potentiallyMaxX >= 1) {
            event.target.x((1 - maxX) * stageWidth);
            polygon.offsetX = 1 - maxX;
          } else {
            polygon.offsetX = normalizedX;
          }

          if (potentiallyMinY < 0) {
            event.target.y(-minY * stageHeight);
            polygon.offsetY = -minY;
          } else if (potentiallyMaxY >= 1) {
            event.target.y((1 - maxY) * stageHeight);
            polygon.offsetY = 1 - maxY;
          } else {
            polygon.offsetY = normalizedY;
          }
        }
        return { ...polygon };
      });
      setPolygons(newPolygons);
    }
  };

  const handleRightMouseClick = event => {
    event.evt.preventDefault();
    if (isPolygonDrawing) {
      const polygonsCopy = [...polygons];
      const lastPolygon = polygonsCopy[polygonsCopy.length - 1];
      polygonsCopy.forEach(polygon => {
        if (lastPolygon.id === polygon.id && lastPolygon.points.length >= 2) {
          lastPolygon.points.splice(-2);
          lastPolygon.linkedTo.splice(-2);
          lastPolygon.isMoving.splice(-2);
          setPolygons(polygonsCopy);
        }
        // remove the polygon
        if (lastPolygon.id === polygon.id && lastPolygon.points.length <= 1) {
          const filteredPolygons = polygonsCopy.filter(
            polygon => polygon.id !== lastPolygon.id
          );
          setPolygons(filteredPolygons);
          setIsPolygonDrawing(false);
          setAreActionsDisabled(false);

          // remove linked polygons
          filteredPolygons.forEach(polygon_ => {
            polygon_.linkedTo.forEach((points, index) => {
              const filteredPoints = points.filter(
                point => point.polygonId !== lastPolygon.id
              );
              polygon_.linkedTo[index] = filteredPoints;
            });
          });
        }
      });
    }
  };

  const handleMouseEnter = event => {
    const container = event.target.getStage().container();
    container.style.cursor = 'crosshair';
  };

  const handleMouseLeave = event => {
    const container = event.target.getStage().container();
    container.style.cursor = 'default';
  };

  const getStageActions = () => {
    if (toggleEditMode) {
      return {
        onMouseDown,
        onTap: onMouseDown,
        onMouseMove: debouncingHandleMouseMove,
        onContextMenu: handleRightMouseClick,
        onMouseEnter: handleMouseEnter,
        onMouseLeave: handleMouseLeave,
        onDragEnd: () => {
          setIsAnythingMoving(false);
        },
      };
    }
    return {
      onMouseDown,
      onTap: onMouseDown,
      onContextMenu: handleRightMouseClick,
    };
  };

  const handleDrawPolygon = () => {
    setIsContextMenuOpen(false);
    createNewPolygonZone(
      [contextMenu.x, contextMenu.y],
      contextMenu.linkedPolygons
    );
  };

  const getRandomColor = () => {
    const text = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += text[Math.floor(Math.random() * 15)];
    }
    return color;
  };
  const handleIsInaccessible = boolFlag => {
    setIsContextMenuOpen(false);
    const polygonsCopy = [...polygons];
    const newPolygons = polygons.map((polygon, index) => {
      if (polygon.id === contextMenu.clickedPolygon.id) {
        polygonsCopy[index].isInaccessible = boolFlag;
        polygonsCopy[index].isEmployeeOnly = false;
        if (boolFlag) polygonsCopy[index].color = '#000000';
        else polygonsCopy[index].color = getRandomColor();
      }
      return { ...polygon };
    });
    setPolygons(newPolygons);
  };

  const handleIsEmployeeOnly = boolFlag => {
    setIsContextMenuOpen(false);
    const polygonsCopy = [...polygons];
    const newPolygons = polygons.map((polygon, index) => {
      if (polygon.id === contextMenu.clickedPolygon.id) {
        polygonsCopy[index].isInaccessible = false;
        polygonsCopy[index].isEmployeeOnly = boolFlag;
        if (boolFlag) polygonsCopy[index].color = '#FF0000';
        else polygonsCopy[index].color = getRandomColor();
      }
      return { ...polygon };
    });
    setPolygons(newPolygons);
  };

  const handleDelete = id => {
    const newPolygons = polygons.filter(polygon => polygon.id !== id);
    // remove linkedPolygons
    newPolygons.forEach(polygon_ => {
      polygon_.linkedTo.forEach((points, index) => {
        const filteredPoints = points.filter(
          point => point.polygonId !== polygon_.id
        );
        polygon_.linkedTo[index] = filteredPoints;
      });
    });
    setIsContextMenuOpen(false);
    setPolygons(newPolygons);
  };

  const handleContextMenuPolyChange = e => {
    const newPolygons = polygons.map(polygon => {
      if (contextMenu.clickedPolygon.id === polygon.id) {
        polygon.name = e.target.value;
      }
      return { ...polygon };
    });
    setPolygons(newPolygons);
    const contextMenuCopy = { ...contextMenu };
    contextMenuCopy.polygonName = e.target.value;
    setContextMenu(contextMenuCopy);
  };

  return (
    <div>
      <PointContextMenu
        isContextMenuOpen={isContextMenuOpen}
        contextMenu={contextMenu}
      >
        <ContextItem onClick={handleDrawPolygon}>Draw New Polygon</ContextItem>
        <Divider />
      </PointContextMenu>
      <PolygonContextMenu
        isContextMenuOpen={isContextMenuOpen}
        contextMenu={contextMenu}
      >
        <ContextTextField
          inputProps={{
            style: {
              fontFamily: 'Montserrat',
              height: '10px',
              color: 'blue',
            },
          }}
          sx={{
            '& .MuiFilledInput-input': {
              paddingTop: '10px',
              marginBottom: '2px',
            },
          }}
          onChange={handleContextMenuPolyChange}
          value={contextMenu.polygonName}
          placeholder='Unnamed Zone'
          variant='filled'
        />
        {contextMenu?.clickedPolygon?.isInaccessible ? (
          <ContextItem onClick={() => handleIsInaccessible(false)}>
            Remove Inaccessible Flag
          </ContextItem>
        ) : (
          <ContextItem onClick={() => handleIsInaccessible(true)}>
            Make Zone Inaccessible
          </ContextItem>
        )}
        <Divider />
        {contextMenu?.clickedPolygon?.isEmployeeOnly ? (
          <ContextItem onClick={() => handleIsEmployeeOnly(false)}>
            Remove Employee-Only Flag
          </ContextItem>
        ) : (
          <ContextItem onClick={() => handleIsEmployeeOnly(true)}>
            Make Zone Employee-Only
          </ContextItem>
        )}
        <Divider />
        {toggleEditMode ? (
          <ContextItem
            onClick={() => handleDelete(contextMenu.clickedPolygon.id)}
          >
            Delete Zone
          </ContextItem>
        ) : null}
        <Divider />
        {contextMenu.clickedPolygon.isInaccessible ? (
          <ContextProperty>* Inaccessible</ContextProperty>
        ) : (
          ''
        )}
        <Divider />
        {contextMenu.clickedPolygon.isEmployeeOnly ? (
          <ContextProperty>* Employee Only</ContextProperty>
        ) : (
          ''
        )}
        <Divider />
      </PolygonContextMenu>
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={500}
        onClose={() => {
          setIsSnackbarOpen(false);
        }}
        message={snackbarMsg}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{ position: 'absolute' }}
      />
      <AccessControl
        id='nbpdywvfhj'
        userPages={userPages}
        targetPageName={PAGES_NAMES.floorplan_editor}
        targetActions={[ROLE_ACTIONS.update]}
      >
        {!newNaming ? (
          <EditMode maxY={stageWidth - 60 + 'px'}>
            <EditModeButton
              onClick={() => setToggleEditMode(!toggleEditMode)}
            />
          </EditMode>
        ) : null}
        <Dropdown maxY={stageWidth - 30 + 'px'}>
          <DropButton>Dropdown</DropButton>
          <DropdownContent>
            {userActionAllowed ? (
              <>
                <Item
                  onClick={() => {
                    setShowGrid(!showGrid);
                  }}
                >
                  Grid {'->'} g
                </Item>
                <Divider />
                <Item
                  onClick={() => {
                    setShowTransformPoints(!showTransformPoints);
                  }}
                >
                  Points {'->'} d
                </Item>
                <Divider />
                <Item
                  onClick={() => {
                    setIsMagneticModeOn(!isMagneticModeOn);
                  }}
                >
                  Magnetic Mode {'->'} m
                </Item>
                <Divider />
                <Item
                  onClick={() => {
                    setIsRestrictModeOn(!isRestrictModeOn);
                  }}
                >
                  Restrict Mode {'->'} r
                </Item>
                <Divider />
                <Item
                  onClick={() => {
                    setSnackbarMsg(
                      'Use "q" to increase size, use "w" to decrease size'
                    );
                    setIsSnackbarOpen(true);
                  }}
                >
                  Point Size {'->'} q, w
                </Item>
                <Divider />
              </>
            ) : null}
            <Item
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setIsLTGdialogOpen('config');
              }}
            >
              Upload Config
            </Item>
            <Item
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setIsLTGdialogOpen('polygons');
              }}
            >
              Upload Polygons
            </Item>
            <Divider />
          </DropdownContent>
        </Dropdown>
      </AccessControl>

      <Stage width={stageWidth} height={stageHeight} {...getStageActions()}>
        <Layer>
          <Lines
            polygons={convertPolygonsToLinesView(
              stageWidth,
              stageHeight,
              polygons
            )}
            transformationPointRadius={transformationPointRadius}
            onDragMove={debouncingHandleDragMove}
            isNearPoint={isNearPoint}
            onDragEnd={debouncingOnDragEnd}
            setPolygons={setPolygons}
            isPolygonDrawing={isPolygonDrawing}
            setIsPolygonDrawing={setIsPolygonDrawing}
            setAreActionsDisabled={setAreActionsDisabled}
            setWasPointMoved={setWasPointMoved}
            transform={transform}
            isUserActionPermitted={isUserActionPermitted}
            userActionAllowed={userActionAllowed}
            isStyledForZone={isStyledForZone}
            stageWidth={stageWidth}
            stageHeight={stageHeight}
            isAnythingMoving={isAnythingMoving}
            setIsAnythingMoving={setIsAnythingMoving}
            setIsDisableLines={setIsDisableLines}
            isDisableLines={isDisableLines}
            setContextMenu={setContextMenu}
            setIsContextMenuOpen={setIsContextMenuOpen}
            isContextMenuOpen={isContextMenuOpen}
            showTransformPoints={showTransformPoints}
            imgRealWidth={imgRealWidth}
            imgRealHeigth={imgRealHeigth}
            setCursorCoordinates={setCursorCoordinates}
            isRestrictModeOn={isRestrictModeOn}
            cursorCoordinates={cursorCoordinates}
            guidingLines={guidingLines}
            setGuidingLines={setGuidingLines}
            isMagneticModeOn={isMagneticModeOn}
            transformationPoints={transformationPoints}
            showZoneLabels={showZoneLabels}
          />
          <Line points={[1, 1]} opacity={0} />
          {toggleEditMode ? renderGrid() : null}
          {toggleEditMode ? renderGuideLines() : null}
          {toggleEditMode ? renderAngleText(angleToDisplay) : null}
          <Text
            text={userActionAllowed ? 'Edit Mode' : ''}
            fontFamily='Montserrat'
            x={stageWidth - 150}
            y={5}
            fontStyle='bold'
            fontSize='12'
          />
          <Text
            text={imgRealWidth + ' x ' + imgRealHeigth}
            fontFamily='Montserrat'
            x={stageWidth - 75}
            y={5}
            fontStyle='bold'
            fontSize='12'
          />
        </Layer>
      </Stage>
    </div>
  );
};

export default Canvas;
