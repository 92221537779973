// Libs
import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
// Utils
import { variables } from '../utils/variables';

const Auth0ProviderWithHistory = ({ children }) => {
  const config = variables();
  const { domain } = config;
  const clientId = config.client_id;
  const { redirectUri } = config;

  return (
    <Auth0Provider
      id='kpizgcfgch'
      domain={domain}
      clientId={clientId}
      redirectUri={redirectUri}
      audience={`https://${domain}/api/v2/`}
      scope='openid profile email'
      useRefreshTokens
      cacheLocation='localstorage'
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
