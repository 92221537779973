import React, { useState } from 'react';
import { Layer, Line, Stage } from 'react-konva';
import isEmpty from 'lodash.isempty';
import {
  checkIsPointNearStartPoint,
  convertPolygonsToLinesView,
  denormalizePoint,
  normalizePoint,
} from '../utils';
import Lines from './Lines';
import { convertToLineObject } from '../utilsConverters';

const MIN_POINTS_COUNT_TO_CREATE_SHAPE = 3;

const CustomStage = ({
  stageWidth,
  stageHeight,
  polygons,
  setPolygons,
  isPolygonDrawing,
  setIsPolygonDrawing,
  sidesPerPolygonLimit,
  polygonsLimit,
  setAreActionsDisabled,
  automatedAoi,
  unmutuablePolygons,
  opacity,
  setWasPointMoved,
  isUserActionPermitted,
  transform,
  isStyledForZone = false,
}) => {
  const [isLineDrawing, setIsLineDrawing] = useState(false);

  const createNewPolygon = point => {
    setIsPolygonDrawing(true);
    setAreActionsDisabled(true);
    const newPolygon = convertToLineObject();
    const normalizedPoint = normalizePoint(stageWidth, stageHeight, point);
    newPolygon.points.push(normalizedPoint);
    setIsLineDrawing(false);
    setPolygons([...polygons, newPolygon]);
  };

  const addNewPointToCurrentPolygon = point => {
    const polygonsCopy = [...polygons];
    const lastPolygon = polygonsCopy[polygonsCopy.length - 1];
    const { offsetX, offsetY } = lastPolygon;
    const firstPoint = lastPolygon.points?.[0];
    const denormalizedFirstPoint = denormalizePoint(
      stageWidth,
      stageHeight,
      firstPoint
    );
    const [denormalizeOffsetX, denormalizeOffsetY] = denormalizePoint(
      stageWidth,
      stageHeight,
      [offsetX, offsetY]
    );
    const isPointNearStartPoint = checkIsPointNearStartPoint(
      denormalizedFirstPoint,
      [point[0] - denormalizeOffsetX, point[1] - denormalizeOffsetY]
    );
    if (isPointNearStartPoint) {
      if (lastPolygon?.points?.length >= MIN_POINTS_COUNT_TO_CREATE_SHAPE) {
        if (lastPolygon?.points?.length > MIN_POINTS_COUNT_TO_CREATE_SHAPE) {
          // remove last point that was added during moving cursor
          lastPolygon.points.splice(-1);
        }
        lastPolygon.isClosed = true;
        setIsPolygonDrawing(false);
        setAreActionsDisabled(false);
      }
    } else {
      const [normalizedCurrentX, normalizedCurrentY] = normalizePoint(
        stageWidth,
        stageHeight,
        point
      );
      lastPolygon.points[lastPolygon.points.length - 1] = [
        normalizedCurrentX - offsetX,
        normalizedCurrentY - offsetY,
      ];

      const sidesCount = lastPolygon.points.length - 1;
      const isClosed = sidesPerPolygonLimit <= sidesCount + 1;

      if (isClosed) {
        lastPolygon.isClosed = isClosed;
        setIsPolygonDrawing(false);
        setAreActionsDisabled(false);
      }
    }
    setIsLineDrawing(false);
    setPolygons(polygonsCopy);
  };

  const onMouseDown = event => {
    const clickedOnEmpty = event.target === event.target.getStage();
    const { x, y } = event.target.getStage().getPointerPosition();
    if (isPolygonDrawing) {
      addNewPointToCurrentPolygon([x, y]);
    } else if (!isPolygonDrawing && clickedOnEmpty) {
      if (polygons.length >= polygonsLimit) return;
      createNewPolygon([x, y]);
    }
  };

  const handleMouseMove = event => {
    if (isPolygonDrawing) {
      const polygonsCopy = [...polygons];
      const lastPolygon = polygonsCopy[polygonsCopy.length - 1];
      const { offsetX, offsetY } = lastPolygon;
      const { x, y } = event.target.getStage().getPointerPosition();
      const [normalizedCurrentX, normalizedCurrentY] = normalizePoint(
        stageWidth,
        stageHeight,
        [x, y]
      );
      if (isLineDrawing) {
        lastPolygon.points[lastPolygon.points.length - 1] = [
          normalizedCurrentX - offsetX,
          normalizedCurrentY - offsetY,
        ];
      } else {
        lastPolygon.points.push([
          normalizedCurrentX - offsetX,
          normalizedCurrentY - offsetY,
        ]);
        setIsLineDrawing(true);
      }
      setPolygons(polygonsCopy);
    }
  };

  const handleDragMove = (id, event) => {
    if (isUserActionPermitted) {
      const newPolygons = polygons.map(polygon => {
        if (polygon.id === id) {
          const { points } = polygon;
          const xCoordinates = points.map(point => point[0]).flat();
          const yCoordinates = points.map(point => point[1]).flat();
          const minX = Math.min(...xCoordinates);
          const maxX = Math.max(...xCoordinates);
          const minY = Math.min(...yCoordinates);
          const maxY = Math.max(...yCoordinates);

          const targetX = event.target.x();
          const targetY = event.target.y();
          const [normalizedX, normalizedY] = normalizePoint(
            stageWidth,
            stageHeight,
            [targetX, targetY]
          );

          const potentiallyMinX = minX + normalizedX;
          const potentiallyMaxX = maxX + normalizedX;
          const potentiallyMinY = minY + normalizedY;
          const potentiallyMaxY = maxY + normalizedY;

          if (potentiallyMinX < 0) {
            event.target.x(-minX * stageWidth);
            polygon.offsetX = -minX;
          } else if (potentiallyMaxX >= 1) {
            event.target.x((1 - maxX) * stageWidth);
            polygon.offsetX = 1 - maxX;
          } else {
            polygon.offsetX = normalizedX;
          }

          if (potentiallyMinY < 0) {
            event.target.y(-minY * stageHeight);
            polygon.offsetY = -minY;
          } else if (potentiallyMaxY >= 1) {
            event.target.y((1 - maxY) * stageHeight);
            polygon.offsetY = 1 - maxY;
          } else {
            polygon.offsetY = normalizedY;
          }
        }
        if (isStyledForZone) {
          return { ...polygon };
        }
        return polygon;
      });
      setPolygons(newPolygons);
    }
  };

  const handleRightMouseClick = event => {
    event.evt.preventDefault();
    if (isPolygonDrawing) {
      const polygonsCopy = [...polygons];
      const lastPolygon = polygonsCopy[polygonsCopy.length - 1];
      const lastPolygonId = lastPolygon.id;
      const filteredPolygons = polygonsCopy.filter(
        polygon => polygon.id !== lastPolygonId
      );
      setPolygons(filteredPolygons);
      setIsPolygonDrawing(false);
      setAreActionsDisabled(false);
    }
    return false;
  };

  const nonDraggable = (id, e) => {
    e.target.x(0);
    e.target.y(0);
  };

  const getStageActions = () => {
    if (isUserActionPermitted) {
      return {
        onMouseDown,
        onTap: onMouseDown,
        onMouseMove: handleMouseMove,
        onContextMenu: handleRightMouseClick,
      };
    }
  };

  return (
    <Stage
      id='zkoijygkub'
      width={stageWidth}
      height={stageHeight}
      {...getStageActions()}
    >
      {!isEmpty(automatedAoi) && (
        <Layer id='oxpulntizj' opacity={opacity ?? 1}>
          <Lines
            id='jyowhfakib'
            polygons={convertPolygonsToLinesView(
              stageWidth,
              stageHeight,
              automatedAoi
            )}
            onDragMove={nonDraggable}
            isDashed
          />

          <Line id='lmdmxzwacs' points={[1, 1]} opacity={0} />
        </Layer>
      )}

      {!isEmpty(unmutuablePolygons) && (
        <Layer id='qhbdsoiujg' opacity={opacity ?? 1}>
          <Lines
            id='ntrmxviaol'
            polygons={convertPolygonsToLinesView(
              stageWidth,
              stageHeight,
              unmutuablePolygons
            )}
            onDragMove={nonDraggable}
            isDashed
          />

          <Line id='uwdakrzejl' points={[1, 1]} opacity={0} />
        </Layer>
      )}
      <Layer id='tqnztxctnn'>
        <Lines
          id='akiltozodu'
          polygons={convertPolygonsToLinesView(
            stageWidth,
            stageHeight,
            polygons
          )}
          onDragMove={handleDragMove}
          setPolygons={setPolygons}
          isPolygonDrawing={isPolygonDrawing}
          setIsPolygonDrawing={setIsPolygonDrawing}
          setAreActionsDisabled={setAreActionsDisabled}
          setWasPointMoved={setWasPointMoved}
          transform={transform}
          isUserActionPermitted={isUserActionPermitted}
          isStyledForZone={isStyledForZone}
        />
        <Line id='hlolawbsug' points={[1, 1]} opacity={0} />
      </Layer>
    </Stage>
  );
};

export default CustomStage;
