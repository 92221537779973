// Libs
import styled, { css } from 'styled-components';

const variant = {
  healthy: 'healthy',
  unhealthy: 'unhealthy',
  notsetup: 'notsetup',
  notrunning: 'notrunning',
  unknown: 'unknown',
};

const size = {
  large: 'large',
  small: 'small',
};

const healthy = css`
  width: auto;
  min-width: auto;
  padding: 4px 8px;

  font-size: 12px;
  line-height: 18px;

  border-radius: 4px;
  border: 1px solid #256029;
  background-color: #c8e6c9;
  color: #256029;

  opacity: ${props => (props.disabled ? '45%' : '100%')};
`;
const unhealthy = css`
  width: auto;
  min-width: auto;
  padding: 4px 8px;

  font-size: 12px;
  line-height: 18px;

  border-radius: 4px;
  border: 1px solid #c63737;
  background-color: #feedaf;
  color: #c63737;

  opacity: ${props => (props.disabled ? '45%' : '100%')};
`;
const notrunning = css`
  width: auto;
  min-width: auto;
  padding: 4px 8px;

  font-size: 12px;
  line-height: 18px;

  border-radius: 4px;
  border: 1px solid #c63737;
  background-color: #c8e6c9;
  color: #c63737;

  opacity: ${props => (props.disabled ? '45%' : '100%')};
`;
const notsetup = css`
  width: auto;
  min-width: auto;
  padding: 4px 8px;

  font-size: 12px;
  line-height: 18px;

  border-radius: 4px;
  border: 1px solid #8a5340;
  background-color: #feedaf;
  color: #8a5340;

  opacity: ${props => (props.disabled ? '45%' : '100%')};
`;
const restarting = css`
  width: auto;
  min-width: auto;
  padding: 4px 8px;

  font-size: 12px;
  line-height: 18px;

  border-radius: 4px;
  border: 1px solid #ff0000;
  background-color: #fb2727;
  color: #fff;

  opacity: ${props => (props.disabled ? '45%' : '100%')};
`;
const unknown = css`
  width: auto;
  min-width: auto;
  padding: 12px 24px;

  font-size: 16px;
  line-height: 24px;

  border-radius: 4px;
  border: 1px solid #666;
  background-color: #eee;
  color: #666;

  opacity: ${props => (props.disabled ? '45%' : '100%')};
`;

const large = css`
  padding: '12px 24px';
  font-size: 16px;
  line-height: 24px;
`;

const small = css`
  width: auto;
  min-width: auto;
  padding: '4px 4px';

  font-size: 12px;
  line-height: 18px;
`;

// BUTTON
// props:
// variant='primary', 'secondary', 'action', 'link'
// size='large', 'small'
// colorType='normal', 'danger'
// disabled
export const PipelineSign = styled.div`
  ${props => {
    switch (props.variant) {
      case variant.healthy:
        return healthy;
      case variant.unhealthy:
        return unhealthy;
      case variant.notrunning:
        return notrunning;
      case variant.notsetup:
        return notsetup;
      case variant.restarting:
        return restarting;
      default:
        return unknown;
    }
  }}
  ${props => {
    switch (props.size) {
      case size.small:
        return small;
      case size.large:
        return large;
      default:
        return small;
    }
  }}
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  border-radius: 4px;
`;
