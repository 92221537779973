import React, { Fragment } from 'react';
import { Line } from 'react-konva';

import { hexToRgb } from '../utils';
import Transform from './Transform';

const Lines = ({
  polygons,
  onDragMove,
  isPolygonDrawing,
  isDashed,
  setPolygons,
  setIsPolygonDrawing,
  setAreActionsDisabled,
  transform,
  setWasPointMoved,
  isUserActionPermitted,
  isStyledForZone = false,
}) => {
  const getLineActions = id => {
    if (isUserActionPermitted) {
      return {
        onDragMove: e => onDragMove(id, e),
        onMouseEnter: handleMouseEnter,
        onMouseLeave: handleMouseLeave,
      };
    }
  };

  const handleMouseEnter = event => {
    const container = event.target.getStage().container();
    container.style.cursor = 'pointer';
  };

  const handleMouseLeave = event => {
    const container = event.target.getStage().container();
    container.style.cursor = 'default';
  };

  return (
    <>
      {polygons.map(polygon => {
        const { id, x, y, points, isClosed, color, offsetX, offsetY } = polygon;
        const { red, green, blue } = hexToRgb(color);
        const stroke = `rgba(${red},${green},${blue}, 1)`;
        const fill = `rgba(${red},${green},${blue}, 0.4)`;

        return (
          <Fragment id='mmvwggofwf' key={id}>
            {/* {renderZoneText(name, points, offsetX, offsetY)} */}
            <Line
              id='dscarmhgha'
              {...getLineActions(id)}
              lineCap='round'
              x={isStyledForZone ? x + offsetX : x}
              y={isStyledForZone ? y + offsetY : y}
              points={points}
              closed={isClosed}
              draggable={!isPolygonDrawing && isUserActionPermitted}
              fill={fill}
              dash={isDashed && [5, 10]}
              stroke={isStyledForZone ? 'black' : stroke}
              strokeWidth={isStyledForZone ? 2 : 5}
            />
            {isClosed && transform && (
              <Transform
                id='rclidscksw'
                polygonId={id}
                setPolygons={setPolygons}
                pointsList={points}
                offsetY={offsetY}
                offsetX={offsetX}
                stroke={stroke}
                setIsPolygonDrawing={setIsPolygonDrawing}
                setAreActionsDisabled={setAreActionsDisabled}
                draggable={isUserActionPermitted}
                setWasPointMoved={setWasPointMoved}
                isStyledForZone={isStyledForZone}
              />
            )}
          </Fragment>
        );
      })}
    </>
  );
};

export default Lines;
