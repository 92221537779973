import { routeService } from '../../routes/routesList';
import { COLORS } from '../colors';

const { list } = routeService;

export const SIDEBAR_PAGES = [
  {
    ...list.locations_and_cameras,
    subPages: [
      list.locations_and_groups,
      list.cameras,
      list.servers,
      list.access_keys,
    ],
  },
  {
    ...list.user_management,
    subPages: [list.users_and_roles],
  },
  {
    ...list.history,
    subPages: [list.user_write_log, list.version_control],
  },
  {
    ...list.template_management,
    subPages: [list.templates, list.camera_objectives, list.floorplan_editor],
  },
  {
    ...list.overlay_tools,
    subPages: [
      list.plane_calibration,
      list.plane_and_normal,
      list.detection_polygon,
      list.aoi_selection,
      list.floor_projection,
      /* Commented until we use this page */
      // list.object_markers,
    ],
  },
  {
    ...list.installation_management,
    subPages: [
      list.camera_view_assist,
      list.installation_support,
      list.camera_validations,
      list.server_provisioning,
    ],
  },
  list.location_status,
  list.shopassist_health,
  {
    ...list.help,
    subPages: [list.info_center],
  },
];

export const ICON_PROPS = {
  color: 'darkGrey',
  hoverColor: COLORS.MainBlue,
  size: 'extraSmall',
};
