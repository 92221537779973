import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { BodyText, ErrorText, RequiredStar } from '../fonts';
import { EyeWrapper, FieldWrapper, PasswordWrapper } from './FormField.styles';
import { Input } from '../inputs';
import { EyeClosedIcon, EyeOpenedIcon } from '../icons';

const PasswordField = ({
  isRequired,
  label,
  name,
  placeholder,
  onChange,
  onBlur,
  form: { values, errors, touched },
  ...props
}) => {
  const [inputType, setInputType] = useState('password');

  const renderError = () => {
    if (touched) {
      return touched[name] && errors[name] ? (
        <ErrorText id='azcvdhylvu'>*{errors[name]}</ErrorText>
      ) : null;
    }
    return errors[name] ? (
      <ErrorText id='cqagwfdbid'>*{errors[name]}</ErrorText>
    ) : null;
  };

  return (
    <FieldWrapper id='fufrkvcmls'>
      <BodyText id='ayxknyogqh'>
        {label}
        {isRequired && <RequiredStar id='srviamppyc' />}
      </BodyText>
      <PasswordWrapper id='qvoimkjelm'>
        <Input
          id={name}
          name={name}
          type={inputType}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          value={values[name]}
          error={errors[name]}
          {...props}
        />
        <EyeWrapper id='eucwkskcgy'>
          {inputType === 'password' ? (
            <EyeOpenedIcon
              id='hxxgrtizow'
              onClick={() => setInputType('text')}
            />
          ) : (
            <EyeClosedIcon
              id='mpiyfdrfwx'
              onClick={() => setInputType('password')}
            />
          )}
        </EyeWrapper>
      </PasswordWrapper>
      {renderError()}
    </FieldWrapper>
  );
};

PasswordField.propTypes = {
  name: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
};

PasswordField.defaultProps = {
  isRequired: false,
  label: '',
  placeholder: '',
};

export default PasswordField;
