import React from 'react';
import { FlexContainer } from '../flexContainer';
import { FlexItem } from '../flexItem';
import * as icons from '../icons';
import {
  IconWrapper,
  StateInfoHeading,
  StateInfoLabel,
  StateInfoWrapper,
} from './styles';

const StateInfo = ({
  children,
  title,
  subtitle,
  icon,
  cameraPipelineStatus,
}) => {
  const IconComponent = icons[icon];
  return (
    <FlexContainer id='wugxrjoedi' align='center' justify='center' fluid>
      <StateInfoWrapper id='hdotuwowcg'>
        <FlexItem id='mmemlujwmf' align='center' justify='center'>
          <StateInfoHeading id='hdqrtclbvf'>{title}</StateInfoHeading>
        </FlexItem>
        <FlexItem id='fhwiirtria'>
          <StateInfoLabel id='jftghsewza'>{subtitle}</StateInfoLabel>
        </FlexItem>
        <FlexItem id='ihtsvyujcm' style={{ margin: '-30px 0 10px 0' }}>
          {cameraPipelineStatus}
        </FlexItem>
        <FlexContainer id='krhfwrxpxy' justify='center' align='center'>
          <IconWrapper id='dnpaxpefeg'>
            <IconComponent id='orzwckhbjt' customSize={60} color='blue' />
          </IconWrapper>
        </FlexContainer>
        <FlexItem id='kdtgjqjmnl'>{children}</FlexItem>
      </StateInfoWrapper>
    </FlexContainer>
  );
};

export default StateInfo;
