import React from 'react';
import PropTypes from 'prop-types';
import { BodyText, ErrorText, RequiredStar } from '../fonts';
import { SelectComponent } from '../selects';
import { FieldWrapper } from './FormField.styles';
import { getCorrectPositionValue } from '../selects/utils';

const SelectField = ({
  isRequired,
  label,
  name,
  placeholder,
  value,
  options,
  onChange,
  form: { errors, touched },
  ...props
}) => {
  return (
    <FieldWrapper id='bcdogupnbv'>
      <BodyText id='jzcqtjzfde'>
        {label}
        {isRequired && <RequiredStar id='cxvmbetgta' />}
      </BodyText>
      <SelectComponent
        id={name}
        placeholder={placeholder}
        value={getCorrectPositionValue(options, value)}
        options={options}
        onChange={onChange}
        error={errors[name]}
        {...props}
      />
      {touched[name] && errors[name] ? (
        <ErrorText id='shmlaemknv'>*{errors[name]}</ErrorText>
      ) : null}
    </FieldWrapper>
  );
};

SelectField.propTypes = {
  name: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
};

SelectField.defaultProps = {
  isRequired: false,
  label: '',
  placeholder: '',
};

export default SelectField;
