// Libs
import styled, { css } from 'styled-components';
// Constants
import { COLORS } from '../colors';

export const baseFontStyles = css`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: normal;
  color: ${({ color }) => color || COLORS.TextBlack};

  & strong {
    font-weight: 700;
  }
  & i {
    font-style: italic;
  }
`;

export const Heading1 = styled.h1`
  ${baseFontStyles};
  font-size: 36px;
  line-height: 44px;
  letter-spacing: 0.25px;
`;

export const Heading2 = styled.h2`
  ${baseFontStyles};
  font-size: 28px;
  line-height: 34px;
`;

export const Heading3 = styled.h3`
  ${baseFontStyles};
  font-size: 24px;
  line-height: 30px;
`;

export const Heading4 = styled.h4`
  ${baseFontStyles};
  font-size: 22px;
  line-height: 28px;
  letter-spacing: 0.25px;
`;

export const Heading5 = styled.h5`
  ${baseFontStyles};
  text-align: justify;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.25px;
`;

export const Heading6 = styled.h6`
  ${baseFontStyles};
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.01em;
`;

export const Heading7 = styled.h6`
  ${baseFontStyles};
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.01em;
  color: #979797;
`;

export const Heading8 = styled.h2`
  ${baseFontStyles};
  font-weight: 500;
  font-size: 32px;
  line-height: 0px;
  text-align: center;
  margin-left: 17px;
  margin-top: auto;
  margin-bottom: auto;
  color: TextBlack;

  mix-blend-mode: normal;
`;

export const Trademark = styled.p`
  ${baseFontStyles};
  font-size: 10px;
  color: #979797;
`;

export const SubTitle = styled.p`
  ${baseFontStyles};
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: ${({ color }) => color ?? COLORS.TextBlack};
`;

export const SubtitleStroke = styled(SubTitle)`
  color: ${COLORS.Gray2};
  text-transform: uppercase;
  font-size: 18px;
`;

export const SideBarTitle = styled.p`
  ${baseFontStyles};
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;
  text-transform: uppercase;
  white-space: nowrap;

  &:hover {
    color: ${COLORS.MainBlue};
  }
`;

export const RequiredStar = styled.span`
  font-size: inherit;
  line-height: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: ${COLORS.Red};
  &::after {
    content: '*';
  }
`;

export const BodyText = styled.p`
  ${baseFontStyles};
  white-space: nowrap;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.02em;
  text-transform: ${({ capitals }) => (capitals ? 'capitalize' : 'none')};
  color: ${({ color }) => color ?? COLORS.TextBlack};
`;

export const BodyTextStroke = styled.p`
  ${baseFontStyles};
  color: ${COLORS.Stroke};
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.02em;
`;

export const BodyTextBold = styled.p`
  ${baseFontStyles};
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
`;

export const BodyTextBoldBigger = styled.p`
  ${baseFontStyles};
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
`;

export const BodyTextSmall = styled.p`
  ${baseFontStyles};
  font-size: 12px;
  line-height: 14px;
`;

export const BodyText2 = styled.p`
  ${baseFontStyles};
  font-size: 12px;
  line-height: 14px;
`;

export const FieldLabel = styled.label`
  ${baseFontStyles};
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.01em;
  color: ${COLORS.Gray2};
  ${({ margin }) => `margin: ${margin} ;`}
`;

export const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const CheckboxTitle = styled.span`
  ${baseFontStyles};
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.02em;

  margin-left: 8px;
`;

export const FieldValue = styled.div`
  margin-top: 8px;

  ${baseFontStyles};
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.02em;
`;

export const BodyTextSemiBold = styled.p`
  ${baseFontStyles};
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
`;

export const ErrorText = styled(BodyText2)`
  position: absolute;
  bottom: -14px;
  display: block;
  width: 100%;
  font-size: 12px;
  text-align: left;
  line-height: 14px;
  font-weight: 400;
  letter-spacing: 0.5px;
  color: ${COLORS.ErrorRed};
`;
