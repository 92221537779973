/* eslint-disable react/no-unknown-property */
import React from 'react';
import { COLORS } from '../colors';

export const LogoIcon = (
  <svg viewBox='0 0 48 48' style={{ '--fill': 'none', '--stroke': 'none' }}>
    <mask
      id='mask0'
      mask-type='alpha'
      maskUnits='userSpaceOnUse'
      x='0'
      y='0'
      width='48'
      height='48'
    >
      <rect width='48' height='48' fill='white' />
    </mask>
    <g mask='url(#mask0)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M19.5303 29.3387L25.3982 16.1387H29.1138L35.0312 29.5148H31.2605L30.1872 27.0378H26.8018L25.1725 25.1222L23.3835 29.5148H19.6404L19.5303 29.3387ZM27.2147 20.102L25.6128 24.0323H29.0312L27.2147 20.102Z'
        fill='#BC4F4F'
      />
      <path
        d='M35.7412 16.1387L35.8513 29.5148H39.1541V16.1387H35.7412Z'
        fill='#BC4F4F'
      />
      <path
        d='M36.7757 30.3682L35.6748 32.8562H36.4014L36.8198 31.8379L37.117 31.1058L37.4528 31.8379H36.8198L36.7372 32.0361L37.0399 32.3939H37.6675L37.8656 32.8562H38.5702L37.4693 30.3682H36.7757Z'
        fill='#BC4F4F'
      />
      <path
        d='M38.7021 30.3682L38.7242 32.8562H39.3352V30.3682H38.7021Z'
        fill='#BC4F4F'
      />
      <path
        d='M19.6403 29.515L19.5302 29.3389L19.4531 29.515H19.6403Z'
        fill='#4B4B4B'
      />
      <path
        d='M19.6403 29.515L19.5302 29.3389L19.4531 29.515H19.6403Z'
        fill='#4B4B4B'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M40.9436 7.04081C43.1454 9.24264 44.8738 11.8023 46.0793 14.6592C47.3289 17.6151 47.9619 20.7527 47.9619 23.9949C47.9619 27.2316 47.3289 30.3692 46.0793 33.3252C44.8738 36.1821 43.1454 38.7417 40.9436 40.9436C38.7417 43.1454 36.1821 44.8738 33.3252 46.0793C30.3692 47.3289 27.2316 47.9619 23.9949 47.9619C21.0885 47.9619 18.2647 47.45 15.5784 46.4426V45.9032V45.9029C15.5784 44.8076 15.5784 43.7123 15.5674 42.617C18.1381 43.7839 20.9949 44.4335 23.9949 44.4335C35.2683 44.4335 44.439 35.2683 44.439 23.9949C44.439 12.7215 35.2683 3.5509 23.9949 3.5509C12.7215 3.5509 3.5509 12.7215 3.5509 23.9949C3.5509 28.6023 5.08118 32.8573 7.66283 36.2812L7.61879 16.2555L16.0133 16.2004C16.0133 16.2004 19.7509 16.2004 19.5307 20.494C19.3931 23.1803 18.5399 24.1215 16.5913 24.65L19.5307 29.3454L19.4537 29.5215H19.6408L15.8481 29.6041L11.0592 22.8335L10.9931 39.7656L10.9766 44.1252C9.80411 43.3656 8.69769 42.5014 7.66833 41.5436C7.45916 41.3454 7.24448 41.1417 7.04081 40.9381C4.83897 38.7362 3.11053 36.1766 1.90503 33.3197C0.655488 30.3637 0.0224609 27.2261 0.0224609 23.9894C0.0224609 20.7527 0.655488 17.6151 1.91053 14.6592C3.11604 11.8023 4.84448 9.24264 7.04631 7.04081C9.24815 4.83897 11.8078 3.11053 14.6647 1.90503C17.6206 0.655488 20.7582 0.0224609 23.9949 0.0224609C27.2316 0.0224609 30.3692 0.655488 33.3252 1.90503C36.1821 3.11053 38.7417 4.83897 40.9436 7.04081ZM14.6032 18.7757H11.1904C11.1154 18.7757 11.0915 18.7833 11.0491 18.7969C11.0292 18.8033 11.0053 18.8109 10.9702 18.8197C10.9867 20.0197 10.9977 21.5995 10.9977 21.7151C11.0968 21.7371 14.6032 21.7481 14.6032 21.7481C15.3463 21.7481 15.9518 21.1426 15.9518 20.3995V20.1243C15.9518 19.3812 15.3463 18.7757 14.6032 18.7757Z'
        fill='url(#paint0_linear)'
      />
      <path
        d='M25.0674 31.8379L25.3482 31.1058L25.6674 31.8379H25.0674L24.9849 32.0361L25.2766 32.3939H25.8766L26.0638 32.8562H26.7298L25.684 30.3682H25.0289L23.9775 32.8562H24.6711L24.9849 32.0361L25.0674 31.8379Z'
        fill='url(#paint1_linear)'
      />
      <path
        d='M23.6972 31.6569C23.7467 31.5853 23.7908 31.5082 23.8183 31.4202C23.8458 31.3376 23.8623 31.2495 23.8623 31.1669C23.8623 31.0679 23.8458 30.9743 23.8073 30.8752C23.7688 30.7816 23.7192 30.6936 23.6532 30.6165C23.5871 30.5394 23.5156 30.4789 23.4275 30.4348C23.3394 30.3908 23.2458 30.3633 23.1523 30.3633H22.2605H21.7266V32.8624H22.2605V31.9266V31.4367V30.9027H23.1523C23.1743 30.9027 23.2018 30.9137 23.2238 30.9303C23.2458 30.9468 23.2678 30.9688 23.2789 30.9963C23.2954 31.0238 23.3064 31.0514 23.3174 31.0844C23.3284 31.1174 23.3339 31.1449 23.3339 31.1669C23.3339 31.1945 23.3284 31.222 23.3174 31.255C23.3064 31.2881 23.2954 31.3156 23.2789 31.3431C23.2623 31.3706 23.2458 31.3926 23.2238 31.4147C23.2018 31.4312 23.1798 31.4422 23.1523 31.4422H22.2605V31.9321L22.2715 31.9761H22.7174L23.2954 32.8679H23.8678L23.2844 31.9541C23.3669 31.9321 23.4385 31.8936 23.51 31.844C23.5816 31.7945 23.6422 31.7339 23.6972 31.6569Z'
        fill='url(#paint2_linear)'
      />
      <path
        d='M28.2495 32.1186C28.189 32.1847 28.1174 32.2342 28.0349 32.2728C27.9523 32.3113 27.8642 32.3333 27.7706 32.3333H27.5945V32.8673H27.7706C27.9413 32.8673 28.1064 32.8342 28.2551 32.7682C28.4092 32.7021 28.5413 32.614 28.6514 32.4984C28.767 32.3828 28.8551 32.2507 28.9211 32.1021C28.9872 31.948 29.0202 31.7883 29.0202 31.6177C29.0202 31.4471 28.9872 31.2819 28.9211 31.1333C28.8551 30.9792 28.767 30.8471 28.6514 30.737C28.5358 30.6214 28.4037 30.5333 28.2551 30.4672C28.1009 30.4012 27.9413 30.3682 27.7706 30.3682H27.4129H26.8789V32.8673H27.4129V30.9021H27.7706C27.8642 30.9021 27.9523 30.9241 28.0349 30.9627C28.1174 31.0012 28.189 31.0507 28.2495 31.1168C28.3101 31.1828 28.3596 31.2544 28.3982 31.3425C28.4312 31.4306 28.4532 31.5186 28.4532 31.6177C28.4532 31.7168 28.4367 31.8049 28.3982 31.8929C28.3596 31.9755 28.3156 32.0526 28.2495 32.1186Z'
        fill='url(#paint3_linear)'
      />
      <rect
        x='29.2676'
        y='30.3682'
        width='0.533941'
        height='2.48808'
        fill='url(#paint4_linear)'
      />
      <path
        d='M30.3574 30.3682V31.6177C30.3574 31.7883 30.3794 31.9535 30.429 32.1021C30.473 32.2562 30.5446 32.3884 30.6382 32.4984C30.7317 32.614 30.8418 32.7021 30.9739 32.7682C31.106 32.8342 31.2602 32.8673 31.4308 32.8673C31.6015 32.8673 31.7556 32.8342 31.8877 32.7682C32.0198 32.7021 32.1354 32.614 32.2235 32.4984C32.3171 32.3828 32.3831 32.2507 32.4326 32.1021C32.4767 31.948 32.5042 31.7883 32.5042 31.6177V30.3682H31.9703V31.6177C31.9703 31.7113 31.9593 31.8049 31.9372 31.8874C31.9207 31.9755 31.8877 32.0471 31.8437 32.1131C31.7996 32.1792 31.7446 32.2287 31.6785 32.2728C31.6125 32.3113 31.5299 32.3333 31.4308 32.3333C31.3317 32.3333 31.2492 32.3113 31.1831 32.2728C31.1171 32.2342 31.062 32.1792 31.018 32.1131C30.9739 32.0471 30.9464 31.97 30.9244 31.8874C30.9079 31.7994 30.8969 31.7113 30.8914 31.6177V30.3682H30.3574Z'
        fill='url(#paint5_linear)'
      />
      <path
        d='M33.5115 30.9025H34.5298C34.5684 30.8144 34.6344 30.6823 34.6454 30.6603C34.6785 30.5832 34.717 30.5061 34.75 30.4291V30.374H33.506C33.4069 30.374 33.3188 30.3905 33.2308 30.4291C33.1427 30.4676 33.0711 30.5171 33.0051 30.5832C32.939 30.6493 32.8895 30.7208 32.8509 30.8089C32.8124 30.897 32.7959 30.985 32.7959 31.0841C32.7959 31.1832 32.8124 31.2713 32.8509 31.3593C32.8895 31.4474 32.939 31.519 33.0051 31.585C33.0711 31.6511 33.1427 31.7006 33.2308 31.7392C33.3188 31.7777 33.4069 31.7942 33.506 31.7942H34.2161C34.2381 31.7942 34.2601 31.8052 34.2876 31.8217C34.3097 31.8382 34.3317 31.8658 34.3427 31.8933C34.3592 31.9208 34.3702 31.9483 34.3812 31.9814C34.3922 32.0144 34.3977 32.0419 34.3977 32.0639C34.3977 32.0915 34.3922 32.119 34.3812 32.1465C34.3702 32.174 34.3592 32.2071 34.3427 32.2291C34.3262 32.2566 34.3097 32.2786 34.2876 32.2951C34.2656 32.3116 34.2436 32.3226 34.2161 32.3226H33.1922C33.1537 32.4052 33.1097 32.5043 33.1042 32.5208C33.0601 32.6144 33.0216 32.708 32.9775 32.8015V32.8566H34.2216C34.3207 32.8566 34.4087 32.8346 34.4968 32.785C34.5849 32.741 34.6564 32.6804 34.7225 32.6034C34.7886 32.5263 34.8381 32.4437 34.8766 32.3502C34.9152 32.2566 34.9317 32.1575 34.9317 32.0584C34.9317 31.9593 34.9152 31.8658 34.8766 31.7667C34.8381 31.6731 34.7886 31.585 34.7225 31.508C34.6564 31.4309 34.5849 31.3704 34.4968 31.3208C34.4087 31.2713 34.3207 31.2493 34.2216 31.2493H33.5115C33.462 31.2493 33.4179 31.2327 33.3849 31.1997C33.3519 31.1667 33.3353 31.1226 33.3353 31.0731C33.3353 31.0236 33.3519 30.9795 33.3849 30.9465C33.4179 30.919 33.462 30.9025 33.5115 30.9025Z'
        fill='url(#paint6_linear)'
      />
    </g>
    <defs>
      <linearGradient
        id='paint0_linear'
        x1='65.2928'
        y1='23.4187'
        x2='23.4579'
        y2='-16.1042'
        gradientUnits='userSpaceOnUse'
      >
        <stop offset='0.00537634' stopColor='#7846C4' />
        <stop offset='0.6745' stopColor='#2B89EE' />
        <stop offset='1' stopColor='#0BA5FF' />
      </linearGradient>
      <linearGradient
        id='paint1_linear'
        x1='37.1719'
        y1='27.131'
        x2='22.6798'
        y2='23.5977'
        gradientUnits='userSpaceOnUse'
      >
        <stop offset='0.00537634' stopColor='#7846C4' />
        <stop offset='0.6745' stopColor='#2B89EE' />
        <stop offset='1' stopColor='#0BA5FF' />
      </linearGradient>
      <linearGradient
        id='paint2_linear'
        x1='36.7142'
        y1='25.6463'
        x2='21.96'
        y2='23.4975'
        gradientUnits='userSpaceOnUse'
      >
        <stop offset='0.00537634' stopColor='#7846C4' />
        <stop offset='0.6745' stopColor='#2B89EE' />
        <stop offset='1' stopColor='#0BA5FF' />
      </linearGradient>
      <linearGradient
        id='paint3_linear'
        x1='36.9051'
        y1='24.7202'
        x2='22.1519'
        y2='22.5621'
        gradientUnits='userSpaceOnUse'
      >
        <stop offset='0.00537634' stopColor='#7846C4' />
        <stop offset='0.6745' stopColor='#2B89EE' />
        <stop offset='1' stopColor='#0BA5FF' />
      </linearGradient>
      <linearGradient
        id='paint4_linear'
        x1='36.0509'
        y1='-1.26676'
        x2='21.3833'
        y2='-1.40135'
        gradientUnits='userSpaceOnUse'
      >
        <stop offset='0.00537634' stopColor='#7846C4' />
        <stop offset='0.6745' stopColor='#2B89EE' />
        <stop offset='1' stopColor='#0BA5FF' />
      </linearGradient>
      <linearGradient
        id='paint5_linear'
        x1='37.0804'
        y1='23.8766'
        x2='22.3282'
        y2='21.7075'
        gradientUnits='userSpaceOnUse'
      >
        <stop offset='0.00537634' stopColor='#7846C4' />
        <stop offset='0.6745' stopColor='#2B89EE' />
        <stop offset='1' stopColor='#0BA5FF' />
      </linearGradient>
      <linearGradient
        id='paint6_linear'
        x1='37.1581'
        y1='23.5087'
        x2='22.4065'
        y2='21.3333'
        gradientUnits='userSpaceOnUse'
      >
        <stop offset='0.00537634' stopColor='#7846C4' />
        <stop offset='0.6745' stopColor='#2B89EE' />
        <stop offset='1' stopColor='#0BA5FF' />
      </linearGradient>
    </defs>
  </svg>
);

export const ListIcon = (
  <svg viewBox='0 0 14 11'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4 9.5C4 9.36739 4.05268 9.24021 4.14645 9.14645C4.24021 9.05268 4.36739 9 4.5 9H13.5C13.6326 9 13.7598 9.05268 13.8536 9.14645C13.9473 9.24021 14 9.36739 14 9.5C14 9.63261 13.9473 9.75979 13.8536 9.85355C13.7598 9.94732 13.6326 10 13.5 10H4.5C4.36739 10 4.24021 9.94732 4.14645 9.85355C4.05268 9.75979 4 9.63261 4 9.5ZM4 5.5C4 5.36739 4.05268 5.24021 4.14645 5.14645C4.24021 5.05268 4.36739 5 4.5 5H13.5C13.6326 5 13.7598 5.05268 13.8536 5.14645C13.9473 5.24021 14 5.36739 14 5.5C14 5.63261 13.9473 5.75979 13.8536 5.85355C13.7598 5.94732 13.6326 6 13.5 6H4.5C4.36739 6 4.24021 5.94732 4.14645 5.85355C4.05268 5.75979 4 5.63261 4 5.5ZM4 1.5C4 1.36739 4.05268 1.24021 4.14645 1.14645C4.24021 1.05268 4.36739 1 4.5 1H13.5C13.6326 1 13.7598 1.05268 13.8536 1.14645C13.9473 1.24021 14 1.36739 14 1.5C14 1.63261 13.9473 1.75979 13.8536 1.85355C13.7598 1.94732 13.6326 2 13.5 2H4.5C4.36739 2 4.24021 1.94732 4.14645 1.85355C4.05268 1.75979 4 1.63261 4 1.5ZM1 2.5C1.26522 2.5 1.51957 2.39464 1.70711 2.20711C1.89464 2.01957 2 1.76522 2 1.5C2 1.23478 1.89464 0.98043 1.70711 0.792893C1.51957 0.605357 1.26522 0.5 1 0.5C0.734784 0.5 0.48043 0.605357 0.292893 0.792893C0.105357 0.98043 0 1.23478 0 1.5C0 1.76522 0.105357 2.01957 0.292893 2.20711C0.48043 2.39464 0.734784 2.5 1 2.5ZM1 6.5C1.26522 6.5 1.51957 6.39464 1.70711 6.20711C1.89464 6.01957 2 5.76522 2 5.5C2 5.23478 1.89464 4.98043 1.70711 4.79289C1.51957 4.60536 1.26522 4.5 1 4.5C0.734784 4.5 0.48043 4.60536 0.292893 4.79289C0.105357 4.98043 0 5.23478 0 5.5C0 5.76522 0.105357 6.01957 0.292893 6.20711C0.48043 6.39464 0.734784 6.5 1 6.5ZM1 10.5C1.26522 10.5 1.51957 10.3946 1.70711 10.2071C1.89464 10.0196 2 9.76522 2 9.5C2 9.23478 1.89464 8.98043 1.70711 8.79289C1.51957 8.60536 1.26522 8.5 1 8.5C0.734784 8.5 0.48043 8.60536 0.292893 8.79289C0.105357 8.98043 0 9.23478 0 9.5C0 9.76522 0.105357 10.0196 0.292893 10.2071C0.48043 10.3946 0.734784 10.5 1 10.5Z'
    />
  </svg>
);

export const CameraIcon = (
  <svg viewBox='0 0 14 12'>
    <path d='M13.25 2.34648L11.25 3.49805V1.12305C11.25 0.571484 10.8016 0.123047 10.25 0.123047H1C0.448438 0.123047 0 0.571484 0 1.12305V10.123C0 10.6746 0.448438 11.123 1 11.123H10.25C10.8016 11.123 11.25 10.6746 11.25 10.123V7.74805L13.25 8.89961C13.5828 9.0918 14 8.85117 14 8.46836V2.7793C14 2.39492 13.5828 2.1543 13.25 2.34648ZM10.125 9.99805H1.125V1.24805H10.125V9.99805ZM12.875 7.38867L11.25 6.4543V4.79336L12.875 3.85742V7.38867ZM2.25 3.24805H4C4.06875 3.24805 4.125 3.1918 4.125 3.12305V2.37305C4.125 2.3043 4.06875 2.24805 4 2.24805H2.25C2.18125 2.24805 2.125 2.3043 2.125 2.37305V3.12305C2.125 3.1918 2.18125 3.24805 2.25 3.24805Z' />
  </svg>
);

export const PipelineIcon = (
  <svg viewBox='0 0 16 16'>
    <path d='M15 4.5C15 4.22386 14.7761 4 14.5 4H12.95C12.7 2.85 11.7 2 10.5 2C9.3 2 8.3 2.85 8.05 4H1.5C1.22386 4 1 4.22386 1 4.5V4.5C1 4.77614 1.22386 5 1.5 5H8.05C8.3 6.15 9.3 7 10.5 7C11.7 7 12.7 6.15 12.95 5H14.5C14.7761 5 15 4.77614 15 4.5V4.5ZM10.5 6C9.65 6 9 5.35 9 4.5C9 3.65 9.65 3 10.5 3C11.35 3 12 3.65 12 4.5C12 5.35 11.35 6 10.5 6Z' />
    <path d='M1 11.5C1 11.7761 1.22386 12 1.5 12H3.05C3.3 13.15 4.3 14 5.5 14C6.7 14 7.7 13.15 7.95 12H14.5C14.7761 12 15 11.7761 15 11.5V11.5C15 11.2239 14.7761 11 14.5 11H7.95C7.7 9.85 6.7 9 5.5 9C4.3 9 3.3 9.85 3.05 11H1.5C1.22386 11 1 11.2239 1 11.5V11.5ZM5.5 10C6.35 10 7 10.65 7 11.5C7 12.35 6.35 13 5.5 13C4.65 13 4 12.35 4 11.5C4 10.65 4.65 10 5.5 10Z' />
  </svg>
);

export const LocationIcon = (
  <svg viewBox='0 0 16 16'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8.04598 1.30575C6.61198 1.30575 5.23671 1.8754 4.22272 2.88939C3.20873 3.90338 2.63908 5.27865 2.63908 6.71264C2.63908 8.81028 3.99688 10.8081 5.42979 12.3206C6.13835 13.0685 6.84822 13.6799 7.38138 14.1045C7.64766 14.3165 7.86907 14.4813 8.02317 14.5926C8.03095 14.5982 8.03855 14.6037 8.04597 14.6091C8.0534 14.6037 8.061 14.5982 8.06878 14.5926C8.22288 14.4813 8.44429 14.3165 8.71057 14.1045C9.24373 13.6799 9.9536 13.0685 10.6622 12.3206C12.0951 10.8081 13.4529 8.81028 13.4529 6.71264C13.4529 5.27865 12.8832 3.90338 11.8692 2.88939C10.8552 1.8754 9.47997 1.30575 8.04598 1.30575ZM8.04598 15.0805C7.83175 15.4018 7.83164 15.4017 7.83151 15.4016L7.83001 15.4006L7.82618 15.3981L7.81236 15.3887C7.80046 15.3806 7.78323 15.3689 7.76102 15.3535C7.71662 15.3227 7.65231 15.2776 7.57093 15.2188C7.40822 15.1013 7.17705 14.9292 6.90022 14.7087C6.34718 14.2683 5.60877 13.6326 4.86906 12.8518C3.40541 11.3069 1.86666 9.12075 1.86666 6.71264C1.86666 5.07379 2.5177 3.50206 3.67654 2.34321C4.83539 1.18437 6.40712 0.533333 8.04598 0.533333C9.68483 0.533333 11.2566 1.18437 12.4154 2.34321C13.5743 3.50206 14.2253 5.07379 14.2253 6.71264C14.2253 9.12075 12.6865 11.3069 11.2229 12.8518C10.4832 13.6326 9.74477 14.2683 9.19173 14.7087C8.9149 14.9292 8.68373 15.1013 8.52102 15.2188C8.43964 15.2776 8.37533 15.3227 8.33093 15.3535C8.30873 15.3689 8.29149 15.3806 8.27959 15.3887L8.26577 15.3981L8.26194 15.4006L8.26044 15.4016C8.26031 15.4017 8.2602 15.4018 8.04598 15.0805ZM8.04598 15.0805L8.2602 15.4018C8.13048 15.4883 7.96147 15.4883 7.83175 15.4018L8.04598 15.0805ZM8.04598 5.16782C7.19279 5.16782 6.50115 5.85946 6.50115 6.71264C6.50115 7.56583 7.19279 8.25747 8.04598 8.25747C8.89916 8.25747 9.5908 7.56583 9.5908 6.71264C9.5908 5.85946 8.89916 5.16782 8.04598 5.16782ZM5.72873 6.71264C5.72873 5.43287 6.7662 4.3954 8.04598 4.3954C9.32575 4.3954 10.3632 5.43287 10.3632 6.71264C10.3632 7.99242 9.32575 9.02988 8.04598 9.02988C6.7662 9.02988 5.72873 7.99242 5.72873 6.71264Z'
    />
  </svg>
);

export const UserSimpleIcon = (
  <svg width='18' height='18' viewBox='0 0 18 18' fill='none'>
    <path d='M8.95 8.65078C10.3 8.65078 11.35 7.55078 11.35 6.20078C11.35 4.85078 10.25 3.80078 8.9 3.80078C7.55 3.80078 6.5 4.90078 6.5 6.20078C6.5 7.55078 7.6 8.65078 8.95 8.65078ZM8.9 4.80078C8.95 4.80078 8.95 4.80078 8.9 4.80078C9.7 4.80078 10.35 5.45078 10.35 6.25078C10.35 7.05078 9.7 7.65078 8.9 7.65078C8.1 7.65078 7.5 7.00078 7.5 6.25078C7.5 5.45078 8.15 4.80078 8.9 4.80078Z' />
    <path d='M16.35 8.34947C15.4 7.49947 14.15 7.04947 12.85 7.09947H12.45C12.35 7.49947 12.2 7.84947 12 8.14947C12.3 8.09947 12.55 8.09947 12.85 8.09947C13.8 8.04947 14.75 8.34947 15.5 8.89947V12.4995H16.5V8.49947L16.35 8.34947Z' />
    <path d='M11.7 3.90039C11.95 3.30039 12.65 3.00039 13.3 3.25039C13.9 3.50039 14.2 4.20039 13.95 4.85039C13.75 5.30039 13.3 5.60039 12.85 5.60039C12.75 5.60039 12.6 5.60039 12.5 5.55039C12.55 5.80039 12.55 6.05039 12.55 6.25039V6.55039C12.65 6.55039 12.75 6.60039 12.85 6.60039C14.1 6.60039 15.1 5.60039 15.1 4.40039C15.1 3.15039 14.1 2.15039 12.9 2.15039C12.1 2.15039 11.4 2.55039 11 3.25039C11.25 3.40039 11.5 3.60039 11.7 3.90039Z' />
    <path d='M6 8.20025C5.8 7.90025 5.65 7.55025 5.55 7.15025H5.15C3.85 7.10025 2.6 7.55025 1.65 8.35025L1.5 8.50025V12.5003H2.5V8.90025C3.3 8.35025 4.2 8.05025 5.15 8.10025C5.45 8.10025 5.75 8.15025 6 8.20025Z' />
    <path d='M5.14988 6.5491C5.24988 6.5491 5.34988 6.5491 5.44988 6.4991V6.1991C5.44988 5.9491 5.44988 5.6991 5.49988 5.4991C5.39988 5.5491 5.24988 5.5491 5.14988 5.5491C4.49988 5.5491 3.94988 4.9991 3.94988 4.3491C3.94988 3.6991 4.49988 3.1491 5.14988 3.1491C5.64988 3.1491 6.09988 3.4491 6.29988 3.8991C6.49988 3.6491 6.79988 3.3991 7.04988 3.1991C6.39988 2.1491 5.04988 1.7991 3.99988 2.4491C2.94988 3.0991 2.59988 4.4491 3.24988 5.4991C3.64988 6.1491 4.34988 6.5491 5.14988 6.5491Z' />
    <path d='M13.05 11.3507L12.95 11.2007C11.95 10.1007 10.55 9.45074 9.05 9.50074C7.55 9.45074 6.1 10.1007 5.1 11.2007L5 11.3507V15.1507C5 15.6007 5.35 16.0007 5.85 16.0007H12.25C12.7 16.0007 13.1 15.6007 13.1 15.1507V11.3507H13.05ZM12.05 15.0007H6V11.7007C6.8 10.9007 7.9 10.5007 9.05 10.5007C10.15 10.4507 11.25 10.9007 12.05 11.7007V15.0007Z' />
  </svg>
);

export const HistorySimpleIcon = (
  <svg viewBox='0 0 16 16'>
    <path d='M16.3125 2.83008H12.2906C11.4275 2.83008 10.5838 3.07793 9.85781 3.54551L9 4.0957L8.14219 3.54551C7.41693 3.07802 6.57224 2.82962 5.70937 2.83008H1.6875C1.37637 2.83008 1.125 3.08145 1.125 3.39258V13.377C1.125 13.6881 1.37637 13.9395 1.6875 13.9395H5.70937C6.57246 13.9395 7.41621 14.1873 8.14219 14.6549L8.92266 15.1576C8.94551 15.1717 8.97187 15.1805 8.99824 15.1805C9.02461 15.1805 9.05098 15.1734 9.07383 15.1576L9.8543 14.6549C10.582 14.1873 11.4275 13.9395 12.2906 13.9395H16.3125C16.6236 13.9395 16.875 13.6881 16.875 13.377V3.39258C16.875 3.08145 16.6236 2.83008 16.3125 2.83008ZM5.70937 12.6738H2.39062V4.0957H5.70937C6.33164 4.0957 6.93633 4.27324 7.4584 4.60899L8.31621 5.15918L8.4375 5.23828V13.3594C7.60078 12.9094 6.66562 12.6738 5.70937 12.6738ZM15.6094 12.6738H12.2906C11.3344 12.6738 10.3992 12.9094 9.5625 13.3594V5.23828L9.68379 5.15918L10.5416 4.60899C11.0637 4.27324 11.6684 4.0957 12.2906 4.0957H15.6094V12.6738ZM6.97676 6.3457H3.71074C3.64219 6.3457 3.58594 6.40547 3.58594 6.47754V7.26855C3.58594 7.34063 3.64219 7.40039 3.71074 7.40039H6.975C7.04355 7.40039 7.0998 7.34063 7.0998 7.26855V6.47754C7.10156 6.40547 7.04531 6.3457 6.97676 6.3457ZM10.8984 6.47754V7.26855C10.8984 7.34063 10.9547 7.40039 11.0232 7.40039H14.2875C14.3561 7.40039 14.4123 7.34063 14.4123 7.26855V6.47754C14.4123 6.40547 14.3561 6.3457 14.2875 6.3457H11.0232C10.9547 6.3457 10.8984 6.40547 10.8984 6.47754ZM6.97676 8.80664H3.71074C3.64219 8.80664 3.58594 8.86641 3.58594 8.93848V9.72949C3.58594 9.80156 3.64219 9.86133 3.71074 9.86133H6.975C7.04355 9.86133 7.0998 9.80156 7.0998 9.72949V8.93848C7.10156 8.86641 7.04531 8.80664 6.97676 8.80664ZM14.2893 8.80664H11.0232C10.9547 8.80664 10.8984 8.86641 10.8984 8.93848V9.72949C10.8984 9.80156 10.9547 9.86133 11.0232 9.86133H14.2875C14.3561 9.86133 14.4123 9.80156 14.4123 9.72949V8.93848C14.4141 8.86641 14.3578 8.80664 14.2893 8.80664Z' />
  </svg>
);

export const TemplateSimpleIcon = (
  <svg viewBox='0 0 16 16'>
    <path d='M3.66683 11.3333H2.8335C2.43567 11.3333 2.05414 11.1753 1.77284 10.894C1.49153 10.6127 1.3335 10.2312 1.3335 9.83333V3.83333C1.3335 3.3471 1.52665 2.88079 1.87047 2.53697C2.21428 2.19315 2.6806 2 3.16683 2H11.1668C11.3638 2 11.5589 2.0388 11.7409 2.11418C11.9228 2.18956 12.0882 2.30005 12.2275 2.43934C12.3668 2.57863 12.4773 2.74399 12.5526 2.92597C12.628 3.10796 12.6668 3.30302 12.6668 3.5V4.33333H13.1668C13.5647 4.33333 13.9462 4.49137 14.2275 4.77267C14.5088 5.05398 14.6668 5.43551 14.6668 5.83333V12.1667C14.6668 12.5645 14.5088 12.946 14.2275 13.2273C13.9462 13.5086 13.5647 13.6667 13.1668 13.6667H5.16683C4.769 13.6667 4.38747 13.5086 4.10617 13.2273C3.82486 12.946 3.66683 12.5645 3.66683 12.1667V11.3333ZM2.3335 3.83333V9.83333C2.3335 10.1093 2.5575 10.3333 2.8335 10.3333H3.66683V5.83333C3.66683 5.43551 3.82486 5.05398 4.10617 4.77267C4.38747 4.49137 4.769 4.33333 5.16683 4.33333H11.6668V3.5C11.6668 3.36739 11.6142 3.24021 11.5204 3.14645C11.4266 3.05268 11.2994 3 11.1668 3H3.16683C2.70683 3 2.3335 3.37333 2.3335 3.83333ZM4.66683 5.83333V12.1667C4.66683 12.4427 4.89083 12.6667 5.16683 12.6667H13.1668C13.2994 12.6667 13.4266 12.614 13.5204 12.5202C13.6142 12.4265 13.6668 12.2993 13.6668 12.1667V5.83333C13.6668 5.70073 13.6142 5.57355 13.5204 5.47978C13.4266 5.38601 13.2994 5.33333 13.1668 5.33333H5.16683C5.03422 5.33333 4.90704 5.38601 4.81328 5.47978C4.71951 5.57355 4.66683 5.70073 4.66683 5.83333Z' />
  </svg>
);

export const NoticeIcon = (
  <svg viewBox='0 0 22 22'>
    <path d='M11 0.5C5.20156 0.5 0.5 5.20156 0.5 11C0.5 16.7984 5.20156 21.5 11 21.5C16.7984 21.5 21.5 16.7984 21.5 11C21.5 5.20156 16.7984 0.5 11 0.5ZM11 19.7188C6.18594 19.7188 2.28125 15.8141 2.28125 11C2.28125 6.18594 6.18594 2.28125 11 2.28125C15.8141 2.28125 19.7188 6.18594 19.7188 11C19.7188 15.8141 15.8141 19.7188 11 19.7188Z' />
    <path d='M9.875 6.875C9.875 7.17337 9.99353 7.45952 10.2045 7.6705C10.4155 7.88147 10.7016 8 11 8C11.2984 8 11.5845 7.88147 11.7955 7.6705C12.0065 7.45952 12.125 7.17337 12.125 6.875C12.125 6.57663 12.0065 6.29048 11.7955 6.0795C11.5845 5.86853 11.2984 5.75 11 5.75C10.7016 5.75 10.4155 5.86853 10.2045 6.0795C9.99353 6.29048 9.875 6.57663 9.875 6.875ZM11.5625 9.5H10.4375C10.3344 9.5 10.25 9.58437 10.25 9.6875V16.0625C10.25 16.1656 10.3344 16.25 10.4375 16.25H11.5625C11.6656 16.25 11.75 16.1656 11.75 16.0625V9.6875C11.75 9.58437 11.6656 9.5 11.5625 9.5Z' />
  </svg>
);

export const VersionPageIcon = (
  <svg viewBox='0 0 18 18'>
    <path
      d='M2.14307 9L9.00021 12.4286L15.8719 9'
      strokeWidth='1.2'
      strokeLinecap='round'
      strokeLinejoin='round'
      fill='none'
    />
    <path
      d='M2.14307 12.4277L9.00021 15.8563L15.8719 12.4277'
      strokeWidth='1.2'
      strokeLinecap='round'
      strokeLinejoin='round'
      fill='none'
    />
    <path
      d='M2.14307 5.70572L9.00707 8.99972L15.8719 5.70572L9.00707 2.14258L2.14307 5.70572Z'
      strokeWidth='1.2'
      strokeLinecap='round'
      strokeLinejoin='round'
      fill='none'
    />
  </svg>
);

export const ChevronIcon = (
  <svg viewBox='0 0 13 9'>
    <path d='M11.295 0.794986L6.70496 5.37499L2.11496 0.794987L0.704956 2.20499L6.70496 8.20499L12.705 2.20499L11.295 0.794986Z' />
  </svg>
);

export const ToggleSidebarIcon = (
  <svg viewBox='0 0 16 14'>
    <path d='M6.14314 5.75056H14.7146C14.7931 5.75056 14.8574 5.68627 14.8574 5.6077V4.6077C14.8574 4.52913 14.7931 4.46484 14.7146 4.46484H6.14314C6.06456 4.46484 6.00028 4.52913 6.00028 4.6077V5.6077C6.00028 5.68627 6.06456 5.75056 6.14314 5.75056ZM6.00028 9.39341C6.00028 9.47199 6.06456 9.53627 6.14314 9.53627H14.7146C14.7931 9.53627 14.8574 9.47199 14.8574 9.39341V8.39341C14.8574 8.31484 14.7931 8.25056 14.7146 8.25056H6.14314C6.06456 8.25056 6.00028 8.31484 6.00028 8.39341V9.39341ZM15.0003 0.714844H1.00028C0.921708 0.714844 0.857422 0.779129 0.857422 0.857701V1.8577C0.857422 1.93627 0.921708 2.00056 1.00028 2.00056H15.0003C15.0788 2.00056 15.1431 1.93627 15.1431 1.8577V0.857701C15.1431 0.779129 15.0788 0.714844 15.0003 0.714844ZM15.0003 12.0006H1.00028C0.921708 12.0006 0.857422 12.0648 0.857422 12.1434V13.1434C0.857422 13.222 0.921708 13.2863 1.00028 13.2863H15.0003C15.0788 13.2863 15.1431 13.222 15.1431 13.1434V12.1434C15.1431 12.0648 15.0788 12.0006 15.0003 12.0006ZM0.918136 7.12377L3.70921 9.32199C3.81278 9.40413 3.96635 9.33091 3.96635 9.19877V4.80234C3.96635 4.6702 3.81456 4.59699 3.70921 4.67913L0.918136 6.87734C0.899369 6.89193 0.884182 6.91062 0.873736 6.93197C0.863289 6.95333 0.857859 6.97679 0.857859 7.00056C0.857859 7.02433 0.863289 7.04779 0.873736 7.06914C0.884182 7.0905 0.899369 7.10918 0.918136 7.12377Z' />
  </svg>
);

export const SearchSimpleIcon = (
  <svg viewBox='0 0 20 20'>
    <path d='M19.3187 18.0273L13.232 11.9406C14.1766 10.7195 14.6875 9.22656 14.6875 7.65625C14.6875 5.77656 13.9539 4.01406 12.6273 2.68516C11.3008 1.35625 9.53359 0.625 7.65625 0.625C5.77891 0.625 4.01172 1.35859 2.68516 2.68516C1.35625 4.01172 0.625 5.77656 0.625 7.65625C0.625 9.53359 1.35859 11.3008 2.68516 12.6273C4.01172 13.9562 5.77656 14.6875 7.65625 14.6875C9.22656 14.6875 10.7172 14.1766 11.9383 13.2344L18.025 19.3187C18.0428 19.3366 18.064 19.3508 18.0874 19.3604C18.1107 19.3701 18.1357 19.3751 18.1609 19.3751C18.1862 19.3751 18.2112 19.3701 18.2345 19.3604C18.2578 19.3508 18.279 19.3366 18.2969 19.3187L19.3187 18.2992C19.3366 18.2814 19.3508 18.2602 19.3604 18.2369C19.3701 18.2135 19.3751 18.1885 19.3751 18.1633C19.3751 18.138 19.3701 18.113 19.3604 18.0897C19.3508 18.0664 19.3366 18.0452 19.3187 18.0273ZM11.3688 11.3688C10.375 12.3602 9.05781 12.9062 7.65625 12.9062C6.25469 12.9062 4.9375 12.3602 3.94375 11.3688C2.95234 10.375 2.40625 9.05781 2.40625 7.65625C2.40625 6.25469 2.95234 4.93516 3.94375 3.94375C4.9375 2.95234 6.25469 2.40625 7.65625 2.40625C9.05781 2.40625 10.3773 2.95 11.3688 3.94375C12.3602 4.9375 12.9062 6.25469 12.9062 7.65625C12.9062 9.05781 12.3602 10.3773 11.3688 11.3688Z' />
  </svg>
);

export const CrossIcon = (
  <svg viewBox='0 0 14 14'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10.9602 12.3744L11.6673 13.0815L13.0815 11.6673L12.3744 10.9602L8.41487 7.00059L12.3747 3.04075L13.0818 2.33364L11.6676 0.919427L10.9605 1.62653L7.00065 5.58638L3.04051 1.62624L2.3334 0.919128L0.919189 2.33334L1.6263 3.04045L5.58644 7.00059L1.62657 10.9605L0.919459 11.6676L2.33367 13.0818L3.04078 12.3747L7.00065 8.41481L10.9602 12.3744Z'
    />
  </svg>
);

export const EditSimpleIcon = (
  <svg viewBox='0 0 13 12'>
    <path d='M7.74052 4L8.36719 4.62667L2.31385 10.6667H1.70052V10.0533L7.74052 4ZM10.1405 0C9.97385 0 9.80052 0.0666666 9.67385 0.193333L8.45385 1.41333L10.9539 3.91333L12.1739 2.69333C12.4339 2.43333 12.4339 2 12.1739 1.75333L10.6139 0.193333C10.4805 0.06 10.3139 0 10.1405 0ZM7.74052 2.12667L0.367188 9.5V12H2.86719L10.2405 4.62667L7.74052 2.12667Z' />
  </svg>
);

export const TrashBinIcon = (
  <svg viewBox='0 0 14 18'>
    <path d='M9.12 7.47L7 9.59L4.87 7.47L3.46 8.88L5.59 11L3.47 13.12L4.88 14.53L7 12.41L9.12 14.53L10.53 13.12L8.41 11L10.53 8.88L9.12 7.47ZM10.5 1L9.5 0H4.5L3.5 1H0V3H14V1H10.5ZM1 16C1 16.5304 1.21071 17.0391 1.58579 17.4142C1.96086 17.7893 2.46957 18 3 18H11C11.5304 18 12.0391 17.7893 12.4142 17.4142C12.7893 17.0391 13 16.5304 13 16V4H1V16ZM3 6H11V16H3V6Z' />
  </svg>
);

export const EditPolygonIcon = (
  <svg viewBox='0 0 24 24'>
    <path d='M19,8 C18.4477153,8 18,7.55228475 18,7 L18,6.92895948 L5.99666253,5.08229218 C5.95481263,5.59608198 5.52457637,6 5,6 L5,18 C5.52457637,18 5.95481263,18.403918 5.99666253,18.9177078 L18,17.0710405 L18,17 C18,16.4477153 18.4477153,16 19,16 L19,8 Z M20,8 L20,16 C20.5522847,16 21,16.4477153 21,17 L21,18 C21,18.5522847 20.5522847,19 20,19 L19,19 C18.4754236,19 18.0451874,18.596082 18.0033375,18.0822922 L6,19.9289595 L6,20 C6,20.5522847 5.55228475,21 5,21 L4,21 C3.44771525,21 3,20.5522847 3,20 L3,19 C3,18.4477153 3.44771525,18 4,18 L4,6 C3.44771525,6 3,5.55228475 3,5 L3,4 C3,3.44771525 3.44771525,3 4,3 L5,3 C5.55228475,3 6,3.44771525 6,4 L6,4.07104052 L18.0033375,5.91770782 C18.0451874,5.40391802 18.4754236,5 19,5 L20,5 C20.5522847,5 21,5.44771525 21,6 L21,7 C21,7.55228475 20.5522847,8 20,8 Z M5,19.4910004 L5,19 L4,19 L4,20 L5,20 L5,19.5077646 C4.99990594,19.5021617 4.99990637,19.4965736 5,19.4910022 L5,19.4910004 Z M19,17.5089996 L19,18 L20,18 L20,17 L19,17 L19,17.4922354 C19.0000941,17.4978383 19.0000936,17.5034264 19,17.5089978 L19,17.5089996 Z M5,4.49223544 L5,4 L4,4 L4,5 L5,5 L5,4.5089996 C4.99990637,4.50342635 4.99990594,4.49783831 5,4.49223545 L5,4.49223544 Z M19,6.50776456 L19,7 L20,7 L20,6 L19,6 L19,6.4910004 C19.0000936,6.49657365 19.0000941,6.50216169 19,6.50776455 L19,6.50776456 Z' />
  </svg>
);

export const ResetIcon = (
  <svg viewBox='0 0 12 12'>
    <path d='M10.2368 1.7625C9.68176 1.20364 9.02155 0.760171 8.29426 0.457673C7.56698 0.155176 6.78702 -0.000370766 5.99934 6.63634e-07C2.68434 6.63634e-07 0.00683594 2.685 0.00683594 6C0.00683594 9.315 2.68434 12 5.99934 12C8.79684 12 11.1293 10.0875 11.7968 7.5H10.2368C9.92789 8.37714 9.35435 9.13678 8.59534 9.67413C7.83634 10.2115 6.92929 10.5 5.99934 10.5C3.51684 10.5 1.49934 8.4825 1.49934 6C1.49934 3.5175 3.51684 1.5 5.99934 1.5C7.24434 1.5 8.35434 2.0175 9.16434 2.835L6.74934 5.25H11.9993V6.63634e-07L10.2368 1.7625Z' />
  </svg>
);

export const TooltipIcon = (
  <svg viewBox='0 0 16 16'>
    <circle cx='7.99992' cy='8.00004' r='6.66667' fill='#2F3B48' />
    <path
      d='M8.00008 6.66667C7.82327 6.66667 7.6537 6.7369 7.52868 6.86193C7.40365 6.98695 7.33342 7.15652 7.33342 7.33333V11.3333C7.33342 11.5101 7.40365 11.6797 7.52868 11.8047C7.6537 11.9298 7.82327 12 8.00008 12C8.17689 12 8.34646 11.9298 8.47149 11.8047C8.59651 11.6797 8.66675 11.5101 8.66675 11.3333V7.33333C8.66675 7.15652 8.59651 6.98695 8.47149 6.86193C8.34646 6.7369 8.17689 6.66667 8.00008 6.66667ZM8.00008 4C7.83526 4 7.67415 4.04887 7.53711 4.14044C7.40007 4.23201 7.29326 4.36216 7.23018 4.51443C7.16711 4.6667 7.15061 4.83426 7.18276 4.99591C7.21491 5.15756 7.29428 5.30605 7.41083 5.42259C7.52737 5.53913 7.67586 5.6185 7.83751 5.65065C7.99916 5.68281 8.16671 5.66631 8.31898 5.60323C8.47126 5.54016 8.6014 5.43335 8.69297 5.29631C8.78454 5.15927 8.83341 4.99815 8.83341 4.83333C8.83341 4.61232 8.74562 4.40036 8.58934 4.24408C8.43306 4.0878 8.2211 4 8.00008 4Z'
      fill='white'
    />
  </svg>
);

export const NoImageIcon = (
  <svg viewBox='0 0 60 60'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M56.25 6.40144L53.5986 3.75L3.75 53.5986L6.40144 56.25L10.1514 52.5H48.75C49.7442 52.4987 50.6972 52.1031 51.4002 51.4002C52.1031 50.6972 52.4987 49.7442 52.5 48.75V10.1514L56.25 6.40144ZM48.75 48.75H13.9014L28.5131 34.1381L32.9734 38.5982C33.6766 39.3014 34.6305 39.6965 35.625 39.6965C36.6195 39.6965 37.5734 39.3014 38.2766 38.5982L41.25 35.625L48.75 43.1199V48.75ZM48.75 37.8154L43.9016 32.9668C43.1984 32.2636 42.2445 31.8685 41.25 31.8685C40.2555 31.8685 39.3016 32.2636 38.5984 32.9668L35.625 35.9404L31.1681 31.4833L48.75 13.9014V37.8154Z'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11.25 41.25V35.625L20.625 26.2564L23.1999 28.8313L25.8547 26.1763L23.2766 23.5982C22.5734 22.8949 21.6195 22.4999 20.625 22.4999C19.6305 22.4999 18.6766 22.8949 17.9734 23.5982L11.25 30.3218V11.25H41.25V7.5H11.25C10.2557 7.50099 9.30249 7.8964 8.59945 8.59945C7.8964 9.30249 7.50099 10.2557 7.5 11.25V41.25H11.25Z'
    />
  </svg>
);

export const CheckRegularIcon = (
  <svg viewBox='0 0 14 10' data-testid='icon'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M13 0C12.72 0 12.47 0.11 12.29 0.29L5 7.59L1.71 4.29C1.53 4.11 1.28 4 1 4C0.45 4 0 4.45 0 5C0 5.28 0.11 5.53 0.29 5.71L4.29 9.71C4.47 9.89 4.72 10 5 10C5.28 10 5.53 9.89 5.71 9.71L13.71 1.71C13.89 1.53 14 1.28 14 1C14 0.45 13.55 0 13 0Z'
      fill='#62DE78'
    />
  </svg>
);

export const WarningIcon = (
  <svg viewBox='0 0 18 16'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10.31 1.34303L17.669 14.513C17.7539 14.6652 17.7975 14.8369 17.7955 15.0111C17.7936 15.1854 17.7462 15.3561 17.658 15.5063C17.5698 15.6566 17.4438 15.7812 17.2926 15.8678C17.1414 15.9544 16.9702 16 16.796 16H1.20401C1.02963 16.0001 0.858278 15.9545 0.70695 15.8678C0.555623 15.7812 0.429584 15.6565 0.341343 15.5061C0.253101 15.3557 0.205723 15.1848 0.203911 15.0104C0.2021 14.836 0.245916 14.6642 0.331014 14.512L7.69101 1.34303C7.82126 1.11026 8.01121 0.916442 8.2413 0.781531C8.47139 0.64662 8.73329 0.5755 9.00001 0.5755C9.26674 0.5755 9.52864 0.64662 9.75873 0.781531C9.98881 0.916442 10.1788 1.11026 10.309 1.34303H10.31ZM9.00001 4.50003C8.84423 4.50001 8.69025 4.53336 8.54844 4.59783C8.40662 4.6623 8.28026 4.75639 8.17784 4.87377C8.07543 4.99116 7.99934 5.12911 7.95469 5.27836C7.91005 5.42761 7.89788 5.58468 7.91901 5.73903L8.43201 9.50503C8.45032 9.6427 8.51801 9.76904 8.62249 9.86054C8.72697 9.95205 8.86113 10.0025 9.00001 10.0025C9.1389 10.0025 9.27306 9.95205 9.37754 9.86054C9.48202 9.76904 9.54971 9.6427 9.56801 9.50503L10.081 5.73903C10.1021 5.58468 10.09 5.42761 10.0453 5.27836C10.0007 5.12911 9.9246 4.99116 9.82219 4.87377C9.71977 4.75639 9.5934 4.6623 9.45159 4.59783C9.30977 4.53336 9.1558 4.50001 9.00001 4.50003ZM9.00001 13.13C9.29838 13.13 9.58453 13.0115 9.79551 12.8005C10.0065 12.5895 10.125 12.3034 10.125 12.005C10.125 11.7067 10.0065 11.4205 9.79551 11.2095C9.58453 10.9986 9.29838 10.88 9.00001 10.88C8.70164 10.88 8.4155 10.9986 8.20452 11.2095C7.99354 11.4205 7.87501 11.7067 7.87501 12.005C7.87501 12.3034 7.99354 12.5895 8.20452 12.8005C8.4155 13.0115 8.70164 13.13 9.00001 13.13Z'
    />
  </svg>
);

export const SuccessIcon = (
  <svg viewBox='0 0 20 20'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM7.3824 9.06892C7.50441 9.12133 7.61475 9.19751 7.707 9.29302L9 10.586L12.293 7.29302C12.3852 7.19751 12.4956 7.12133 12.6176 7.06892C12.7396 7.01651 12.8708 6.98892 13.0036 6.98777C13.1364 6.98662 13.2681 7.01192 13.391 7.0622C13.5138 7.11248 13.6255 7.18673 13.7194 7.28063C13.8133 7.37452 13.8875 7.48617 13.9378 7.60907C13.9881 7.73196 14.0134 7.86364 14.0122 7.99642C14.0111 8.1292 13.9835 8.26042 13.9311 8.38243C13.8787 8.50443 13.8025 8.61477 13.707 8.70702L9.707 12.707C9.51947 12.8945 9.26516 12.9998 9 12.9998C8.73483 12.9998 8.48053 12.8945 8.293 12.707L6.293 10.707C6.19749 10.6148 6.1213 10.5044 6.0689 10.3824C6.01649 10.2604 5.9889 10.1292 5.98775 9.99642C5.98659 9.86364 6.0119 9.73196 6.06218 9.60907C6.11246 9.48617 6.18671 9.37452 6.2806 9.28063C6.3745 9.18673 6.48615 9.11248 6.60904 9.0622C6.73194 9.01192 6.86362 8.98662 6.9964 8.98777C7.12918 8.98892 7.2604 9.01651 7.3824 9.06892Z'
    />
  </svg>
);

export const HomeConfigIcon = (
  <svg width='72' height='67' viewBox='0 0 72 67' fill='none'>
    <rect
      x='1.5'
      y='1.5'
      width='39'
      height='23'
      rx='3.5'
      stroke={COLORS.MainBlue}
      strokeWidth='3'
    />
    <rect
      x='48.5'
      y='1.5'
      width='22'
      height='23'
      rx='3.5'
      stroke={COLORS.Black}
      strokeWidth='3'
    />
    <rect
      x='48.5'
      y='32.5'
      width='22'
      height='33'
      rx='3.5'
      stroke={COLORS.Black}
      strokeWidth='3'
    />
    <rect
      x='17.5'
      y='32.5'
      width='23'
      height='23'
      rx='3.5'
      stroke={COLORS.Black}
      strokeWidth='3'
    />
  </svg>
);

export const TableIcon = (
  <svg
    width='124'
    height='124'
    viewBox='0 0 124 124'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M62 124C96.2417 124 124 96.2417 124 62C124 27.7583 96.2417 0 62 0C27.7583 0 0 27.7583 0 62C0 96.2417 27.7583 124 62 124Z'
      fill='white'
    />
    <rect
      x='40.5'
      y='40.5'
      width='43'
      height='43'
      rx='4.5'
      stroke='#1268FB'
      strokeWidth='3'
    />
    <path d='M40.5 49H83.5' stroke='#1268FB' strokeWidth='3' />
    <path d='M40.5 61H83.5' stroke='#1268FB' strokeWidth='3' />
    <path d='M54 83L54 50' stroke='#1268FB' strokeWidth='3' />
    <path d='M40.5 73H83.5' stroke='#1268FB' strokeWidth='3' />
  </svg>
);

export const ChartIcon = (
  <svg width='60' height='60' viewBox='0 0 60 60' fill='none'>
    <g clipPath='url(#clip0_13153_143350)'>
      <path
        d='M43.125 45C36.3767 45 33.6951 37.1704 31.1014 29.5989C28.6433 22.4218 26.1 15 20.625 15C19.3464 15.0752 18.1192 15.5293 17.0997 16.3045C16.0801 17.0797 15.3145 18.1409 14.9003 19.3528L11.3462 18.1571C11.4405 17.8751 13.7284 11.25 20.625 11.25C28.7796 11.25 31.8979 20.3526 34.65 28.3836C36.9159 35.0001 39.0563 41.25 43.125 41.25C44.4036 41.1748 45.6308 40.7207 46.6503 39.9455C47.6699 39.1703 48.4355 38.1091 48.8498 36.8972L52.4038 38.0929C52.3095 38.3749 50.0216 45 43.125 45Z'
        fill='#1268FB'
      />
      <path
        d='M7.5 52.5V31.875H11.25V28.125H7.5V3.75H3.75V52.5C3.75 53.4946 4.14509 54.4484 4.84835 55.1516C5.55161 55.8549 6.50544 56.25 7.5 56.25H56.25V52.5H7.5Z'
        fill='#1268FB'
      />
      <path d='M18.75 28.125H15V31.875H18.75V28.125Z' fill='#1268FB' />
      <path d='M26.25 28.125H22.5V31.875H26.25V28.125Z' fill='#1268FB' />
      <path d='M41.25 28.125H37.5V31.875H41.25V28.125Z' fill='#1268FB' />
      <path d='M48.75 28.125H45V31.875H48.75V28.125Z' fill='#1268FB' />
      <path d='M56.25 28.125H52.5V31.875H56.25V28.125Z' fill='#1268FB' />
    </g>
    <defs>
      <clipPath id='clip0_13153_143350'>
        <rect width='60' height='60' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export const DataIcon = (
  <svg
    width='60'
    height='60'
    viewBox='0 0 60 60'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_13168_143374)'>
      <path d='M30 15H11.25V18.75H30V15Z' />
      <path d='M26.25 22.5H11.25V26.25H26.25V22.5Z' />
      <path d='M18.75 30H11.25V33.75H18.75V30Z' />
      <path d='M52.5 48.75H13.9014L56.25 6.40144L53.5986 3.75L49.8486 7.5H7.5C6.50579 7.50114 5.55262 7.8966 4.84961 8.59961C4.1466 9.30262 3.75114 10.2558 3.75 11.25V41.25H7.5V11.25H46.0986L3.75 53.5986L6.40144 56.25L10.1514 52.5H52.5C53.4942 52.4989 54.4474 52.1034 55.1504 51.4004C55.8534 50.6974 56.2489 49.7442 56.25 48.75V18.75H52.5V48.75Z' />
    </g>
    <defs>
      <clipPath id='clip0_13168_143374'>
        <rect width='60' height='60' />
      </clipPath>
    </defs>
  </svg>
);

export const CheckmarkIcon = (
  <svg
    clipRule='evenodd'
    fillRule='evenodd'
    strokeLinejoin='round'
    strokeMiterlimit='2'
    viewBox='0 0 24 24'
  >
    <path
      d='m11.998 2.005c5.517 0 9.997 4.48 9.997 9.997 0 5.518-4.48 9.998-9.997 9.998-5.518 0-9.998-4.48-9.998-9.998 0-5.517 4.48-9.997 9.998-9.997zm0 1.5c-4.69 0-8.498 3.807-8.498 8.497s3.808 8.498 8.498 8.498 8.497-3.808 8.497-8.498-3.807-8.497-8.497-8.497zm-5.049 8.886 3.851 3.43c.142.128.321.19.499.19.202 0 .405-.081.552-.242l5.953-6.509c.131-.143.196-.323.196-.502 0-.41-.331-.747-.748-.747-.204 0-.405.082-.554.243l-5.453 5.962-3.298-2.938c-.144-.127-.321-.19-.499-.19-.415 0-.748.335-.748.746 0 .205.084.409.249.557z'
      fillRule='nonzero'
    />
  </svg>
);

export const ReferenceImageIcon = (
  <svg
    width='22'
    height='18'
    viewBox='0 0 22 18'
    fillRule='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path d='M20.75 0.75H1.25C0.835156 0.75 0.5 1.08516 0.5 1.5V16.5C0.5 16.9148 0.835156 17.25 1.25 17.25H20.75C21.1648 17.25 21.5 16.9148 21.5 16.5V1.5C21.5 1.08516 21.1648 0.75 20.75 0.75ZM19.8125 15.5625H2.1875V14.6273L5.43359 10.7766L8.95156 14.9484L14.4242 8.46094L19.8125 14.85V15.5625ZM19.8125 12.5203L14.5672 6.3C14.4922 6.21094 14.3562 6.21094 14.2812 6.3L8.95156 12.6188L5.57656 8.61797C5.50156 8.52891 5.36562 8.52891 5.29062 8.61797L2.1875 12.2977V2.4375H19.8125V12.5203ZM6.125 7.6875C6.39585 7.6875 6.66405 7.63415 6.91428 7.5305C7.16452 7.42685 7.39189 7.27493 7.58341 7.08341C7.77493 6.89189 7.92685 6.66452 8.0305 6.41428C8.13415 6.16405 8.1875 5.89585 8.1875 5.625C8.1875 5.35415 8.13415 5.08595 8.0305 4.83572C7.92685 4.58548 7.77493 4.35811 7.58341 4.16659C7.39189 3.97507 7.16452 3.82315 6.91428 3.7195C6.66405 3.61585 6.39585 3.5625 6.125 3.5625C5.57799 3.5625 5.05339 3.7798 4.66659 4.16659C4.2798 4.55339 4.0625 5.07799 4.0625 5.625C4.0625 6.17201 4.2798 6.69661 4.66659 7.08341C5.05339 7.4702 5.57799 7.6875 6.125 7.6875ZM6.125 4.96875C6.48828 4.96875 6.78125 5.26172 6.78125 5.625C6.78125 5.98828 6.48828 6.28125 6.125 6.28125C5.76172 6.28125 5.46875 5.98828 5.46875 5.625C5.46875 5.26172 5.76172 4.96875 6.125 4.96875Z' />
  </svg>
);

export const PlaneCalibrationIcon = (
  <svg
    width='18'
    height='18'
    viewBox='0 0 18 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path d='M17.4375 6.23438C17.5406 6.23438 17.625 6.15 17.625 6.04688V4.64062C17.625 4.5375 17.5406 4.45312 17.4375 4.45312H13.5938V0.5625C13.5938 0.459375 13.5094 0.375 13.4062 0.375H11.9062C11.8031 0.375 11.7188 0.459375 11.7188 0.5625V4.45312H6.375V0.5625C6.375 0.459375 6.29063 0.375 6.1875 0.375H4.6875C4.58437 0.375 4.5 0.459375 4.5 0.5625V4.45312H0.5625C0.459375 4.45312 0.375 4.5375 0.375 4.64062V6.04688C0.375 6.15 0.459375 6.23438 0.5625 6.23438H4.5V11.7656H0.5625C0.459375 11.7656 0.375 11.85 0.375 11.9531V13.3594C0.375 13.4625 0.459375 13.5469 0.5625 13.5469H4.5V17.4375C4.5 17.5406 4.58437 17.625 4.6875 17.625H6.1875C6.29063 17.625 6.375 17.5406 6.375 17.4375V13.5469H11.7188V17.4375C11.7188 17.5406 11.8031 17.625 11.9062 17.625H13.4062C13.5094 17.625 13.5938 17.5406 13.5938 17.4375V13.5469H17.4375C17.5406 13.5469 17.625 13.4625 17.625 13.3594V11.9531C17.625 11.85 17.5406 11.7656 17.4375 11.7656H13.5938V6.23438H17.4375ZM11.7188 11.7656H6.375V6.23438H11.7188V11.7656Z' />
  </svg>
);

export const AoiIcon = (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path d='M8.00016 11.3333L8.66683 10V6.57201C9.81416 6.27401 10.6668 5.23868 10.6668 4.00001C10.6668 2.52934 9.47083 1.33334 8.00016 1.33334C6.5295 1.33334 5.3335 2.52934 5.3335 4.00001C5.3335 5.23868 6.18616 6.27401 7.3335 6.57201V10L8.00016 11.3333ZM6.66683 4.00001C6.66683 3.26468 7.26483 2.66668 8.00016 2.66668C8.7355 2.66668 9.3335 3.26468 9.3335 4.00001C9.3335 4.73534 8.7355 5.33334 8.00016 5.33334C7.26483 5.33334 6.66683 4.73534 6.66683 4.00001Z' />
    <path d='M10.8448 7.04199L10.4895 8.32732C12.2168 8.80465 13.3335 9.72265 13.3335 10.6667C13.3335 11.928 11.1435 13.3333 8.00016 13.3333C4.85683 13.3333 2.66683 11.928 2.66683 10.6667C2.66683 9.72265 3.7835 8.80465 5.5115 8.32665L5.15616 7.04132C2.79816 7.69332 1.3335 9.08199 1.3335 10.6667C1.3335 12.9093 4.26216 14.6667 8.00016 14.6667C11.7382 14.6667 14.6668 12.9093 14.6668 10.6667C14.6668 9.08199 13.2022 7.69332 10.8448 7.04199V7.04199Z' />
  </svg>
);

export const DetectionIcon = (
  <svg
    width='16'
    height='16'
    viewBox='0 0 32 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M 14 4 C 12.895431 4 12 4.8954305 12 6 C 12 6.0211168 11.99935 6.0415409 12 6.0625 L 6.9375 9.25 C 6.6569454 9.100054 6.3403411 9 6 9 C 4.8954305 9 4 9.8954305 4 11 C 4 11.739604 4.4031044 12.372707 5 12.71875 L 5 24.28125 C 4.4031044 24.627293 4 25.260396 4 26 C 4 27.104569 4.8954305 28 6 28 C 6.7396043 28 7.3727074 27.596896 7.71875 27 L 20.28125 27 C 20.627293 27.596896 21.260396 28 22 28 C 23.104569 28 24 27.104569 24 26 C 24 25.600737 23.889076 25.21845 23.6875 24.90625 L 26.125 20 C 27.170989 19.935109 28 19.062335 28 18 C 28 17.022944 27.301254 16.206033 26.375 16.03125 L 24.46875 10.3125 C 24.784455 9.9588855 25 9.5113393 25 9 C 25 7.8954305 24.104569 7 23 7 C 22.488661 7 22.041114 7.2155452 21.6875 7.53125 L 15.96875 5.59375 C 15.782257 4.6823837 14.966498 4 14 4 z M 15.3125 7.5 L 21.03125 9.375 C 21.183222 10.180371 21.819629 10.816778 22.625 10.96875 L 24.53125 16.65625 C 24.206054 17.011924 24 17.480091 24 18 C 24 18.414213 24.128101 18.805798 24.34375 19.125 L 21.875 24 C 21.18836 24.042598 20.607533 24.437189 20.28125 25 L 7.71875 25 C 7.5434657 24.697649 7.3023514 24.456534 7 24.28125 L 7 12.71875 C 7.5968956 12.372707 8 11.739604 8 11 C 8 10.978883 8.0006497 10.958459 8 10.9375 L 13.0625 7.75 C 13.343055 7.899946 13.659659 8 14 8 C 14.503722 8 14.960854 7.8073527 15.3125 7.5 z'
      overflow='visible'
      fontFamily='Sans'
    />
  </svg>
);
export const RegisteredIcon = (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M1.99998 0.566666C1.61984 0.566666 1.25527 0.717677 0.986464 0.986479C0.717662 1.25528 0.56665 1.61986 0.56665 2V6.33333C0.56665 6.71348 0.717662 7.07805 0.986464 7.34685C1.25527 7.61565 1.61984 7.76666 1.99998 7.76666H3.56665V10.3747C3.56665 10.9205 3.78345 11.4441 4.16937 11.8301C4.55529 12.2162 5.07878 12.4331 5.62465 12.4333H8.23332V14C8.23332 14.3801 8.38433 14.7447 8.65313 15.0135C8.92193 15.2823 9.28651 15.4333 9.66665 15.4333H14C14.3801 15.4333 14.7447 15.2823 15.0135 15.0135C15.2823 14.7447 15.4333 14.3801 15.4333 14V9.66667C15.4333 9.28652 15.2823 8.92195 15.0135 8.65315C14.7447 8.38434 14.3801 8.23333 14 8.23333H9.66665C9.28651 8.23333 8.92193 8.38434 8.65313 8.65315C8.38433 8.92195 8.23332 9.28652 8.23332 9.66667V11.2333H5.62532C5.15121 11.2333 4.76665 10.8481 4.76665 10.3753V7.76666H6.33332C6.71346 7.76666 7.07803 7.61565 7.34684 7.34685C7.61564 7.07805 7.76665 6.71348 7.76665 6.33333V2C7.76665 1.61986 7.61564 1.25528 7.34684 0.986479C7.07803 0.717677 6.71346 0.566666 6.33332 0.566666H1.99998ZM1.83499 1.83501C1.87875 1.79125 1.9381 1.76667 1.99998 1.76667H6.33332C6.3952 1.76667 6.45455 1.79125 6.49831 1.83501C6.54207 1.87877 6.56665 1.93811 6.56665 2V6.33333C6.56665 6.39522 6.54207 6.45457 6.49831 6.49832C6.45455 6.54208 6.3952 6.56667 6.33332 6.56667H1.99998C1.9381 6.56667 1.87875 6.54208 1.83499 6.49832C1.79123 6.45457 1.76665 6.39522 1.76665 6.33333V2C1.76665 1.93812 1.79123 1.87877 1.83499 1.83501ZM9.50166 9.50167C9.54542 9.45792 9.60477 9.43333 9.66665 9.43333H14C14.0619 9.43333 14.1212 9.45792 14.165 9.50167C14.2087 9.54543 14.2333 9.60478 14.2333 9.66667V14C14.2333 14.0619 14.2087 14.1212 14.165 14.165C14.1212 14.2087 14.0619 14.2333 14 14.2333H9.66665C9.60477 14.2333 9.54542 14.2087 9.50166 14.165C9.4579 14.1212 9.43332 14.0619 9.43332 14V9.66667C9.43332 9.60478 9.4579 9.54543 9.50166 9.50167Z'
    />
  </svg>
);

export const SouthArrowIcon = (
  <svg width='24' height='24' viewBox='0 0 24 24'>
    <path d='M22 12c0 5.514-4.486 10-10 10s-10-4.486-10-10 4.486-10 10-10 10 4.486 10 10zm-22 0c0 6.627 5.373 12 12 12s12-5.373 12-12-5.373-12-12-12-12 5.373-12 12zm12-3l-4-2 4 11 4-11-4 2z' />
  </svg>
);

export const StoreFrontIcon = (
  <svg
    width='22'
    height='18'
    viewBox='0 0 22 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M20.0253 8.05V16C20.0253 16.55 19.8297 17.021 19.4383 17.413C19.0463 17.8043 18.5753 18 18.0253 18H4.02533C3.47533 18 3.00467 17.8043 2.61333 17.413C2.22133 17.021 2.02533 16.55 2.02533 16V8.05C1.642 7.7 1.34633 7.25 1.13833 6.7C0.929668 6.15 0.925335 5.55 1.12533 4.9L2.17533 1.5C2.30867 1.06667 2.54633 0.708333 2.88833 0.425C3.22967 0.141667 3.62533 0 4.07533 0H17.9753C18.4253 0 18.817 0.137333 19.1503 0.412C19.4837 0.687333 19.7253 1.05 19.8753 1.5L20.9253 4.9C21.1253 5.55 21.1213 6.14167 20.9133 6.675C20.7047 7.20833 20.4087 7.66667 20.0253 8.05ZM13.2253 7C13.6753 7 14.017 6.84567 14.2503 6.537C14.4837 6.229 14.5753 5.88333 14.5253 5.5L13.9753 2H12.0253V5.7C12.0253 6.05 12.142 6.35433 12.3753 6.613C12.6087 6.871 12.892 7 13.2253 7ZM8.72534 7C9.10867 7 9.42134 6.871 9.66333 6.613C9.90467 6.35433 10.0253 6.05 10.0253 5.7V2H8.07533L7.52533 5.5C7.45867 5.9 7.54633 6.25 7.78833 6.55C8.02967 6.85 8.342 7 8.72534 7ZM4.27533 7C4.57533 7 4.83767 6.89167 5.06233 6.675C5.28767 6.45833 5.42533 6.18333 5.47533 5.85L6.02533 2H4.07533L3.07533 5.35C2.97533 5.68333 3.02933 6.04167 3.23733 6.425C3.446 6.80833 3.792 7 4.27533 7ZM17.7753 7C18.2587 7 18.6087 6.80833 18.8253 6.425C19.042 6.04167 19.092 5.68333 18.9753 5.35L17.9253 2H16.0253L16.5753 5.85C16.6253 6.18333 16.763 6.45833 16.9883 6.675C17.213 6.89167 17.4753 7 17.7753 7ZM4.02533 16H18.0253V8.95C17.942 8.98333 17.888 9 17.8633 9H17.7753C17.3253 9 16.9293 8.925 16.5873 8.775C16.246 8.625 15.9087 8.38333 15.5753 8.05C15.2753 8.35 14.9337 8.58333 14.5503 8.75C14.167 8.91667 13.7587 9 13.3253 9C12.8753 9 12.4543 8.91667 12.0623 8.75C11.671 8.58333 11.3253 8.35 11.0253 8.05C10.742 8.35 10.413 8.58333 10.0383 8.75C9.663 8.91667 9.25867 9 8.82533 9C8.342 9 7.90434 8.91667 7.51233 8.75C7.121 8.58333 6.77533 8.35 6.47533 8.05C6.12533 8.4 5.77967 8.64567 5.43833 8.787C5.09633 8.929 4.70867 9 4.27533 9H4.16333C4.12133 9 4.07533 8.98333 4.02533 8.95V16Z'
      fill='#1B2733'
    />
  </svg>
);

export const VideoCamIcon = (
  <svg
    width='20'
    height='16'
    viewBox='0 0 20 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M2 16C1.45 16 0.979333 15.8043 0.588 15.413C0.196 15.021 0 14.55 0 14V2C0 1.45 0.196 0.979333 0.588 0.588C0.979333 0.196 1.45 0 2 0H14C14.55 0 15.021 0.196 15.413 0.588C15.8043 0.979333 16 1.45 16 2V6.5L20 2.5V13.5L16 9.5V14C16 14.55 15.8043 15.021 15.413 15.413C15.021 15.8043 14.55 16 14 16H2ZM2 14H14V2H2V14Z'
      fill='#1B2733'
    />
  </svg>
);

export const ArrowBackIcon = (
  <svg
    width='20'
    height='12'
    viewBox='0 0 20 12'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M19.3 1.88906L9.65 11.5391L-4.21815e-07 1.88906L1.425 0.464063L9.65 8.71406L17.875 0.464062L19.3 1.88906Z'
      fill='#1C1B1F'
    />
  </svg>
);
export const CloseIcon = (
  <svg
    width='18'
    height='18'
    viewBox='0 0 18 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M15.2939 16.7082L16.001 17.4153L17.4153 16.001L16.7082 15.2939L10.4153 9.00105L16.7086 2.70771L17.4157 2.0006L16.0015 0.586389L15.2944 1.2935L9.00105 7.58683L2.70726 1.29304L2.00015 0.585938L0.585938 2.00015L1.29304 2.70726L7.58683 9.00105L1.29349 15.2944L0.586383 16.0015L2.0006 17.4157L2.7077 16.7086L9.00105 10.4153L15.2939 16.7082Z'
      fill='#1B2733'
    />
  </svg>
);

export const UploadIcon = (
  <svg
    width='18'
    height='18'
    viewBox='0 0 18 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M6.375 4.4469H8.10703V12.3758C8.10703 12.4789 8.19141 12.5633 8.29453 12.5633H9.70078C9.80391 12.5633 9.88828 12.4789 9.88828 12.3758V4.4469H11.625C11.782 4.4469 11.8687 4.26643 11.7727 4.14455L9.14766 0.821117C9.13012 0.798707 9.10771 0.780583 9.08212 0.768118C9.05654 0.755654 9.02846 0.749176 9 0.749176C8.97154 0.749176 8.94346 0.755654 8.91788 0.768118C8.89229 0.780583 8.86988 0.798707 8.85234 0.821117L6.22734 4.14221C6.13125 4.26643 6.21797 4.4469 6.375 4.4469ZM17.5781 11.6727H16.1719C16.0688 11.6727 15.9844 11.7571 15.9844 11.8602V15.4696H2.01562V11.8602C2.01562 11.7571 1.93125 11.6727 1.82812 11.6727H0.421875C0.31875 11.6727 0.234375 11.7571 0.234375 11.8602V16.5008C0.234375 16.9156 0.569531 17.2508 0.984375 17.2508H17.0156C17.4305 17.2508 17.7656 16.9156 17.7656 16.5008V11.8602C17.7656 11.7571 17.6812 11.6727 17.5781 11.6727Z'
      fill='#2F3B48'
    />
  </svg>
);

export const PhotoCamera = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
  >
    {' '}
    <path d='M15 12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.172a3 3 0 0 0 2.12-.879l.83-.828A1 1 0 0 1 6.827 3h2.344a1 1 0 0 1 .707.293l.828.828A3 3 0 0 0 12.828 5H14a1 1 0 0 1 1 1v6zM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2z' />{' '}
    <path d='M8 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zm0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7zM3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z' />{' '}
  </svg>
);

export const AddIcon = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
  >
    <path d='M12 2C6.475 2 2 6.475 2 12s4.475 10 10 10 10-4.475 10-10S17.525 2 12 2zm5 11h-4v4h-2v-4H7v-2h4V7h2v4h4v2z' />
  </svg>
);

export const CancelIcon = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='48'
    height='48'
    viewBox='0 0 48 48'
    fill='none'
  >
    <circle cx='24' cy='24' r='20' stroke='currentColor' strokeWidth='3' />
    <path
      d='M14 14l20 20M14 34l20-20'
      stroke='red'
      strokeWidth='3'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
