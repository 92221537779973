// APIs
import { QUERY_KEYS } from './QUERY_KEYS';
import apiFetch from '../fetcher';
import { API_URL } from '../apiURLs';
import { useProxyQuery } from './useProxyQuery';

export const useFetchAllRoles = (search = '', options) => {
  return useProxyQuery(
    [QUERY_KEYS.ROLES],
    () => apiFetch(API_URL.getRoles(search)),
    options
  );
};

export const useFetchPagesActions = options =>
  useProxyQuery(
    [QUERY_KEYS.PAGES_AND_ACTIONS],
    () => apiFetch(API_URL.getPagesActions()),
    options
  );

export const useFetchRoleDetails = (id, options) => {
  return useProxyQuery(
    [QUERY_KEYS.ROLES, id],
    () => apiFetch(API_URL.getRoleDetails(id)),
    options
  );
};

export const useFetchUserTypesStatusesOrganizations = options => {
  return useProxyQuery(
    [QUERY_KEYS.TYPES_STATUSES_ORGS],
    () => apiFetch(API_URL.getUserTypesStatusesOrganizations()),
    options
  );
};
