export const COLORS = {
  MainBlue: '#1268FB',
  SecondaryBlue1: '#1D7AFD',
  FocusBlue: '#1268fb51',
  SecondaryBlue2: '#E5F0FF',
  SecondaryBlue3: '#F7F9FA',
  SecondaryBlue4: '#E4F0FE',
  Black: '#000000',
  TextBlack: '#1B2733',
  BlackAlpha10: '#1b273326',
  Gray1: '#2F3B48',
  Gray2: '#788392',
  DisabledGray: '#C1C3C8',
  GullGray: '#9ba5b3',
  HoverGray: '#78839251',
  White: '#FFFFFF',
  SeparatorGray: '#ECF0F1',
  NeutralGray: '#DFE1E6',
  BackgroundGray: '#FAFAFA',
  Yellow: '#FFC700',
  SecondaryYellow: '#FFFBEC',
  Orange: '#FF8400',
  Red: '#FF483D',
  ErrorRed: '#ff3b30',
  ErrorShadow: '#ff3b3051',
  SecondaryRed: '#FFF4F3',
  Green: '#62DE78',
  Stroke: '#9BA5B2',
  SecondaryGreen: '#F0FFF3',

  NoImagePlaceholder: '#4a4c4e',
};
