import styled from 'styled-components';
import { COLORS } from '../../colors';

const SIZE_MAP = {
  small: '14px',
  normal: '16px',
};

export const CheckboxInput = styled.input.attrs({ type: 'checkbox' })`
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
  top: 0;
  left: 0;
  opacity: 0;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  position: relative;
  cursor: pointer;
  align-items: center;
  border-radius: 3px;
  transition: 0.3ms;
  justify-content: center;
  width: ${({ size }) => SIZE_MAP[size] ?? SIZE_MAP.small};
  height: ${({ size }) => SIZE_MAP[size] ?? SIZE_MAP.small};
  border: 1px solid
    ${({ checked }) => (checked ? COLORS.MainBlue : COLORS.NeutralGray)};
  background-color: ${({ checked, disabled }) => {
    if (disabled) return COLORS.DisabledGray;
    return checked ? COLORS.MainBlue : COLORS.SecondaryBlue3;
  }};
  &:focus {
    border: 1px solid ${COLORS.MainBlue};
  }
  &:hover {
    background-color: ${({ checked }) =>
      checked ? COLORS.SecondaryBlue1 : COLORS.SeparatorGray};
  }
`;
