import {
  ErrorIcon,
  NoticeFilledIcon,
  SuccessIcon,
  WarningIcon,
} from '../icons';

export const notificationPresetsMap = {
  info: {
    icon: NoticeFilledIcon,
    color: 'secondaryBlue',
    size: 'medium',
  },
  error: {
    icon: ErrorIcon,
    color: 'errorRed',
    size: 'medium',
  },
  warning: {
    icon: WarningIcon,
    color: 'yellow',
    size: 'normal',
  },
  success: {
    icon: SuccessIcon,
    color: 'successGreen',
    size: 'medium',
  },
};
