import { useFeatureFlagEnabled } from 'posthog-js/react';
import posthog from 'posthog-js';
import React, { useState, useEffect } from 'react';
import Toggle from 'react-toggle';
import { FlexContainer } from '../flexContainer';
import './styles.css';
import { variables } from '../../utils/variables';
import { FeatureFlagNames } from '../../constants/FeatureFlagNames';

const CustomToggle = ({ checked, onChange, disabled }) => {
  return (
    <Toggle
      id='odyplcziuh'
      onChange={onChange}
      checked={checked}
      disabled={disabled}
    />
  );
};

let process_started = false;

const VASAToggle = ({ toggleValue, setToggle, setServerType, props }) => {
  const isNextShopAssistEnabled = useFeatureFlagEnabled(
    FeatureFlagNames.NextShopAssist
  );
  const [myToggle, setMyToggle] = useState(0); // VA, SA
  const [vaBGColor, setVaBGColor] = useState();
  const [saBGColor, setSaBGColor] = useState();
  const [vaColor, setVaColor] = useState();
  const [saColor, setSaColor] = useState();
  const caption_0 = props?.[0]?.['caption'];
  const caption_1 = props?.[1]?.['caption'];
  // const active_0 = props?.[0]?.['active'];
  const active_1 = props?.[1]?.['active'];
  const toggleActiveBGColor = '#0066FF';
  const togglePassiveBGColor = '#F4F5F7';

  function findToggleIndex(jsonData, value) {
    for (let i = 0; i < jsonData.length; i++) {
      if (jsonData[i].value === value) {
        return i;
      }
    }
    return null;
  }
  useEffect(() => {
    if (myToggle === 0) {
      setVaBGColor(toggleActiveBGColor);
      setSaBGColor(togglePassiveBGColor);
      setVaColor('white');
      setSaColor('#222');
    } else if (myToggle === 1) {
      setVaBGColor(togglePassiveBGColor);
      setSaBGColor(toggleActiveBGColor);
      setSaColor('white');
      setVaColor('#222');
    }
  }, [myToggle]);

  useEffect(() => {}, []);

  useEffect(() => {
    if (myToggle !== findToggleIndex(props, toggleValue))
      setMyToggle(findToggleIndex(props, toggleValue));
  }, [toggleValue]);

  const toggleVASA = () => {
    if (!process_started) {
      process_started = true;
    }
    if (myToggle === 0 && toggleValue === props[0]['value']) {
      if (isNextShopAssistEnabled) {
        posthog.capture('toggleClicked', {
          current_url: window.location.href,
          toWhichPage: 'Next Central',
        });
        window.location.href = `${variables().redirectUri}/next`;
      } else {
        posthog.capture('toggleClicked', {
          current_url: window.location.href,
          toWhichPage: 'VA',
        });
        setMyToggle(1);
        setToggle(props[1]['value']);
      }
    } else if (myToggle === 1 && toggleValue === props[1]['value']) {
      setMyToggle(0);
      if (props?.[0]) setToggle(props[0]['value']); // SA -> VA
      if (props?.[0]) {
        setToggle(props[0]['value']); // SA -> VA
        setServerType('va1'); // default to va1 when looking at VA
      }
    }
  };

  return (
    <FlexContainer align='left' fluid style={{ paddingLeft: '40px' }}>
      <FlexContainer
        onClick={toggleVASA}
        style={{
          backgroundColor: '#F4F5F7',
          borderRadius: '3px',
          height: '24px',
          padding: '3px',
          gap: '2px',
          margin: '3px 15px 0 0',
          cursor: 'pointer',
        }}
      >
        {active_1 ? (
          <FlexContainer
            id='eduucyvwyf'
            justify='center'
            style={{
              color: vaColor,
              backgroundColor: vaBGColor,
              borderRadius: '2px',
              fontSize: '14px',
              lineHeight: '16px',
              paddingLeft: '5px',
              paddingRight: '5px',
            }}
          >
            {caption_0}
          </FlexContainer>
        ) : null}
        {active_1 ? (
          <FlexContainer
            id='rdvucyvwer'
            justify='center'
            style={{
              color: saColor,
              backgroundColor: saBGColor,
              borderRadius: '2px',
              height: '18px',
              fontSize: '15px',
              lineHeight: '16px',
              paddingLeft: '5px',
              paddingRight: '5px',
            }}
          >
            {caption_1}
          </FlexContainer>
        ) : null}
      </FlexContainer>
    </FlexContainer>
  );
};

export { VASAToggle, CustomToggle };
