import React from 'react';
import { Layer, Line, Stage } from 'react-konva';
import {
  checkIsPointNearStartPoint,
  convertPolygonsToRectangleView,
  denormalizePoint,
  normalizePoint,
  PIXEL_RADIUS,
} from '../utils';
import Rectangles from './Rectangles';
import { convertToRectangleObject } from '../utilsConverters';

const RectangleStage = ({
  stageWidth,
  stageHeight,
  polygons,
  setPolygons,
  isPolygonDrawing,
  setIsPolygonDrawing,
  polygonsLimit,
  setAreActionsDisabled,
  isUserActionPermitted,
  transform,
}) => {
  const createNewPolygon = point => {
    setIsPolygonDrawing(true);
    setAreActionsDisabled(true);
    const newPolygon = convertToRectangleObject();
    const [normalizedX, normalizedY] = normalizePoint(
      stageWidth,
      stageHeight,
      point
    );

    newPolygon.x = normalizedX;
    newPolygon.y = normalizedY;
    setPolygons([...polygons, newPolygon]);
  };

  const handleMouseDown = event => {
    if (polygons.length < polygonsLimit) {
      const { x, y } = event.target.getStage().getPointerPosition();
      createNewPolygon([x, y]);
    }
  };

  const handleMouseMove = event => {
    if (isPolygonDrawing) {
      const polygonsCopy = [...polygons];
      const lastPolygon = polygonsCopy[polygonsCopy.length - 1];
      const firstPointX = lastPolygon.x;
      const firstPointY = lastPolygon.y;
      const { x, y } = event.target.getStage().getPointerPosition();
      const [normalizedCurrentX, normalizedCurrentY] = normalizePoint(
        stageWidth,
        stageHeight,
        [x, y]
      );
      lastPolygon.isClosed = true;
      lastPolygon.x = firstPointX;
      lastPolygon.y = firstPointY;
      lastPolygon.width = normalizedCurrentX - firstPointX;
      lastPolygon.height = normalizedCurrentY - firstPointY;

      setPolygons(polygonsCopy);
    }
  };

  const handleMouseUp = event => {
    if (isPolygonDrawing) {
      setIsPolygonDrawing(false);
      setAreActionsDisabled(false);

      const polygonsCopy = [...polygons];
      const lastPolygon = polygonsCopy[polygonsCopy.length - 1];
      const firstPointX = lastPolygon.x;
      const firstPointY = lastPolygon.y;
      const { x, y } = event.target.getStage().getPointerPosition();
      const [normalizedCurrentX, normalizedCurrentY] = normalizePoint(
        stageWidth,
        stageHeight,
        [x, y]
      );
      // In case if a finish point is near the start point
      // then we draw the small rectangle
      const denormalizedFirstPoint = denormalizePoint(stageWidth, stageHeight, [
        firstPointX,
        firstPointY,
      ]);
      if (checkIsPointNearStartPoint(denormalizedFirstPoint, [x, y])) {
        const newFinishX = normalizedCurrentX + PIXEL_RADIUS / stageWidth;
        const newFinishY = normalizedCurrentY + PIXEL_RADIUS / stageWidth;
        lastPolygon.finishX = newFinishX;
        lastPolygon.finishY = newFinishY;
        lastPolygon.width = newFinishX - firstPointX;
        lastPolygon.height = newFinishY - firstPointY;
      } else {
        lastPolygon.finishX = normalizedCurrentX;
        lastPolygon.finishY = normalizedCurrentY;
        lastPolygon.width = normalizedCurrentX - firstPointX;
        lastPolygon.height = normalizedCurrentY - firstPointY;
      }
      lastPolygon.isClosed = true;
      lastPolygon.x = firstPointX;
      lastPolygon.y = firstPointY;

      setPolygons(polygonsCopy);
    }
  };

  const handleDragMove = (id, event) => {
    const newPolygons = polygons.map(polygon => {
      const targetX = event.target.x();
      const targetY = event.target.y();
      const [normalizedX, normalizedY] = normalizePoint(
        stageWidth,
        stageHeight,
        [targetX, targetY]
      );

      if (normalizedX < 0) {
        const newX = 0;
        event.target.x(newX);
        polygon.x = newX;
        polygon.finishX = polygon.width;
      } else if (normalizedX + polygon.width >= 1) {
        event.target.x((1 - polygon.width) * stageWidth);
        polygon.x = 1 - polygon.width;
        polygon.finishX = 1;
      } else {
        polygon.x = normalizedX;
        polygon.finishX = normalizedX + polygon.width;
      }

      if (normalizedY < 0) {
        const newY = 0;
        event.target.y(newY);
        polygon.y = newY;
        polygon.finishY = polygon.height;
      } else if (normalizedY + polygon.height >= 1) {
        event.target.y((1 - polygon.height) * stageHeight);
        polygon.y = 1 - polygon.height;
        polygon.finishY = 1;
      } else {
        polygon.y = normalizedY;
        polygon.finishY = normalizedY + polygon.height;
      }

      return polygon;
    });
    setPolygons(newPolygons);
  };

  const getRectangleStage = () => {
    if (isUserActionPermitted) {
      return {
        onMouseDown: handleMouseDown,
        onMouseMove: handleMouseMove,
        onMouseUp: handleMouseUp,
      };
    }
  };

  return (
    <Stage
      id='vpdbztwbfi'
      width={stageWidth}
      height={stageHeight}
      {...getRectangleStage()}
    >
      <Layer id='itpcjgojey'>
        <Rectangles
          id='onusnpulxq'
          polygons={convertPolygonsToRectangleView(
            stageWidth,
            stageHeight,
            polygons
          )}
          stageWidth={stageWidth}
          stageHeight={stageHeight}
          onDragMove={handleDragMove}
          setPolygons={setPolygons}
          isPolygonDrawing={isPolygonDrawing}
          setIsPolygonDrawing={setIsPolygonDrawing}
          setAreActionsDisabled={setAreActionsDisabled}
          transform={transform}
          isUserActionPermitted={isUserActionPermitted}
        />
        <Line id='wwjfcwzaep' points={[1, 1]} opacity={0} />
      </Layer>
    </Stage>
  );
};

export default RectangleStage;
