import React from 'react';
import { FlexContainer } from '../flexContainer';
import StateInfo from '../stateInfo';

const NoAccessSection = () => {
  return (
    <FlexContainer id='bknbquhser' justify='center' align='center' fluid>
      <StateInfo
        id='fblbpbthhi'
        icon='LockIcon'
        title='No access'
        subtitle='Contact your Administrator for access privileges.'
      />
    </FlexContainer>
  );
};

export default NoAccessSection;
