// Libs
import * as yup from 'yup';

export const COOKIE_COMPANY_ID = 'companyId';

export const COOKIE_MASTER_FILTER = 'masterFilter';

export const RAI_CENTRAL_CACHE = '@@raicentral::cache@@';

const LINK_FIELDS = ['kafka_url', 's3_bucket'];
const SECURE_LINK =
  /^(https:\/\/)[a-z\d]+([-.][a-z\d]+)*\.[a-z]{2,5}(:\d{1,5})?(\/.*)?$/;

const yupSchemas = {
  STRING_DIALOG_VALUE: yup
    .string('*Should be a string')
    .min(3, '*Value is too short')
    .required('*Required field')
    .nullable(),
  DELETE_PARAM_DIALOG_VALUE: yup
    .string('*Should be a string')
    .required('*Required field')
    .nullable(),
  NUMBER_DIALOG: yup
    .number()
    .typeError('*Please input a number')
    .required('*Required field'),
  STRING: yup
    .string('Should be a string')
    .min(3, 'Value is too short')
    .nullable(),
  STRING_REQ: yup
    .string('Should be a string')
    .min(2, 'Value is too short')
    .required('Required field')
    .nullable(),
  STRING_1_CHAR_REQ: yup
    .string('*Should be a string')
    .min(1)
    .required('*Required field')
    .nullable(),
  STRING_NOT_EMPTY: yup
    .string('*Should be a string')
    .min(3, '*Value is too short')
    .required('*Cannot be empty')
    .nullable(),
  PPC_DROPDOWN: yup.string('*Should be a string').required('*Required field'),
  NUMBER_NULLABLE: yup.number().typeError('Please input a number').nullable(),
  NUMBER: yup.number().typeError('Please input a number'),
  NUMBER_POSITIVE: yup
    .number()
    .typeError('Please input a number')
    .required('*Required field')
    .nullable()
    .moreThan(0, 'Must be greater than 0'),
  NUMBER_REQ: yup
    .number()
    .typeError('Please input a number')
    .required('*Required field')
    .nullable(),
  EMAIL: yup.string().email('This is not a valid email').nullable(),
  EMAIL_REQ: yup
    .string()
    .email('This is not a valid email')
    .required('Email is required')
    .nullable(),
  URL: yup.string().url('This is not a valid URL').nullable(),
  URL_REQ: yup
    .string()
    .url('Invalid value')
    .required('Required field')
    .nullable(),
  URL_NOT_EMPTY: yup
    .string()
    .url('*Invalid value')
    .required('Cannot be empty')
    .nullable(),
  URL_SECURE: yup.string().matches(SECURE_LINK, 'Invalid value').nullable(),
  BOOL: yup.bool(),
  BOOL_REQ: yup.bool().required('*Required field'),
  DROP: yup.mixed(),
  ONE_OF: values => yup.mixed().oneOf(values),
};

const US_STATES = {
  AK: 'Alaska',
  AL: 'Alabama',
  AR: 'Arkansas',
  AZ: 'Arizona',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DC: 'District of Columbia',
  DE: 'Delaware',
  FL: 'Florida',
  GA: 'Georgia',
  HI: 'Hawaii',
  IA: 'Iowa',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  MA: 'Massachusetts',
  MD: 'Maryland',
  ME: 'Maine',
  MI: 'Michigan',
  MN: 'Minnesota',
  MO: 'Missouri',
  MS: 'Mississippi',
  MT: 'Montana',
  NC: 'North Carolina',
  ND: 'North Dakota',
  NE: 'Nebraska',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NV: 'Nevada',
  NY: 'New York',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VA: 'Virginia',
  VT: 'Vermont',
  WA: 'Washington',
  WI: 'Wisconsin',
  WV: 'West Virginia',
  WY: 'Wyoming',
};

const inputTypes = {
  TEXTINPUT: 'text',
  NUMINPUT: 'number',
  PASSWORD: 'password',
  SELECT: 'select',
  CHECKBOX: 'checkbox',
  TEAXTAREA: 'textarea',
};

const specialValues = {
  NULL: null,
  UNDEFINED: undefined,
};

const sizesMedia = {
  mobileS: 320,
  mobileM: 375,
  mobileL: 429,
  tablet: 768,
  laptop: 1024,
  laptopS: 1200,
  laptopL: 1440,
  desktop: 2560,
};

const polygonsCategories = {
  PLANE_AND_NORMAL: 'plane_and_normal',
  DETECTION_POLYGON: 'detection_polygon',
  AOI_SELECTION: 'aoi_selection',
  FLOOR_PROJECTION: 'floor_projection', // for checkstandXXX cameras
  OBJECT_MARKERS: 'object_markers',
  CAMERA_OBJECTIVES: 'camera_objectives',
};

const STATUS_CODES = {
  OK: 200,
  NO_CONTENT: 204,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  NOT_ACCEPTABLE: 406,
  INTERNAL_SERVER_ERROR: 500,
  BAD_GATEWAY: 502,
  SERVICE_UNAVAILABLE: 503,
  GATEWAY_TIMEOUT: 504,
};

const DEFAULT_OBJECTIVES_SELECTS = {
  search_by: '',
  id: '',
  template: '',
};

const YES_NO_SELECTS = [
  { label: 'No', value: 0 },
  { label: 'Yes', value: 1 },
];

const USER_TYPE = {
  Admin: 1,
  Client: 2,
  Support: 3,
  Integrator: 4,
};

export {
  inputTypes,
  specialValues,
  sizesMedia,
  polygonsCategories,
  STATUS_CODES,
  yupSchemas,
  LINK_FIELDS,
  US_STATES,
  USER_TYPE,
  DEFAULT_OBJECTIVES_SELECTS,
  YES_NO_SELECTS,
};
