// Libs
import React from 'react';
import { useHistory } from 'react-router-dom';
// eslint-disable-next-line import/no-cycle
import { useReload } from '../reloadModal';
// import { Icon } from '../iconComponent/Icon';
import logo from '../../assets/images/logos/logo.png';

const Logo = () => {
  const history = useHistory();
  const { handleUnsavedClose } = useReload();

  const handleClick = () => {
    handleUnsavedClose(() => {
      history.push('/');
    });
  };

  // return (
  //   <Icon
  //     id='zrobrrjcvo'
  //     size='logo'
  //     iconName='LogoIcon'
  //     onClick={handleClick}
  //   />
  // );

  return (
    <div onClick={handleClick} role='button' tabIndex={0} aria-hidden='true'>
      <img src={logo} alt='RadiusAI Logo' height={50} />
    </div>
  );
};

export default Logo;
