import React from 'react';
import { Circle } from 'react-konva';
import { normalizePoint, PIXEL_RADIUS } from '../utils';

const POINTS_INDEX = {
  TOP_LEFT: 0,
  TOP_RIGHT: 1,
  BOTTOM_RIGHT: 2,
  BOTTOM_LEFT: 3,
};

const Transform = ({
  stroke,
  setPolygons,
  draggable,
  polygon,
  stageHeight,
  stageWidth,
}) => {
  const { x, y, width, height } = polygon;
  const getPoints = () => {
    const points = [[x, y]];
    points.push([x + width, y]);
    points.push([x + width, y + height]);
    points.push([x, y + height]);
    return points;
  };
  const points = getPoints();

  const onDrag = event => {
    setPolygons(prevState => {
      const rectangle = prevState[0];
      const { width, height } = event.target.getStage().getAttrs();
      const { x, y, index: pointIndex } = event.target.getAttrs();
      let [normalizeX, normalizeY] = normalizePoint(width, height, [x, y]);
      const normalizedPixelRadius = PIXEL_RADIUS / width;

      // move points only in canvas
      if (normalizeX >= 1) {
        event.target.x(width);
        normalizeX = 1;
      }
      if (normalizeX <= 0) {
        event.target.x(0);
        normalizeX = 0;
      }
      if (normalizeY >= 1) {
        event.target.y(height);
        normalizeY = 1;
      }
      if (normalizeY <= 0) {
        event.target.y(0);
        normalizeY = 0;
      }

      const [finishX, finishY] = normalizePoint(
        stageWidth,
        stageHeight,
        points[2]
      );

      const [normalizedTopRightX, normalizedTopRightY] = normalizePoint(
        width,
        height,
        points[POINTS_INDEX.TOP_RIGHT]
      );

      const [normalizedTopLeftX, normalizedTopLeftY] = normalizePoint(
        width,
        height,
        points[POINTS_INDEX.TOP_LEFT]
      );

      const [normalizedBottomLeftX, normalizedBottomLeftY] = normalizePoint(
        width,
        height,
        points[POINTS_INDEX.BOTTOM_LEFT]
      );

      const [normalizedBottomRightX, normalizedBottomRightY] = normalizePoint(
        width,
        height,
        points[POINTS_INDEX.BOTTOM_RIGHT]
      );

      switch (pointIndex) {
        case POINTS_INDEX.TOP_LEFT: {
          if (
            normalizeX + normalizedPixelRadius < normalizedTopRightX &&
            normalizeY + normalizedPixelRadius < normalizedBottomLeftY
          ) {
            rectangle.x = normalizeX;
            rectangle.y = normalizeY;
            rectangle.width = finishX - rectangle.x;
            rectangle.height = finishY - rectangle.y;
          } else {
            event.target.x(points[POINTS_INDEX.TOP_LEFT][0]);
            event.target.y(points[POINTS_INDEX.TOP_LEFT][1]);
          }
          break;
        }
        case POINTS_INDEX.TOP_RIGHT: {
          if (
            normalizeX - normalizedPixelRadius > normalizedTopLeftX &&
            normalizeY + normalizedPixelRadius < normalizedBottomRightY
          ) {
            rectangle.y = normalizeY;
            rectangle.width = normalizeX - rectangle.x;
            rectangle.height = finishY - rectangle.y;
          } else {
            event.target.x(points[POINTS_INDEX.TOP_RIGHT][0]);
            event.target.y(points[POINTS_INDEX.TOP_RIGHT][1]);
          }
          break;
        }
        case POINTS_INDEX.BOTTOM_LEFT: {
          if (
            normalizeX + normalizedPixelRadius < normalizedBottomRightX &&
            normalizeY - normalizedPixelRadius > normalizedTopLeftY
          ) {
            rectangle.x = normalizeX;
            rectangle.width = finishX - rectangle.x;
            rectangle.height = normalizeY - rectangle.y;
          } else {
            event.target.x(points[POINTS_INDEX.BOTTOM_LEFT][0]);
            event.target.y(points[POINTS_INDEX.BOTTOM_LEFT][1]);
          }
          break;
        }
        // BOTTOM_RIGHT
        default: {
          if (
            normalizeX - normalizedPixelRadius > normalizedBottomLeftX &&
            normalizeY - normalizedPixelRadius > normalizedTopRightY
          ) {
            rectangle.width = normalizeX - rectangle.x;
            rectangle.height = normalizeY - rectangle.y;
          } else {
            event.target.x(points[POINTS_INDEX.BOTTOM_RIGHT][0]);
            event.target.y(points[POINTS_INDEX.BOTTOM_RIGHT][1]);
          }
        }
      }

      rectangle.finishX = finishX;
      rectangle.finishY = finishY;

      return [rectangle];
    });
  };

  return (
    <>
      {points.map((point, index) => {
        return (
          <Circle
            id='ijauffiwea'
            key={`point${index}`}
            onDragMove={onDrag}
            index={index}
            draggable={draggable}
            radius={8}
            fill='white'
            stroke={stroke}
            x={point[0]}
            y={point[1]}
            strokeWidth={5}
          />
        );
      })}
    </>
  );
};

export default Transform;
