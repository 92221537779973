import styled from 'styled-components';
import { COLORS } from '../colors';
import { BodyTextStroke, Heading2 } from '../fonts';

export const IconWrapper = styled.div`
  background-color: ${COLORS.White};
  width: 125px;
  height: 125px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StateInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 212px;
  padding: 16px;
`;

export const StateInfoHeading = styled(Heading2)`
  margin-bottom: 24px;
  text-align: center;
`;

export const StateInfoLabel = styled(BodyTextStroke)`
  text-align: center;
  margin-bottom: 24px;
`;
