// Libs
import { useQuery, useQueryClient } from 'react-query';
// APIs
import { QUERY_KEYS } from './QUERY_KEYS';
import { STATUS_CODES } from '../../constants';

export const useProxyQuery = (queries, queryFn, options) => {
  const queryClient = useQueryClient();

  const data = useQuery(queries, queryFn, options);

  const dataError = data?.error;
  if (dataError?.response?.data?.status === STATUS_CODES.FORBIDDEN) {
    queryClient.invalidateQueries([QUERY_KEYS.USER_INFO]);
  }

  return data;
};
