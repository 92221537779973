import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import { Button } from 'primereact/button';

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <Button
      onClick={() => loginWithRedirect()}
      label='Login'
      className='mr-2 mb-2'
      style={{
        color: 'white',
        width: '17rem',
        display: 'flex',
        justifyContent: 'center',
        fontSize: '1.3rem',
        margin: '20px 0 20px 0',
      }}
    />
  );
};

export default LoginButton;
