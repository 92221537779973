export const compareLinks = (url, link) => {
  const reg = /:\w+/;
  const param = link.match(reg);
  if (!param) {
    return url === link;
  }
  const splittedUrl = url.split('/');
  const splittedLink = link.split('/');
  const paramIndex = splittedLink.indexOf(param[0]);
  splittedLink.splice(paramIndex, 1);
  splittedUrl.splice(paramIndex, 1);
  const newLink = splittedLink.join('/');
  const newUrl = splittedUrl.join('/');
  return newLink === newUrl;
};
