import React, { useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import Popup from './Popup';
import { BodyText } from '../fonts';
import { Wrapper } from './styles';
import { COLORS } from '../colors';
import { isVisible } from '../../utils/isVisible';

const TOOLTIP_MARGIN = 15;

const Tooltip = ({
  message,
  canShow = true,
  children,
  size,
  disabled = false,
  position,
  ...props
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState();
  const onMouseOver = () => setIsHovered(true);
  const onMouseLeave = () => setIsHovered(false);
  const canShowTip = isHovered && canShow && message && tooltipPosition;

  const targetRef = useRef();

  const getPosition = (targetWidth, targetLeft) => {
    switch (position) {
      case 'right':
        return `${targetLeft + targetWidth - TOOLTIP_MARGIN}px`;
      case 'center':
        return `${targetLeft + targetWidth / 2}px`;
      case 'left':
        return `${targetLeft + TOOLTIP_MARGIN}px`;
      default:
        return `${targetLeft + targetWidth / 2}px`;
    }
  };

  useEffect(() => {
    if (targetRef?.current) {
      const { bottom, left, width } = targetRef.current.getBoundingClientRect();
      if (width !== 0) {
        const tipTop = `${bottom + TOOLTIP_MARGIN / 3}px`;
        const tipLeft = getPosition(width, left);
        setTooltipPosition({
          top: tipTop,
          left: tipLeft,
        });
      }
    }
    if (isHovered) {
      setTimeout(() => setIsHovered(false), 3000); // Closes itself 3 seconds later
    }
  }, [isHovered]);

  const renderPopup = useCallback(() => {
    return (
      <Popup id='ucnjcozoum' size={size} style={tooltipPosition}>
        <BodyText
          id='mukkrkootz'
          style={{ whiteSpace: 'break-spaces' }}
          color={COLORS.White}
        >
          {message}
        </BodyText>
      </Popup>
    );
  }, [tooltipPosition?.top, tooltipPosition?.left, message]);

  const isTargetVisible = isVisible(targetRef?.current);

  // close tooltip if target is not visible
  useEffect(() => {
    if (!isTargetVisible) {
      setIsHovered(false);
    }
  }, [isTargetVisible]);

  return (
    <span {...props} ref={targetRef}>
      <Wrapper
        id='pwcryrsaqq'
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
      >
        {canShowTip && !disabled && createPortal(renderPopup(), document.body)}
        <span style={{ flex: 1 }}>{children}</span>
      </Wrapper>
    </span>
  );
};

export default Tooltip;
