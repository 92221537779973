import React from 'react';
import styled from 'styled-components';
import Loader from '../spinner';
import { Heading6 } from '../fonts';

const ActionLoaderWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ActionLoader = ({ text }) => {
  return (
    <ActionLoaderWrapper id='hckhdewwsr'>
      <Loader id='jgqfcxkquz' size='small' color='#1268FB' />
      <Heading6 id='ydgnvopfrs' style={{ marginLeft: '24px' }}>
        {text}
      </Heading6>
    </ActionLoaderWrapper>
  );
};

export default ActionLoader;
