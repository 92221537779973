import React from 'react';
import isEmpty from 'lodash.isempty';
import isEqual from 'lodash.isequal';
import Select from 'react-select';
import { COLORS } from '../colors';

const size = {
  extraSmall: {
    minHeight: '25px',
    marginTop: 0,
  },
  small: {
    minHeight: '32px',
    marginTop: 0,
  },
  normal: {
    minHeight: '46px',
    marginTop: '4px',
  },
  features: {
    minHeight: '125px',
    marginTop: '4px',
  },
};

const customStyles = {
  control: (styles, state) => ({
    ...styles,
    minHeight: size[state.selectProps.size].minHeight,
    // eslint-disable-next-line no-nested-ternary
    border: state.selectProps.error
      ? `1px solid ${COLORS.ErrorRed}`
      : state.isFocused
      ? `1px solid ${COLORS.MainBlue}`
      : `1px solid ${COLORS.Gray2}`,
    boxShadow: state.selectProps.error
      ? `0px 0px 0px 2px ${COLORS.ErrorShadow}`
      : state.isFocused && `0 0 0 2px ${COLORS.FocusBlue}`,
    marginTop: size[state.selectProps.size].marginTop,
    fontSize: '14px',
    span: {
      display: 'none',
    },
    '&:hover': {
      boxShadow:
        !state.selectProps.isValidate &&
        (state.isFocused ? '0' : `0 0 0 2px ${COLORS.HoverGray}`),
    },
  }),
  option: provided => ({
    ...provided,
    width: '99.5%',
    fontSize: '14px',
    lineHeight: '16px',
    padding: '8px 12px',
  }),
  multiValue: styles => ({
    ...styles,
    height: '24px',
    backgroundColor: COLORS.NeutralGray,
  }),
  multiValueLabel: styles => ({
    ...styles,
    fontSize: '14px',
    marginTop: '2px',
  }),
  multiValueRemove: styles => ({
    ...styles,
    color: COLORS.TextBlack,
    ':hover': {
      backgroundColor: COLORS.NeutralGray,
    },
  }),
  valueContainer: styles => ({
    ...styles,
    maxHeight: '90px',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '4px',
    },
    '&::-webkit-scrollbar-thumb': {
      outline: '1px solid slategrey',
      background: '#E5E5E5',
      borderRadius: '4px',
    },
  }),
  menuList: styles => ({
    ...styles,
    maxHeight: '170px',
    overflowY: 'auto',
    padding: 0,

    '&::-webkit-scrollbar': {
      width: '4px',
      height: '10px',
    },
    '&::-webkit-scrollbar-thumb': {
      outline: '1px solid slategrey',
      background: '#E5E5E5',
      borderRadius: '4px',
    },
  }),
};
const paginationStyles = {
  control: (styles, state) => ({
    ...styles,
    minHeight: size[state.selectProps.size].minHeight,
    border: 'none',
    boxShadow: 'none',
    marginTop: size[state.selectProps.size].marginTop,
    fontSize: '14px',
    span: {
      display: 'none',
    },
  }),
  option: provided => ({
    ...provided,
    width: '99.5%',
    fontSize: '14px',
    lineHeight: '16px',
    padding: '8px 12px',
  }),
  multiValue: styles => ({
    ...styles,
    height: '24px',
    backgroundColor: COLORS.NeutralGray,
  }),
  multiValueLabel: styles => ({
    ...styles,
    fontSize: '14px',
    marginTop: '2px',
  }),
  multiValueRemove: styles => ({
    ...styles,
    color: COLORS.TextBlack,
    ':hover': {
      backgroundColor: COLORS.NeutralGray,
    },
  }),
  valueContainer: styles => ({
    ...styles,
    maxHeight: '90px',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '4px',
    },
    '&::-webkit-scrollbar-thumb': {
      outline: '1px solid slategrey',
      background: '#E5E5E5',
      borderRadius: '4px',
    },
  }),
  menuList: styles => ({
    ...styles,
    maxHeight: '170px',
    overflowY: 'auto',
    padding: 0,

    '&::-webkit-scrollbar': {
      width: '4px',
      height: '10px',
    },
    '&::-webkit-scrollbar-thumb': {
      outline: '1px solid slategrey',
      background: '#E5E5E5',
      borderRadius: '4px',
    },
  }),
  dropdownIndicator: styles => ({
    ...styles,
    paddingRight: 0,
    paddingLeft: 0,
    cursor: 'pointer',
  }),
};

// There is used a React-Select library
// https://react-select.com/home
export const SelectComponent = ({ menuPortalStyle, ...props }) => {
  const allOptions = [];

  if (!isEmpty(props.options)) {
    allOptions.push(...props.options);

    if (props.isMulti) {
      const allChosenValues = props.value.map(option => option.value).sort();
      const allExistingOptions = props.options
        .map(option => option.value)
        .sort();
      const areAllOptionsChosen = isEqual(allChosenValues, allExistingOptions);

      if (!areAllOptionsChosen) {
        allOptions.unshift({
          label: 'Select All',
          value: 'all',
        });
      }
    }
  }

  const handleChange = (selected, event) => {
    const isAllOptionSelected =
      props.isMulti &&
      selected.length &&
      selected.find(option => option.value === 'all');

    if (isAllOptionSelected) {
      props.onChange(allOptions.slice(1), event);
    } else {
      props.onChange(selected, event);
    }
  };

  const selectProps = {
    ...props,
    options: allOptions,
    onChange: handleChange,
  };

  return (
    <Select
      id='rsmehealzz'
      styles={{ ...customStyles, menuPortal: menuPortalStyle }}
      {...selectProps}
      isDisabled={props.disabled}
    />
  );
};

export const PaginationSelect = ({ ...props }) => {
  return (
    <Select
      id='mcufombgur'
      styles={{ ...paginationStyles }}
      {...props}
      isSearchable={false}
    />
  );
};

SelectComponent.defaultProps = {
  size: 'normal',
};
