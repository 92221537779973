import React from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-cycle
import { BodyText, ErrorText, RequiredStar, Input } from '../index';
import { FieldWrapper } from './FormField.styles';

const InputField = ({
  isRequired,
  label,
  name,
  placeholder,
  onChange,
  onBlur,
  form: { values, errors, touched },
  ...props
}) => {
  const renderError = () => {
    if (touched) {
      return touched[name] && errors[name] ? (
        <ErrorText id='rcyjqzztkl'>*{errors[name]}</ErrorText>
      ) : null;
    }
    return errors[name] ? (
      <ErrorText id='mrwfhmfvht'>*{errors[name]}</ErrorText>
    ) : null;
  };

  return (
    <FieldWrapper id='zebqxezoxx'>
      <BodyText id='lytxsjzrtz'>
        {label}
        {isRequired && <RequiredStar id='efijcayjlk' />}
      </BodyText>
      <Input
        id={name}
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        value={values[name]}
        error={errors[name]}
        {...props}
      />
      {renderError()}
    </FieldWrapper>
  );
};

InputField.propTypes = {
  name: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
};

InputField.defaultProps = {
  isRequired: false,
  label: '',
  placeholder: '',
};

export default InputField;
