import React, { useEffect, useRef, useState } from 'react';
import isEmpty from 'lodash.isempty';
import {
  PolygonsCameraImage,
  PolygonsEditorWrapper,
  PolygonsStage,
  PolygonsWorkspace,
} from './PolygonsEditor.styles';
import PolygonCTA from './PolygonCTA';
import { useWindowSize } from '../../hooks/useWindowSize';
import CustomStage from './custom/CustomStage';
import ZoneStage from './zone/ZoneStage';
import RectangleStage from './rectangle/RectangleStage';
import { useAppContext } from '../../AppContext';
import ArrowStage from './arrow/ArrowStage';
import PlaneStage from './plane/PlaneStage';

export const SHAPE_TYPES = {
  CUSTOM: 'custom',
  RECTANGLE: 'rectangle',
  ARROW: 'arrow',
  PLANE: 'plane',
  ZONE: 'zone',
};

// PROPS:
// shapeType: SHAPE_TYPES
export const PolygonsEditor = ({
  shapeType = SHAPE_TYPES.CUSTOM,
  cameraImage,
  sidesPerPolygonLimit,
  polygonsLimit,
  polygons,
  setPolygons,
  setAreActionsDisabled,
  unmutuablePolygons,
  opacity,
  setWasPointMoved,
  isUserActionPermitted,
  transform = true,
  // arrows
  mkpts0,
  mkpts1,
  colors,
  // plane
  planeCalibrationOutput,
  setPlaneCalibrationOutput,
  showGrid,
  handleUpdatePlaneCalibration,
  patchCoordinates,
  setPatchCoordinates,
  setPreviusPatchCoordinates,
  previusPatchCoordinates,
  showLiveImage,
  enableCornerSelection,
  setCornerPoints,
  cornerPoints,
  isUpdatingPlane,
  runOrder,
  setRunOrder,
  isLastVersion,
  isReferenceImageAvailable,
  //zones
  isStyledForZone = false,
  // needed for responsive zone item
  zoneSetImgRelativeWidth,
  zoneSetImgRelativeHeight,
  showZoneLabels = false,
}) => {
  const { isSidebarOpened } = useAppContext();

  const imgRef = useRef(null);
  const [imgRefWidth, setImgRefWidth] = useState(0);
  const [imgRealWidth, setImgRealWidth] = useState(0);
  const [imgRealHeight, setImgRealHeight] = useState(0);
  const [imgRelativeWidth, setImgRelativeWidth] = useState(0);
  const [imgRelativeHeight, setImgRelativeHeight] = useState(0);
  const [isPolygonDrawing, setIsPolygonDrawing] = useState(false);
  const [isDummyClicked, setIsDummyClicked] = useState(false);
  const { width: windowWidth, height: windowHeight } = useWindowSize();

  // useEffect(() => {
  //   setAreActionsDisabled(true);
  // }, []);

  useEffect(() => {
    const width = imgRef.current?.offsetWidth;
    if (width > 0) {
      setImgRefWidth(width);
    }
  }, [imgRef.current?.offsetWidth, windowWidth, windowHeight]);

  useEffect(() => {
    setTimeout(() => {
      const offsetWidth = imgRef.current?.offsetWidth;
      setImgRefWidth(offsetWidth);
    }, 400);
  }, [isSidebarOpened]);

  useEffect(() => {
    const img = new Image();
    img.src = cameraImage;
    img.onload = () => {
      const realWidth = img.width;
      const realHeight = img.height;
      if (realWidth > 0 && realHeight > 0) {
        setImgRealWidth(realWidth);
        setImgRealHeight(realHeight);
      }
    };
  }, [cameraImage]);

  useEffect(() => {
    if (imgRealWidth > 0 && imgRealHeight > 0) {
      const relativeWidth = imgRefWidth;
      const relativeHeight = (relativeWidth / imgRealWidth) * imgRealHeight;
      if (relativeWidth > 0 && relativeHeight > 0) {
        setImgRelativeWidth(relativeWidth);
        setImgRelativeHeight(relativeHeight);
        if (shapeType === SHAPE_TYPES.ZONE) {
          zoneSetImgRelativeHeight(relativeHeight);
          zoneSetImgRelativeWidth(relativeWidth);
        }
      }
    }
  }, [imgRealWidth, imgRealHeight, imgRefWidth]);

  useEffect(() => {
    if (isEmpty(polygons)) {
      setIsDummyClicked(false);
    }
    if (shapeType === SHAPE_TYPES.PLANE || shapeType === SHAPE_TYPES.ZONE) {
      setIsDummyClicked(true);
    }
  }, [polygons]);

  const getStage = () => {
    switch (shapeType) {
      case SHAPE_TYPES.CUSTOM:
        return (
          <CustomStage
            id='iigwxexefk'
            stageWidth={imgRelativeWidth}
            stageHeight={imgRelativeHeight}
            polygons={polygons}
            isPolygonDrawing={isPolygonDrawing}
            setIsPolygonDrawing={setIsPolygonDrawing}
            setAreActionsDisabled={setAreActionsDisabled}
            setPolygons={setPolygons}
            sidesPerPolygonLimit={sidesPerPolygonLimit}
            polygonsLimit={polygonsLimit}
            unmutuablePolygons={unmutuablePolygons}
            opacity={opacity}
            setWasPointMoved={setWasPointMoved}
            isUserActionPermitted={isUserActionPermitted}
            transform={transform}
          />
        );
      case SHAPE_TYPES.RECTANGLE:
        return (
          <RectangleStage
            id='znczmqpbjr'
            stageWidth={imgRelativeWidth}
            stageHeight={imgRelativeHeight}
            polygons={polygons}
            isPolygonDrawing={isPolygonDrawing}
            setIsPolygonDrawing={setIsPolygonDrawing}
            setAreActionsDisabled={setAreActionsDisabled}
            setPolygons={setPolygons}
            polygonsLimit={polygonsLimit}
            setWasPointMoved={setWasPointMoved}
            isUserActionPermitted={isUserActionPermitted}
            transform={transform}
          />
        );
      case SHAPE_TYPES.ZONE:
        return (
          <ZoneStage
            id='pqyubnidej'
            stageWidth={imgRelativeWidth}
            stageHeight={imgRelativeHeight}
            polygons={polygons}
            isPolygonDrawing={isPolygonDrawing}
            setIsPolygonDrawing={setIsPolygonDrawing}
            setAreActionsDisabled={setAreActionsDisabled}
            setPolygons={setPolygons}
            sidesPerPolygonLimit={sidesPerPolygonLimit}
            polygonsLimit={polygonsLimit}
            opacity={opacity}
            setWasPointMoved={setWasPointMoved}
            isUserActionPermitted={isUserActionPermitted}
            transform={transform}
            imgRealHeigth={imgRealHeight}
            imgRealWidth={imgRealWidth}
            showZoneLabels={showZoneLabels}
          />
        );
      case SHAPE_TYPES.ARROW:
        return (
          <ArrowStage
            id='ckionlmpgk'
            stageWidth={imgRelativeWidth}
            stageHeight={imgRelativeHeight}
            mkpts0={mkpts0}
            mkpts1={mkpts1}
            colors={colors}
          />
        );
      case SHAPE_TYPES.PLANE:
        return (
          <PlaneStage
            id='kjeljtatbn'
            stageWidth={imgRelativeWidth}
            stageHeight={imgRelativeHeight}
            planeCalibrationOutput={planeCalibrationOutput}
            setPlaneCalibrationOutput={setPlaneCalibrationOutput}
            showGrid={showGrid}
            polygons={polygons}
            setPolygons={setPolygons}
            isPolygonDrawing={isPolygonDrawing}
            setIsPolygonDrawing={setIsPolygonDrawing}
            handleUpdatePlaneCalibration={handleUpdatePlaneCalibration}
            patchCoordinates={patchCoordinates}
            setPatchCoordinates={setPatchCoordinates}
            previusPatchCoordinates={previusPatchCoordinates}
            setPreviusPatchCoordinates={setPreviusPatchCoordinates}
            isUserActionPermitted={isUserActionPermitted}
            showLiveImage={showLiveImage}
            enableCornerSelection={enableCornerSelection}
            setCornerPoints={setCornerPoints}
            cornerPoints={cornerPoints}
            runOrder={runOrder}
            setRunOrder={setRunOrder}
            isUpdatingPlane={isUpdatingPlane}
            isLastVersion={isLastVersion}
            isReferenceImageAvailable={isReferenceImageAvailable}
          />
        );
      default:
        return (
          <CustomStage
            id='luwlohnlut'
            stageWidth={imgRelativeWidth}
            stageHeight={imgRelativeHeight}
            polygons={polygons}
            setPolygons={setPolygons}
            sidesPerPolygonLimit={sidesPerPolygonLimit}
            polygonsLimit={polygonsLimit}
            unmutuablePolygons={unmutuablePolygons}
            setWasPointMoved={setWasPointMoved}
            isUserActionPermitted={isUserActionPermitted}
            transform={transform}
          />
        );
    }
  };

  return (
    <PolygonsEditorWrapper
      id='bmhptjmfhd'
      style={{
        marginTop: isStyledForZone ? '25px' : '',
        border: isStyledForZone ? '1px solid rgba(27, 39, 51, 0.15)' : '',
      }}
    >
      <PolygonsWorkspace id='kaczekhxuk'>
        <PolygonsCameraImage
          id='kckopfjvqr'
          ref={imgRef}
          alt='img'
          src={cameraImage}
        />
        {imgRelativeWidth > 0 && imgRelativeHeight > 0 && (
          <PolygonsStage id='upebiwmebq'>
            {isEmpty(polygons) && !isDummyClicked && isUserActionPermitted && (
              <PolygonCTA
                id='hzugjnebon'
                setIsDummyClicked={setIsDummyClicked}
              />
            )}
            {getStage()}
          </PolygonsStage>
        )}
      </PolygonsWorkspace>
    </PolygonsEditorWrapper>
  );
};
