import React from 'react';
import { Button } from 'primereact/button';

const StyledButton = ({ label, className, disabled, onClick }) => {
  return (
    <Button
      label={label}
      className={className}
      disabled={disabled || false}
      onClick={onClick}
      style={{
        width: 'auto',
        fontSize: '16px',
        lineHeight: '18px',
        fontStyle: 'normal',
        fontWeight: 600,
        borderRadius: '8px',
        margin: '8px',
      }}
    />
  );
};

export default StyledButton;
