import React from 'react';
// eslint-disable-next-line import/no-cycle
import { Dialog, Heading2, Heading6, StyledButton } from '../index';

const ReloadModal = ({ onClose, onContinue, isOpen }) => {
  const renderHeader = () => (
    <Heading2 id='osumxafhdk'>You have unsaved changes</Heading2>
  );

  const renderBody = () => {
    return <Heading6 id='kequrrfvia'>Are you sure you want to quit?</Heading6>;
  };

  const renderFooter = () => (
    <>
      <StyledButton
        label='No'
        className='p-button-outlined mr-2 mb-2'
        onClick={onClose}
      />
      <StyledButton
        label='Yes'
        className='p-button-raised mr-2 mb-2'
        onClick={onContinue}
        disabled={false}
      />
    </>
  );

  return (
    <Dialog
      id='mrlthcwgcz'
      zIndex='10000'
      size='small'
      isOpen={isOpen}
      onClose={onClose}
      header={renderHeader()}
      body={renderBody()}
      footer={renderFooter()}
    />
  );
};

export default ReloadModal;
