// Libs
import styled from 'styled-components';
// Constants
import { COLORS } from '../colors';

export const SidebarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 80px;
  left: 0;
  height: calc(100vh - 80px);
  justify-content: space-between;
  width: ${({ isOpen }) => (isOpen ? '231px' : '50px')};
  min-width: ${({ isOpen }) => (isOpen ? '230px' : '50px')};

  overflow: hidden;
  transition: all var(--default-transition-duration) ease;
  z-index: 998;
  background: ${COLORS.White};
  box-shadow: 0 8px 12px rgba(9, 30, 66, 0.2), 0 0 1px rgba(9, 30, 66, 0.47);
`;

export const SidebarContainer = styled.div`
  width: ${({ isOpen }) => (isOpen ? '230px' : '50px')};
  min-width: ${({ isOpen }) => (isOpen ? '230px' : '50px')};
  transition: all var(--default-transition-duration) ease;
`;

export const SidebarBody = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 3px;
  }
  &::-webkit-scrollbar-thumb {
    outline: 1px solid slategrey;
    background: #e5e5e5;
    border-radius: 4px;
  }
`;

export const SidebarLinkWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 16px;
  margin-bottom: 8px;
  cursor: pointer;

  width: 229px;
  height: 40px;
  left: 0px;
  top: 48px;

  background: ${({ isActive }) =>
    isActive ? COLORS.SecondaryBlue2 : COLORS.White};
  border-radius: ${({ isActive }) =>
    isActive ? '0px 20px 20px 0px' : '0px 0px 0px 0px'};
  & p {
    color: ${({ isActive }) => (isActive ? COLORS.MainBlue : COLORS.TextBlack)};
  }
  & svg {
    fill: ${({ isActive }) => (isActive ? COLORS.MainBlue : COLORS.TextBlack)};
  }
  &:hover {
    p {
      color: ${COLORS.MainBlue};
    }
  }
`;

export const SidebarLinkItem = styled.div`
  display: flex;
  align-items: center;

  & svg {
    margin-right: 8px;
  }
`;

export const SidebarArrow = styled.div`
  transform: ${({ isOpen }) => (isOpen ? '' : 'rotate(-90deg)')};
  & svg {
    width: 12px;
  }
`;

export const SidebarFooter = styled.div`
  display: flex;
  padding: 12px 16px;
  border-top: 2px solid #f0f0f0;
  justify-content: space-between;
`;

export const SidebarVersion = styled.div`
  color: gray;
`;

export const SidebarSubLinkWrapper = styled.div`
  margin: 0px 41px 0px 25px;
  padding: 13px 30px 13px 15px;

  font-size: 14px;
  line-height: 18px;
  color: ${({ isActive }) => (isActive ? COLORS.MainBlue : COLORS.TextBlack)};
  border-left: ${({ isActive }) =>
    isActive ? `3px solid ${COLORS.MainBlue}` : `3px solid #E5E5E5`};
  cursor: pointer;
  &:hover {
    color: ${COLORS.MainBlue};
  }
`;

export const IconBlockPointer = styled.div`
  display: flex;
  justify-content: flex-start;
  transform: ${({ degree }) => `rotate(${degree}deg)`};
  transition: transform 0.3s;
  cursor: pointer;
`;
