import React from 'react';
import styled, { keyframes } from 'styled-components';

const ldsRing = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const sizes = {
  tinny: {
    wrapper: '15px',
    spinner: '10px',
    border: '2px',
  },
  small: {
    wrapper: '16px',
    spinner: '16px',
    border: '3px',
  },
  normal: {
    wrapper: '80px',
    spinner: '40px',
    border: '4px',
  },
};

const Spinner = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ size }) => (size ? sizes[size].wrapper : sizes.normal.wrapper)};
  height: ${({ size }) => (size ? sizes[size].wrapper : sizes.normal.wrapper)};
  & div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: ${({ size }) => (size ? sizes[size].spinner : sizes.normal.spinner)};
    height: ${({ size }) =>
      size ? sizes[size].spinner : sizes.normal.spinner};
    border: ${({ color, size }) =>
      `${size ? sizes[size].border : sizes.normal.border} solid ${
        color ?? '#fff'
      }`};
    border-radius: 50%;
    animation: ${ldsRing} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${({ color }) =>
      `${color ?? '#fff'} transparent transparent transparent`};
  }
  & div:nth-child(1) {
    animation-delay: -0.45s;
  }
   & div:nth-child(2) {
    animation-delay: -0.3s;
  }
  & div:nth-child(3) {
    animation-delay: -0.15s;
`;

const Loader = ({ color, size }) => {
  return (
    <Spinner id='cyiojwoevk' color={color} size={size}>
      <div />
      <div />
      <div />
    </Spinner>
  );
};

export default Loader;
