import { v4 as uuidv4 } from 'uuid';
import isEmpty from 'lodash.isempty';
import { getRandomColor } from './utils';

const getRectParametersByPoints = points => {
  const res = {
    x: null,
    y: null,
    width: null,
    height: null,
    finishX: null,
    finishY: null,
  };
  if (!isEmpty(points) && points.length === 2) {
    const [firstPointX, firstPointY] = points[0];
    const [finishPointX, finishPointY] = points[1];

    const width = finishPointX - firstPointX;
    const height = finishPointY - firstPointY;

    res.x = firstPointX;
    res.y = firstPointY;
    res.finishX = finishPointX;
    res.finishY = finishPointY;
    res.width = width;
    res.height = height;
  }
  return res;
};

export const convertToRectangleObject = (polygon = {}) => {
  const { payload } = polygon;
  const { x, y, width, height, finishX, finishY } =
    getRectParametersByPoints(payload);
  return {
    id: uuidv4(),
    x: x || 0,
    y: y || 0,
    finishX: finishX || 0,
    finishY: finishY || 0,
    offsetX: 0,
    offsetY: 0,
    width: width || 0,
    height: height || 0,
    color: getRandomColor(),
    error: '',
    isClosed: !isEmpty(polygon),
  };
};

export const convertToLineObject = (polygon = {}) => {
  const { payload, name } = polygon;
  return {
    id: uuidv4(),
    x: 0,
    y: 0,
    offsetX: 0,
    offsetY: 0,
    points: payload || [],
    color: getRandomColor(),
    name: name || '',
    error: '',
    isClosed: !isEmpty(polygon),
  };
};
