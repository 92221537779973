import React, { useState } from 'react';
import {
  SidebarArrow,
  SidebarLinkItem,
  SidebarLinkWrapper,
} from './Sidebar.style';
import { SideBarTitle } from '../fonts';
import { useAppContext } from '../../AppContext';
// eslint-disable-next-line import/no-cycle
import { getPageIcon } from '../../utils/iconUtils';
import { COLORS } from '../colors';
import { Icon } from '../iconComponent/Icon';

const SidebarLink = ({ item = {}, onClick, children }) => {
  const { isSidebarOpened } = useAppContext();
  const { isOpen, isActive, name } = item;
  const [isHovered, setIsHovered] = useState(false);

  const getIconColor = () => {
    return isHovered || isActive ? COLORS.MainBlue : COLORS.TextBlack;
  };

  return (
    <>
      <SidebarLinkWrapper
        id='ncolmxgelf'
        onMouseOver={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        isActive={isActive}
        onClick={() => onClick(item)}
      >
        <SidebarLinkItem id='vtbnamkvqf'>
          {getPageIcon(name, 'normal', getIconColor())}
          {isSidebarOpened && (
            <SideBarTitle id='bkbwpumdsi'>{name}</SideBarTitle>
          )}
        </SidebarLinkItem>
        {children && isSidebarOpened && (
          <SidebarArrow id='itsprfcnbn' isOpen={isOpen}>
            <Icon
              id='ldufhshgvm'
              size='extraSmall'
              iconName='ChevronIcon'
              fill={getIconColor()}
            />
          </SidebarArrow>
        )}
      </SidebarLinkWrapper>
      {children}
    </>
  );
};

export default SidebarLink;
