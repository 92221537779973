import React from 'react';
import { Rect } from 'react-konva';
import { hexToRgb } from '../utils';
import Transform from './Transform';

const Rectangles = ({
  polygons,
  onDragMove,
  setPolygons,
  setIsPolygonDrawing,
  setAreActionsDisabled,
  transform,
  setWasPointMoved,
  stageHeight,
  stageWidth,
  isPolygonDrawing,
  isUserActionPermitted,
}) => {
  const handleMouseEnter = event => {
    const container = event.target.getStage().container();
    container.style.cursor = 'pointer';
  };

  const handleMouseLeave = event => {
    const container = event.target.getStage().container();
    container.style.cursor = 'default';
  };

  const getRectActions = id => {
    if (isUserActionPermitted) {
      return {
        onDragMove: e => onDragMove(id, e),
        onMouseEnter: handleMouseEnter,
        onMouseLeave: handleMouseLeave,
      };
    }
  };
  return (
    <>
      {polygons.map(polygon => {
        const { id, x, y, points, isClosed, color, width, height } = polygon;
        const { red, green, blue } = hexToRgb(color);
        const stroke = `rgba(${red},${green},${blue}, 1)`;
        return (
          <>
            <Rect
              id='zcmpzgqzqd'
              key={id}
              {...getRectActions(id)}
              x={x}
              y={y}
              width={width}
              height={height}
              points={points}
              closed={isClosed}
              draggable={!isPolygonDrawing && isUserActionPermitted}
              stroke='blue'
              strokeWidth={5}
            />
            {isClosed && transform && !isPolygonDrawing && (
              <Transform
                id='bghlhdzjjk'
                key={`${id}-points`}
                polygon={polygon}
                polygonId={id}
                pointsList={points}
                setPolygons={setPolygons}
                stroke={stroke}
                setIsPolygonDrawing={setIsPolygonDrawing}
                setAreActionsDisabled={setAreActionsDisabled}
                draggable={isUserActionPermitted}
                setWasPointMoved={setWasPointMoved}
                stageWidth={stageWidth}
                stageHeight={stageHeight}
              />
            )}
          </>
        );
      })}
    </>
  );
};

export default Rectangles;
