import React, { memo } from 'react';
import chunk from 'lodash.chunk';
import { Circle } from 'react-konva';
import isEqual from 'lodash.isequal';
import { normalizePoint } from '../utils';

function areEqual(prevProps, nextProps) {
  return (
    prevProps.offsetX === nextProps.offsetX &&
    prevProps.offsetY === nextProps.offsetY &&
    isEqual(prevProps.pointsList, nextProps.pointsList)
  );
}

const Transform = memo(
  ({
    polygonId,
    pointsList,
    stroke,
    offsetX,
    offsetY,
    setPolygons,
    setIsPolygonDrawing,
    setAreActionsDisabled,
    draggable,
    setWasPointMoved,
    isStyledForZone,
  }) => {
    const points = chunk(pointsList, 2);

    const deletePoint = event => {
      event.evt.preventDefault();
      setAreActionsDisabled(true);
      setIsPolygonDrawing(true);
      setPolygons(prevState => {
        const state = [...prevState];
        const polygonIndex = state.findIndex(item => item.id === polygonId);

        // move editing polygon to the last index for drawing new line
        const polygon = state.splice(polygonIndex, 1)[0];

        const { index: pointIndex } = event.target.getAttrs();
        const firstPart = polygon.points.slice(0, pointIndex);
        const secondPart = polygon.points.slice(pointIndex + 1, -1);
        polygon.points = [...secondPart, ...firstPart];
        polygon.isClosed = false;
        state.push(polygon);
        return state;
      });
    };

    const onDrag = event => {
      setWasPointMoved(true);
      setPolygons(prevState => {
        const state = [...prevState];
        const { width, height } = event.target.getStage().getAttrs();
        const { x, y, index: pointIndex } = event.target.getAttrs();
        const polygonIndex = state.findIndex(item => item.id === polygonId);
        const polygonPoints = state[polygonIndex].points;
        let [normalizeX, normalizeY] = normalizePoint(width, height, [x, y]);
        const [normalizeOffsetX, normalizeOffsetY] = normalizePoint(
          width,
          height,
          [offsetX, offsetY]
        );

        // move points only in canvas
        if (normalizeX >= 1) {
          event.target.x(width);
          normalizeX = 1;
        }
        if (normalizeX <= 0) {
          event.target.x(0);
          normalizeX = 0;
        }
        if (normalizeY >= 1) {
          event.target.y(height);
          normalizeY = 1;
        }
        if (normalizeY <= 0) {
          event.target.y(0);
          normalizeY = 0;
        }

        polygonPoints[pointIndex] = [
          normalizeX - normalizeOffsetX,
          normalizeY - normalizeOffsetY,
        ];
        return state;
      });
    };

    return (
      <>
        {points.map((point, index) => {
          return (
            <Circle
              id='geaznvozuv'
              key={`point${index}`}
              onContextMenu={deletePoint}
              onDragMove={onDrag}
              index={index}
              draggable={draggable}
              radius={isStyledForZone ? 5 : 8}
              fill={isStyledForZone ? 'black' : 'white'}
              stroke={isStyledForZone ? 'black' : stroke}
              x={point[0] + offsetX}
              y={point[1] + offsetY}
              strokeWidth={isStyledForZone ? 2 : 5}
            />
          );
        })}
      </>
    );
  },
  areEqual
);

export default Transform;
