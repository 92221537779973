import styled from 'styled-components';

const sizeMap = {
  large: '500px',
  normal: '200px',
};

export default styled.div`
  position: relative;
  z-index: 1001;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 8px;
  gap: 10px;

  flex: none;
  order: 1;
  flex-grow: 1;

  width: 105px;
  height: auto;

  background-color: #2f3b48;
  padding: 6px 8px;
  text-align: center;
  position: absolute;
  color: white;
  z-index: 1005;
  max-width: ${({ size }) => (size ? sizeMap[size] : sizeMap.normal)};
  width: max-content;
  border-radius: 6px;
  word-break: break-word;
  transform: translate(-50%, 0);
  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 6px;
    height: 6px;
    background-color: #2f3b48;
    transform: rotate(45deg) translate(-50%, 0);
    top: 0px;
    left: 50%;
  }
`;
