import styled from 'styled-components';

export const SearchBarContainer = styled.div`
  margin-bottom: 16px;
  margin-top: 2px;
`;

export const SearchInputWrapper = styled.div`
  position: relative;
  width: 400px;
`;

export const SearchIconWrapper = styled.div`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  left: var(--spacing-3xs);
  pointer-events: none;
  z-index: 1;
`;

export const ClearButton = styled.div`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  right: var(--spacing-2xs);
  &:hover {
    cursor: pointer;
  }
`;
