import React from 'react';
import PropTypes from 'prop-types';
import { FlexContainer } from '../flexContainer';
import StateInfo from '../stateInfo';

const Placeholder = ({
  title,
  subtitle,
  icon,
  children,
  height,
  cameraPipelineStatus,
}) => {
  return (
    <FlexContainer
      id='jzftmdzuoo'
      fluid
      justify='center'
      align='center'
      direction='column'
      height={height}
    >
      <StateInfo
        id='xcylscbpmr'
        icon={icon}
        title={title}
        subtitle={subtitle}
        cameraPipelineStatus={cameraPipelineStatus}
      />
      {children}
    </FlexContainer>
  );
};

Placeholder.propTypes = {
  /**
   * Title of the placeholder
   */
  title: PropTypes.string.isRequired,

  /**
   * Subtitle of the placeholder
   */
  subtitle: PropTypes.string,

  /**
   * The icon name
   */
  icon: PropTypes.string.isRequired,

  children: PropTypes.node,
  /**
   * Height of the wrapper.
   */
  height: PropTypes.string,
};

Placeholder.defaultProps = {
  subtitle: '',
  children: null,
  height: null,
};

export default Placeholder;
