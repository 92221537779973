import React from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-cycle
import InputField from './InputField';
import CheckboxField from './CheckboxField';
import SelectField from './SelectField';
import PasswordField from './PasswordField';

export const CONTROL_TYPES = {
  input: 'input',
  password: 'password',
  checkbox: 'checkbox',
  select: 'select',
};

export const FormControl = ({ control, name, ...props }) => {
  switch (control) {
    case CONTROL_TYPES.input: {
      return <InputField id='qpldlpdcnh' name={name} {...props} />;
    }
    case CONTROL_TYPES.password: {
      return <PasswordField id='pqtlrnuifc' name={name} {...props} />;
    }
    case CONTROL_TYPES.checkbox: {
      return <CheckboxField id='ytxgrlmgdw' name={name} {...props} />;
    }
    case CONTROL_TYPES.select: {
      return <SelectField id='yavudyaqcx' name={name} {...props} />;
    }
    default: {
      return <InputField id='fvehyaecmq' name={name} {...props} />;
    }
  }
};

FormControl.propTypes = {
  name: PropTypes.string.isRequired,
};
