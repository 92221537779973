import React from 'react';
import {
  CloseArea,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogModal,
  DialogScrollBar,
  ScrolledContent,
} from './Dialog.styles';
import { CrossIcon } from '../icons';

const getCustomStyles = zIndex => {
  return {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(27, 39, 51, 0.4)',
      zIndex: zIndex || 1000,
    },
  };
};

// DIALOG
// props:
// size='huge', 'largest', 'large', 'small'
// There is used a React-Modal library what apply styles in styled-components
// http://reactcommunity.org/react-modal/
export const Dialog = ({
  isOpen,
  onAfterOpen,
  onClose,
  header,
  body,
  footer,
  scrollbar,
  size,
  maxSize,
  bodyOverflow,
  zIndex = 1000,
  isCrossEnabled = true,
}) => {
  return (
    <DialogModal
      id='modal-dialog'
      style={getCustomStyles(zIndex)}
      isOpen={isOpen}
      onAfterOpen={onAfterOpen}
      onRequestClose={onClose}
      ariaHideApp={false}
      parentSelector={() =>
        document.querySelector('#modal-area') || document.body
      }
    >
      <DialogContent id='fauxbhsoro' size={size} maxSize={maxSize}>
        {isCrossEnabled ? (
          <CloseArea id='aivdaplrcf'>
            <CrossIcon id='qxlozfrnvc' size='small' onClick={onClose} />
          </CloseArea>
        ) : (
          ''
        )}
        <DialogHeader id='qfmooskgkc'>{header}</DialogHeader>
        <DialogBody id='jcycliuqzd'>
          <ScrolledContent id='fvcybybcps' overflow={bodyOverflow}>
            {body}
          </ScrolledContent>
        </DialogBody>
        {footer ? (
          <>
            <DialogFooter id='yzrzpzmcji'>{footer}</DialogFooter>
            <DialogScrollBar id='imzseoxacl'>{scrollbar}</DialogScrollBar>
          </>
        ) : (
          ''
        )}
      </DialogContent>
    </DialogModal>
  );
};
