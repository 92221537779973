import { RULES } from '../../routes/RouteCameraRules';
// eslint-disable-next-line import/no-cycle
import { ALL_CAMERAS_ID } from './Header';

export const getFullPathArrayForSelect = (pathArray, getPath) => {
  return pathArray.map(allowedPath => getPath({ item: allowedPath }));
};

function includeByLabel(data, keywords) {
  // include array of words
  return data.filter(item => keywords.some(word => item.label.includes(word)));
}

function excludeByLabel(data, keywords) {
  // disclude array of words
  return data.filter(item => !keywords.some(word => item.label.includes(word)));
}

export const filterCameraSelection = (pathOrComponent, options, storeName) => {
  // apply rules defined in RULES constant
  let optionsCopy = [...options];
  let overrideApplied = false;
  RULES.forEach(rule => {
    if (pathOrComponent.includes(rule.path)) {
      if ('overrideForStore' in rule) {
        rule.overrideForStore.forEach(overrideRule => {
          if (storeName === overrideRule.store) {
            if (overrideRule.excludeCameraPrefix.length > 0) {
              optionsCopy = excludeByLabel(
                optionsCopy,
                overrideRule.excludeCameraPrefix
              );
            }
            if (overrideRule.includeCameraPrefix.length > 0) {
              optionsCopy = includeByLabel(
                optionsCopy,
                overrideRule.includeCameraPrefix
              );
            }
            overrideApplied = true;
          }
        });
      }
      if (!overrideApplied) {
        if (rule.excludeCameraPrefix.length > 0) {
          optionsCopy = excludeByLabel(optionsCopy, rule.excludeCameraPrefix);
        }
        if (rule.includeCameraPrefix.length > 0) {
          optionsCopy = includeByLabel(optionsCopy, rule.includeCameraPrefix);
        }
      }
    }
  });
  return optionsCopy;
};

// Extra options beside cameras options
export const modifyCameraSelection = (path, options) => {
  if (path.includes('camera_view_assist'))
    options.unshift({
      label: 'All Cameras',
      value: ALL_CAMERAS_ID,
    });
  if (path.includes('installation_support'))
    options.unshift({
      label: 'Store level',
      value: null,
    });
  return options;
};

// in order reach full properties of globalLocation
export const getLocation = (locations, locationId) => {
  return locations.find(location => location.id === locationId);
};

// check if can switch cameras depending on filtered camera options
export const canSwitchLocation = (
  cameraOptions,
  globalCamera,
  globalLocation,
  path
) => {
  if (globalCamera && globalLocation) {
    const filteredOptions = filterCameraSelection(
      path,
      cameraOptions,
      globalLocation.display_name
    );
    const currentCamera = filteredOptions.find(
      camera => camera.label === globalCamera.display_name
    );
    if (!currentCamera) return false;
  }
  return true;
};
