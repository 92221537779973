// Libs
import isObject from 'lodash.isobject';
// Permissions
import { ROLE_PERMISSIONS } from '../permissions';

export const formatListData = (data, key, template) => {
  if (data && Array.isArray(data)) {
    if (key === 'user') return data.map(item => template(item.user));
    return data.map(item => template(item));
  }
  if (data && data[key]) return data[key].map(item => template(item));
  return [];
};

export const logsValuesArray = (data, list) => {
  return data.map(page => {
    if (isObject(page)) {
      const displayNameKey = Object.keys(list).find(
        pageKey => pageKey === page.name
      );
      const pageName = list[displayNameKey].name;

      const listOfActions = page.actions;

      let permissionName = 'View'; // Default permission
      if (
        ROLE_PERMISSIONS.delete.actions.every(action =>
          listOfActions.includes(action)
        )
      ) {
        permissionName = ROLE_PERMISSIONS.delete.name;
      } else if (
        ROLE_PERMISSIONS.create.actions.every(action =>
          listOfActions.includes(action)
        )
      ) {
        permissionName = ROLE_PERMISSIONS.create.name;
      } else if (
        ROLE_PERMISSIONS.update.actions.every(action =>
          listOfActions.includes(action)
        )
      ) {
        permissionName = ROLE_PERMISSIONS.update.name;
      }

      return `${pageName} (${permissionName})`;
    }
    return page;
  });
};
