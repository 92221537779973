import styled from 'styled-components';

export const sizeMap = {
  extraTiny: '6px',
  tiny: '10px',
  extraSmall: '14px',
  small: '16px',
  normal: '20px',
  medium: '24px',
  large: '36px',
  logo: '48px',
  config: '83px',
  failed: '124px',
  welcome: '374px',
};

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ size }) => sizeMap[size]};
  width: ${({ size }) => sizeMap[size]};

  & > svg {
    width: 100%;
    height: 100%;
    fill: ${({ fill }) => fill};
    stroke: ${({ stroke }) => stroke};
    &:hover {
      fill: ${({ fill, hoverColor }) => (fill ? hoverColor : '')};
      fill: ${({ stroke, hoverColor }) => (stroke ? hoverColor : '')};
    }
  }
`;
