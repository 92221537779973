/* eslint-disable object-shorthand */
/* eslint-disable prefer-destructuring */
import React, { memo, useState } from 'react';
import chunk from 'lodash.chunk';
import debounce from 'lodash.debounce';
import { Circle, Line, Text } from 'react-konva';
import {
  normalizePoint,
  distanceBetweenPoints,
  calculateGuidingLines,
  denormalizePoint,
  linkedPoints,
} from '../utils';

const Transform = memo(
  ({
    isRestrictModeOn,
    transformationPoints,
    polygonId,
    polygons,
    pointsList,
    stroke,
    offsetX,
    offsetY,
    setPolygons,
    setWasPointMoved,
    isStyledForZone,
    stageHeight,
    stageWidth,
    setIsAnythingMoving,
    imgRealWidth,
    imgRealHeigth,
    setContextMenu,
    setIsContextMenuOpen,
    showTransformPoints,
    setCursorCoordinates,
    cursorCoordinates,
    guidingLines,
    setGuidingLines,
    transformationPointRadius,
    isMagneticModeOn,
  }) => {
    const points = chunk(pointsList, 2);
    const [linkagePoints, setLinkagePoints] = useState([]);
    const [angles, setAngles] = useState([]);

    const handleRightClick = event => {
      const { x, y, index } = event.target.getAttrs();
      setContextMenu({
        x: x,
        y: y,
        isClickedOnPoint: true,
        linkedPolygons: linkedPoints(polygons, polygonId, index),
        polygonName: '',
        clickedPolygon: polygonId,
      });
      setIsContextMenuOpen(true);
      event.evt.preventDefault();
    };
    const debouncingOnDrag = debounce(event => onDrag(event), 3);
    const onDrag = event => {
      try {
        const lines = calculateGuidingLines(
          transformationPoints,
          stageHeight,
          stageWidth,
          imgRealHeigth,
          imgRealWidth,
          cursorCoordinates
        );
        setGuidingLines(lines);
        setIsAnythingMoving(true);
        setWasPointMoved(true);
        const { x, y } = event.target.getStage().getPointerPosition();
        const denormalizedX = Math.round((x * imgRealWidth) / stageWidth);
        const denormalizedY = Math.round((y * imgRealHeigth) / stageHeight);
        setCursorCoordinates({
          stageX: x,
          stageY: y,
          x: denormalizedX,
          y: denormalizedY,
        });
        setPolygons(prevState => {
          const state = [...prevState];
          const { width, height } = event.target.getStage().getAttrs();
          const { x, y, index: pointIndex } = event.target.getAttrs();
          const polygonIndex = state.findIndex(item => item.id === polygonId);
          const polygonPoints = state[polygonIndex].points;
          let [normalizeX, normalizeY] = normalizePoint(width, height, [x, y]);

          // move points only in canvas
          if (normalizeX >= 1) {
            event.target.x(width);
            normalizeX = 1;
          }
          if (normalizeX <= 0) {
            event.target.x(0);
            normalizeX = 0;
          }
          if (normalizeY >= 1) {
            event.target.y(height);
            normalizeY = 1;
          }
          if (normalizeY <= 0) {
            event.target.y(0);
            normalizeY = 0;
          }
          polygonPoints[pointIndex] = [normalizeX, normalizeY];
          const closestPoints = [];
          state.map(polygon => {
            polygon.points.map((point, pointIndex_) => {
              if (polygon.id === polygonId && pointIndex_ === pointIndex) {
                state[polygonIndex].isMoving[pointIndex] = true;
                // try{setAngles(getPointNeighboors(polygon, point, pointIndex_, points.length - 1))}
                // catch{/* somehow when points are so close points looks undefined */}
                // Move the linked transformation points
                if (polygon.linkedTo[pointIndex].length >= 1) {
                  for (
                    let index = 0;
                    index < polygon.linkedTo[pointIndex].length;
                    index++
                  ) {
                    const polygonToMove =
                      state[
                        state.findIndex(
                          i =>
                            i.id ===
                            polygon.linkedTo[pointIndex][index].polygonId
                        )
                      ];
                    polygonToMove.points[
                      polygon.linkedTo[pointIndex][index].pointIndex
                    ] = [...polygonPoints[pointIndex]];
                    polygonToMove.isMoving[
                      polygon.linkedTo[pointIndex][index].pointIndex
                    ] = true;
                  }
                }
              }

              const distance = distanceBetweenPoints(
                point[0],
                point[1],
                normalizeX,
                normalizeY
              );
              if (polygonId !== polygon.id && isMagneticModeOn) {
                if (distance <= 0.02) {
                  const [x1, y1] = denormalizePoint(stageWidth, stageHeight, [
                    point[0],
                    point[1],
                  ]);
                  const [x2, y2] = denormalizePoint(stageWidth, stageHeight, [
                    normalizeX,
                    normalizeY,
                  ]);
                  closestPoints.push({
                    dist: distance,
                    points: [x1, y1, x2, y2],
                    polygonId: polygon.id,
                    pointIndex: pointIndex_,
                  });
                }
              }
              return distance;
            });
            const sortedClosestPoints = closestPoints.sort(function sortPoints(
              a,
              b
            ) {
              return a.dist - b.dist;
            });
            if (sortedClosestPoints.length >= 1 && isMagneticModeOn) {
              setLinkagePoints(sortedClosestPoints[0].points);
            } else setLinkagePoints([]);
            return polygon;
          });
          return state;
        });
      } catch {
        /* */
      }
    };
    const debouncingDragEnd = debounce(event => onDragEnd(event), 3);
    const onDragEnd = event => {
      setIsAnythingMoving(false);
      setAngles([]);
      try {
        setPolygons(prevState => {
          const state = [...prevState];
          const { width, height } = event.target.getStage().getAttrs();
          const { x, y, index: pointIndex } = event.target.getAttrs();

          const polygonIndex = state.findIndex(item => item.id === polygonId);
          const polygonPoints = state[polygonIndex].points;
          let [normalizeX, normalizeY] = normalizePoint(width, height, [x, y]);

          // move points only in canvas
          if (normalizeX >= 1) {
            event.target.x(width);
            normalizeX = 1;
          }
          if (normalizeX <= 0) {
            event.target.x(0);
            normalizeX = 0;
          }
          if (normalizeY >= 1) {
            event.target.y(height);
            normalizeY = 1;
          }
          if (normalizeY <= 0) {
            event.target.y(0);
            normalizeY = 0;
          }
          const closestPoints = [];
          setLinkagePoints([]);
          state.map(polygon =>
            polygon.points.map((point, pointIndex_) => {
              polygon.isMoving[pointIndex_] = false;
              if (polygon.id !== polygonId) {
                const distance = distanceBetweenPoints(
                  point[0],
                  point[1],
                  normalizeX,
                  normalizeY
                );
                if (distance <= 0.02 && distance !== 0) {
                  closestPoints.push({
                    dist: distance,
                    polygonId: polygon.id,
                    pointIndex: pointIndex_,
                    points: [point[0], point[1]],
                  });
                }
              }
              return point;
            })
          );
          // restrictmode alignment
          if (isRestrictModeOn === true && guidingLines.length > 0) {
            const pointsToUpdate = [normalizeX, normalizeY];
            guidingLines.forEach(line => {
              if (line.isY) pointsToUpdate[0] = line.normPoint[0];
              else pointsToUpdate[1] = line.normPoint[1];
            });
            // update moved transformation point
            polygonPoints[pointIndex] = pointsToUpdate;
            // move linked points as well
            state.forEach((polygon, polyonIndex) => {
              polygon.linkedTo.forEach((linkedPoints, pointIndex_) => {
                linkedPoints.forEach(point => {
                  if (
                    point.polygonId === polygonId &&
                    point.pointIndex === pointIndex
                  ) {
                    state[polyonIndex].points[pointIndex_] = [
                      ...pointsToUpdate,
                    ];
                  }
                });
              });
            });
          }
          const sortedClosestPoints = closestPoints.sort(function sortPoints(
            a,
            b
          ) {
            return a.dist - b.dist;
          });
          if (sortedClosestPoints.length >= 1 && isMagneticModeOn) {
            // check wheter point is already linked
            if (
              state[
                state.findIndex(
                  polygon => polygon.id === sortedClosestPoints[0].polygonId
                )
              ].linkedTo[sortedClosestPoints[0].pointIndex].find(
                point => point.polygonId === polygonId
              ) === undefined
            ) {
              polygonPoints[pointIndex] = sortedClosestPoints[0].points;
              state[polygonIndex].linkedTo[pointIndex].push({
                polygonId: sortedClosestPoints[0].polygonId,
                pointIndex: sortedClosestPoints[0].pointIndex,
              });
              state[
                state.findIndex(i => i.id === sortedClosestPoints[0].polygonId)
              ].linkedTo[sortedClosestPoints[0].pointIndex].push({
                polygonId: polygonId,
                pointIndex: pointIndex,
              });
            }
            // add other polygon points if closest and other points overlay
            for (let i = 1; i < sortedClosestPoints.length; i++) {
              if (sortedClosestPoints[i].dist === sortedClosestPoints[0].dist)
                if (
                  state[
                    state.findIndex(
                      polygon => polygon.id === sortedClosestPoints[i].polygonId
                    )
                  ].linkedTo[sortedClosestPoints[i].pointIndex].find(
                    point => point.polygonId === polygonId
                  ) === undefined
                ) {
                  // check wheter point is already linked
                  state[polygonIndex].linkedTo[pointIndex].push({
                    polygonId: sortedClosestPoints[i].polygonId,
                    pointIndex: sortedClosestPoints[i].pointIndex,
                  });
                  state[
                    state.findIndex(
                      polygon => polygon.id === sortedClosestPoints[i].polygonId
                    )
                  ].linkedTo[sortedClosestPoints[i].pointIndex].push({
                    polygonId: polygonId,
                    pointIndex: pointIndex,
                  });
                }
            }
          }
          return state;
        });
      } catch {
        /* */
      }
    };
    const handleMouseEnter = event => {
      const container = event.target.getStage().container();
      container.style.cursor = 'grab';
    };

    const handleMouseLeave = event => {
      const container = event.target.getStage().container();
      container.style.cursor = 'crosshair';
    };
    return (
      <>
        <Line
          points={linkagePoints}
          fill='red'
          stroke={isStyledForZone ? 'black' : stroke}
          strokeWidth={isStyledForZone ? 2 : 5}
        />
        {angles.map(angle => {
          const points = polygons.filter(
            polygon => polygon.id === angle.polygonId
          )[0].points;
          const avgX =
            polygons
              .filter(polygon => polygon.id === angle.polygonId)[0]
              .points.filter((point, index) => index % 2 === 0)
              .reduce((partialSum, a) => partialSum + a, 0) /
            (points.length / 2);
          const avgY =
            polygons
              .filter(polygon => polygon.id === angle.polygonId)[0]
              .points.filter((point, index) => index % 2 === 1)
              .reduce((partialSum, a) => partialSum + a, 0) /
            (points.length / 2);

          return (
            <Text
              text={angle.angle}
              fontFamily='Montserrat'
              x={
                points[angle.pointIndex * 2] -
                (points[angle.pointIndex * 2] - avgX) / 2
              }
              y={
                points[angle.pointIndex * 2 + 1] -
                (points[angle.pointIndex * 2 + 1] - avgY) / 2
              }
              // x={avgX}
              // y={avgY}
              key={`${angle.polygonId}${angle.pointIndex}`}
              offsetY={20}
              fontSize='12'
            />
          );
        })}
        {points.map((point, index) => {
          let opacity = 0.5;
          let nOfNeighboors = 1;
          polygons.forEach(polygon => {
            polygon.linkedTo.forEach(points => {
              points.forEach(point => {
                if (point.polygonId === polygonId && point.pointIndex === index)
                  nOfNeighboors += nOfNeighboors;
              });
            });
          });
          opacity /= nOfNeighboors;
          return (
            <Circle
              key={`point${index}`}
              onContextMenu={handleRightClick}
              onDragMove={debouncingOnDrag}
              onDragEnd={debouncingDragEnd}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              index={index}
              draggable
              radius={showTransformPoints ? transformationPointRadius : 0}
              fill='black'
              stroke='black'
              opacity={opacity}
              x={point[0] + offsetX}
              y={point[1] + offsetY}
              strokeWidth={isStyledForZone ? 2 : 5}
            />
          );
        })}
      </>
    );
  }
);

export default Transform;
