import styled from 'styled-components';
import { BodyText } from '../fonts';

export const Wrapper = styled.div`
  display: flex;
  padding: 0 10px;
  align-items: center;
`;

export const StyledBodyText = styled(BodyText)`
  margin: 0 0 0 15px;
  width: 10px;
`;
