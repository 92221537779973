import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Redirect, Route, useRouteMatch } from 'react-router-dom';
import isEmpty from 'lodash.isempty';
import { useAppContext } from '../../AppContext';
import { routeService } from '../../routes/routesList';

const ProtectedRoute = props => {
  const { isAuthenticated, user } = useAuth0();
  const { userPages, userInfo } = useAppContext();
  const {
    list: { no_email_verified, no_access, camera_view_assist },
    getPath,
  } = routeService;
  const isTermPage = useRouteMatch('/terms')?.path === '/terms';
  const isOverlayCameraAssistPage =
    useRouteMatch('/app/overlay_tools/camera_view_assist')?.path ===
    '/app/overlay_tools/camera_view_assist';
  const noAccessRoute = getPath({ item: no_access });
  const noEmailVerifiedRoute = getPath({ item: no_email_verified });
  const installationManagementCameraViewAssist = getPath({
    item: camera_view_assist,
  });

  if (!isTermPage) {
    if (!isAuthenticated) {
      return <Redirect id='cbmnhujhad' to='/' />;
    }

    if (!user?.email_verified) {
      return <Redirect id='xcjiaixrkg' to={noEmailVerifiedRoute} />;
    }
    if (userInfo && isEmpty(userPages)) {
      return <Redirect id='ztysspzbvt' to={noAccessRoute} />;
    }
  }
  if (isOverlayCameraAssistPage) {
    return (
      <Redirect id='qagaqwhqhj' to={installationManagementCameraViewAssist} />
    );
  }
  return <Route id='hkdbxfxbtg' {...props} />;
};

export default ProtectedRoute;
