export const ITEMS_PER_PAGE = 8;

export const CAMERA_VIEW_OPTIONS = {
  ALL: {
    label: 'All',
    value: 'all',
  },
  INTERIOR: {
    label: 'Interior',
    value: 'interior',
  },
  EXTERIOR: {
    label: 'Exterior',
    value: 'exterior',
  },
};

export const DEFAULT_OPACITY = 0.5;

export const cameraAssistToolTip =
  "Camera Assist is an on-demand camera validation tool. It provides instructional arrows during installation and checks for camera's status when triggered.";

export const tooltip =
  'Camera View Assist is for active camera installation. Live image set as background, template image set as foreground';

export const CAMERA_OBJECTIVE_TOOLTIP =
  'Camera Objectives will be available after objectives are saved';
