export const isActionPermitted = (
  userPages = [],
  targetPageName,
  targetActions = []
) => {
  const currentUserPage = userPages.find(
    userPage => userPage.name === targetPageName
  );
  return targetActions.every(targetAction =>
    currentUserPage?.actions
      .map(currentAction => currentAction.name)
      .includes(targetAction)
  );
};

export const hasAccessToPage = (userPages = [], targetPageName) => {
  return userPages.map(page => page.name).includes(targetPageName);
};
