// Libs
import ReactModal from 'react-modal';
import styled from 'styled-components';
// Constants
import { COLORS } from '../colors';

export const DialogModal = styled(ReactModal)`
  outline: none;
`;

export const DialogContent = styled.div`
  display: block;
  position: absolute;

  width: ${props => {
    switch (props.size) {
      case 'huge':
        return '1306px';
      case 'radius_ipad':
        return '80%';
      case 'xxlarge':
        return '950px';
      case 'xlarge':
        return '848px';
      case 'largest':
        return '720px';
      case 'large':
        return '656px';
      case 'small':
        return '362px';
      case 'medium':
        return '400px';
      default:
        return '362px';
    }
  }};
  max-width: ${props => {
    return props.maxSize;
  }};
  height: auto;

  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;

  margin-right: -50%;
  transform: translate(-50%, -50%);
  z-index: 1000;

  background-color: ${COLORS.White};
  box-shadow: 0 10px 18px rgba(9, 30, 66, 0.15), 0 0 1px rgba(9, 30, 66, 0.31);
  border-radius: 8px;
`;

export const CloseArea = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
`;

export const DialogHeader = styled.header`
  padding: 32px 32px 0 32px;
`;

export const DialogBody = styled.div`
  padding: 0 16px;
  margin-top: 16px;
  margin-bottom: 16px;
  min-width: 80%;
`;

export const ScrolledContent = styled.div`
  max-height: 560px;
  display: flex;
  flex-wrap: wrap;
  overflow: ${props => props.overflow || 'auto'};
  padding: 2px 16px;

  @media (max-height: 750px) {
    & {
      max-height: 370px;
    }
  }

  @media (max-height: 590px) {
    & {
      max-height: 250px;
    }
  }

  @media (max-height: 470px) {
    & {
      max-height: 160px;
    }
  }

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    outline: 1px solid slategrey;
    background: #e5e5e5;
    border-radius: 4px;
  }
`;

export const DialogFooter = styled.footer`
  display: flex;
  justify-content: flex-end;
  padding: 22px 32px;
  border-top: 1px solid ${COLORS.SeparatorGray};

  & Button {
    margin-left: 16px;
  }
`;

export const DialogScrollBar = styled.footer`
  width: 100%;
  color: #666;

  & div {
    border-top: 1px solid #eee;
    padding: 3px 0 3px 15px;
    font-weight: bold;
  }
`;
