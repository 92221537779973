import { routeService } from './routesList';

const {
  list: {
    plane_and_normal,
    detection_polygon,
    aoi_selection,
    floor_projection,
    plane_calibration,
  },
} = routeService;

export const RULES = [
  // First exclude then include
  // e.g. excludeCameraPrefix: ['checkstandtwo'] -> returns every cam but checkstandtwo ->
  // e.g. includeCameraPrefix: ['checkstand'] -> considering there is only checkstandone that 'checkstand' is included
  // e.g. only return value will be checkstand
  // If store has a special rule like store 56 ATM this is the workflow
  // * Look for overrideForStore and apply the same stuff on the above
  {
    path: aoi_selection.path,
    includeCameraPrefix: ['checkstand'],
    excludeCameraPrefix: [],
    overrideForStore: [
      {
        store: 'Store 0056', // display name
        includeCameraPrefix: ['checkstand', 'coolersone'],
        excludeCameraPrefix: [],
      },
      {
        store: 'Store 0766', // display name
        includeCameraPrefix: ['checkstand', 'coolersone'],
        excludeCameraPrefix: [],
      },
    ],
  },
  {
    path: floor_projection.path,
    includeCameraPrefix: ['checkstand'],
    excludeCameraPrefix: [],
    overrideForStore: [
      {
        store: 'Store 0056', // display name
        includeCameraPrefix: ['checkstand', 'coolersone'],
        excludeCameraPrefix: [],
      },
    ],
  },
  {
    path: plane_calibration.path,
    includeCameraPrefix: [],
    excludeCameraPrefix: ['EXT', 'restroomhall'],
  },
  {
    path: plane_and_normal.path,
    includeCameraPrefix: [],
    excludeCameraPrefix: ['EXT', 'restroomhall'],
  },
  {
    path: detection_polygon.path,
    includeCameraPrefix: [],
    excludeCameraPrefix: ['restroomhall'],
  },
];
