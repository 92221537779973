import React from 'react';
import { Layer, Stage, Arrow } from 'react-konva';

const ArrowStage = ({ stageWidth, stageHeight, mkpts0, mkpts1, colors }) => {
  const RES_X = 1920;
  const RES_Y = 1080;
  const SCALE_X = RES_X / stageWidth;
  const SCALE_Y = RES_Y / stageHeight;

  return (
    <Stage id='yktzrlgwwx' width={stageWidth} height={stageHeight}>
      <Layer id='pqboskkhzs'>
        {mkpts0?.map((point, index) => {
          const arrows = [];
          const pts00 = parseFloat(point[0]) / SCALE_X;
          const pts01 = parseFloat(point[1]) / SCALE_Y;
          const pts10 = parseFloat(mkpts1[index][0]) / SCALE_X;
          const pts11 = parseFloat(mkpts1[index][1]) / SCALE_Y;
          const arrow = (
            <Arrow
              id='jwfgpbokmt'
              points={[pts00, pts01, pts10, pts11]}
              stroke={colors ? colors[index] : 'red'} // Remove red after erasing dynamodb old data
              fill={colors ? colors[index] : 'red'} // Remove red after erasing dynamodb old data
              strokeWidth={5}
              pointerWidth={5}
              key={index}
            />
          );
          if (Math.random() >= 0.825) {
            arrows.push(arrow);
          }
          return mkpts0 !== undefined && mkpts1 !== undefined && arrows;
        })}
      </Layer>
    </Stage>
  );
};

export default ArrowStage;
