/* eslint-disable object-shorthand */
import React, { Fragment } from 'react';
import { Line, Text } from 'react-konva';
import { hexToRgb } from '../utils';
import Transform from './Transform';

const Lines = ({
  isMagneticModeOn,
  setGuidingLines,
  polygons,
  isNearPoint,
  onDragMove,
  isPolygonDrawing,
  setPolygons,
  transformationPoints,
  setIsPolygonDrawing,
  setAreActionsDisabled,
  transform,
  setWasPointMoved,
  isUserActionPermitted,
  userActionAllowed,
  isStyledForZone = true,
  stageWidth,
  stageHeight,
  isAnythingMoving,
  setIsAnythingMoving,
  setIsDisableLines,
  isDisableLines,
  setContextMenu,
  setIsContextMenuOpen,
  imgRealWidth,
  imgRealHeigth,
  showTransformPoints,
  setCursorCoordinates,
  transformationPointRadius,
  isRestrictModeOn,
  cursorCoordinates,
  guidingLines,
  onDragEnd,
  showZoneLabels,
}) => {
  const getLineActions = id => {
    if (isUserActionPermitted) {
      return {
        onDragMove: e => onDragMove(id, e),
        onDragEnd: e => onDragEnd(id, e),
        onMouseEnter: handleMouseEnter,
        onMouseLeave: handleMouseLeave,
        onContextMenu: e => handleRightClick(id, e),
      };
    }
  };

  const handleRightClick = (id, e) => {
    const clickedPolygon = polygons.find(polygon => polygon.id === id);
    const clickedPolygonName = clickedPolygon.name;
    if (!isPolygonDrawing) {
      const { x, y } = e.target.getStage().getPointerPosition();
      setContextMenu({
        x: x,
        y: y,
        clickedPolygon: clickedPolygon,
        isClickedOnPoint: false,
        polygonName: clickedPolygonName,
      });
      setIsContextMenuOpen(true);
      e.evt.preventDefault();
    }
  };

  const handleMouseEnter = event => {
    const container = event.target.getStage().container();
    container.style.cursor = 'pointer';
  };

  const handleMouseLeave = event => {
    const container = event.target.getStage().container();
    container.style.cursor = 'crosshair';
  };

  const calculateTextPosition = (points, name) => {
    let minX = Number.POSITIVE_INFINITY;
    let maxX = Number.NEGATIVE_INFINITY;
    let minY = Number.POSITIVE_INFINITY;
    let maxY = Number.NEGATIVE_INFINITY;

    for (let i = 0; i < points.length; i += 2) {
      const x = points[i];
      const y = points[i + 1];

      // Update min and max values for x and y
      minX = Math.min(minX, x);
      maxX = Math.max(maxX, x);
      minY = Math.min(minY, y);
      maxY = Math.max(maxY, y);
    }

    const textX = (maxX - minX) / 2 - (name.length * 10) / 2 + minX;
    const textY = (maxY - minY) / 2 - 16 / 2 + minY;

    return { textX, textY };
  };

  return (
    <>
      {polygons.map(polygon => {
        const { id, x, y, points, isClosed, color, offsetX, offsetY, name } =
          polygon;
        const { textX, textY } = calculateTextPosition(points, name);
        const { red, green, blue } = hexToRgb(color);
        const stroke = `rgba(${red},${green},${blue}, 1)`;
        const fill = `rgba(${red},${green},${blue}, 0.4)`;
        if (!polygon.isInaccessible)
          return (
            <Fragment key={id}>
              {!polygon.isInaccessible &&
                !polygon.isEmployeeOnly &&
                showZoneLabels && (
                  <Text
                    id='sfegffnhb'
                    text={name}
                    fontFamily='Montserrat'
                    x={textX}
                    y={textY}
                    fontStyle='bold'
                    fontSize='16'
                  />
                )}
              <Line
                {...getLineActions(id)}
                lineCap='round'
                x={x + offsetX}
                y={y + offsetY}
                points={points}
                closed={isNearPoint && isMagneticModeOn ? 'true' : isClosed}
                draggable={!isPolygonDrawing && userActionAllowed}
                fill={polygon.isInaccessible ? 'rgba(83, 83, 83, 0.6)' : fill}
                zIndex={polygon.isInaccessible ? 2 : 1}
                stroke={isPolygonDrawing || isAnythingMoving ? 'black' : ''}
                strokeWidth={1}
              />
              {isClosed && transform && userActionAllowed && (
                <Transform
                  polygonId={id}
                  transformationPointRadius={transformationPointRadius}
                  polygons={polygons}
                  setPolygons={setPolygons}
                  pointsList={points}
                  offsetY={offsetY}
                  offsetX={offsetX}
                  stroke={stroke}
                  setIsPolygonDrawing={setIsPolygonDrawing}
                  setAreActionsDisabled={setAreActionsDisabled}
                  draggable={isUserActionPermitted}
                  setWasPointMoved={setWasPointMoved}
                  isStyledForZone={isStyledForZone}
                  stageWidth={stageWidth}
                  stageHeight={stageHeight}
                  setIsDisableLines={setIsDisableLines}
                  isDisableLines={isDisableLines}
                  setIsAnythingMoving={setIsAnythingMoving}
                  setContextMenu={setContextMenu}
                  setIsContextMenuOpen={setIsContextMenuOpen}
                  showTransformPoints={showTransformPoints}
                  setCursorCoordinates={setCursorCoordinates}
                  imgRealWidth={imgRealWidth}
                  imgRealHeigth={imgRealHeigth}
                  isRestrictModeOn={isRestrictModeOn}
                  guidingLines={guidingLines}
                  transformationPoints={transformationPoints}
                  cursorCoordinates={cursorCoordinates}
                  setGuidingLines={setGuidingLines}
                  isMagneticModeOn={isMagneticModeOn}
                />
              )}
            </Fragment>
          );
        return <Line key={id} />;
      })}
      {polygons.map(polygon => {
        const { id, x, y, points, isClosed, color, offsetX, offsetY } = polygon;
        const { red, green, blue } = hexToRgb(color);
        const stroke = `rgba(${red},${green},${blue}, 1)`;
        const fill = `rgba(${red},${green},${blue}, 0.4)`;
        if (polygon.isInaccessible)
          return (
            <Fragment key={id}>
              <Line
                {...getLineActions(id)}
                lineCap='round'
                x={x + offsetX}
                y={y + offsetY}
                points={points}
                closed={isClosed}
                draggable={!isPolygonDrawing && userActionAllowed}
                fill={polygon.isInaccessible ? 'rgba(83, 83, 83, 0.6)' : fill}
                zIndex={polygon.isInaccessible ? 2 : 1}
                stroke={isPolygonDrawing || isAnythingMoving ? 'black' : ''}
                strokeWidth={1}
              />
              {isClosed && transform && userActionAllowed && (
                <Transform
                  polygonId={id}
                  transformationPointRadius={transformationPointRadius}
                  polygons={polygons}
                  setPolygons={setPolygons}
                  pointsList={points}
                  offsetY={offsetY}
                  offsetX={offsetX}
                  stroke={stroke}
                  setIsPolygonDrawing={setIsPolygonDrawing}
                  setAreActionsDisabled={setAreActionsDisabled}
                  draggable={isUserActionPermitted}
                  setWasPointMoved={setWasPointMoved}
                  isStyledForZone={isStyledForZone}
                  stageWidth={stageWidth}
                  stageHeight={stageHeight}
                  setIsDisableLines={setIsDisableLines}
                  isDisableLines={isDisableLines}
                  setIsAnythingMoving={setIsAnythingMoving}
                  setContextMenu={setContextMenu}
                  setIsContextMenuOpen={setIsContextMenuOpen}
                  showTransformPoints={showTransformPoints}
                  setCursorCoordinates={setCursorCoordinates}
                  imgRealWidth={imgRealWidth}
                  imgRealHeigth={imgRealHeigth}
                  isRestrictModeOn={isRestrictModeOn}
                  guidingLines={guidingLines}
                  transformationPoints={transformationPoints}
                  cursorCoordinates={cursorCoordinates}
                  setGuidingLines={setGuidingLines}
                />
              )}
            </Fragment>
          );
        return <Line key={id} />;
      })}
    </>
  );
};

export default Lines;
