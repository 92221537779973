import React, { createContext, useContext, useEffect, useState } from 'react';
import { useModal } from '../../hooks/useModal';
// eslint-disable-next-line import/no-cycle
import ReloadModal from './ReloadModal';
import { useAppContext } from '../../AppContext';

const ReloadContext = createContext();

export const useReload = () => {
  return useContext(ReloadContext);
};

const ReloadWrapper = ({ children }) => {
  const { isOpen, open, close } = useModal();
  const [handleClose, setHandleClose] = useState();
  const [shouldModal, setShouldModal] = useState(false);
  const { globalLocation, userInfo } = useAppContext();
  const onClose = () => {
    handleClose();
    close();
    setTimeout(() => setShouldModal(false));
  };
  const beforeUnloadListener = event => {
    event.preventDefault();
    event.returnValue =
      'You have unsaved changes! Are you sure you want to exit?';
    return event.returnValue;
  };

  useEffect(() => {
    setShouldModal(false);
  }, [userInfo]);

  useEffect(() => {
    if (shouldModal) {
      window.addEventListener('beforeunload', beforeUnloadListener, {
        capture: true,
      });
    } else
      window.removeEventListener('beforeunload', beforeUnloadListener, {
        capture: true,
      });
    return () =>
      window.removeEventListener('beforeunload', beforeUnloadListener, {
        capture: true,
      });
  }, [shouldModal]);

  useEffect(() => {
    setShouldModal(false);
  }, [globalLocation]);

  const handleUnsavedClose = onCloseHandler => {
    if (shouldModal) {
      setHandleClose(() => onCloseHandler);
      open();
    } else {
      onCloseHandler();
    }
  };

  return (
    <ReloadContext.Provider
      id='rhzdcbobkp'
      value={{
        handleUnsavedClose,
        setShouldModal,
        shouldModal,
      }}
    >
      {children}
      <ReloadModal
        id='tihqgzgsge'
        onClose={close}
        isOpen={isOpen}
        onContinue={onClose}
      />
    </ReloadContext.Provider>
  );
};

export default ReloadWrapper;
