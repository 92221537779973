// File: sidebar.jsx
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import AnimateHeight from 'react-animate-height';
import isEmpty from 'lodash.isempty';
import {
  IconBlockPointer,
  SidebarBody,
  SidebarContainer,
  SidebarFooter,
  SidebarWrapper,
  SidebarVersion,
} from './Sidebar.style';
import { routeService } from '../../routes/routesList';
// eslint-disable-next-line import/no-cycle
import SidebarLink from './SidebarLink';
import SidebarSubLink from './SidebarSubLink';
import { useAppContext } from '../../AppContext';
// eslint-disable-next-line import/no-cycle
import { useReload } from '../reloadModal';
import { hasAccessToPage } from '../../permissions/utils';
import { SIDEBAR_PAGES } from './constants';
import { compareLinks } from '../../utils/compareLinks';
import { Icon } from '../iconComponent/Icon';
import { COLORS } from '../colors';
import { canSwitchLocation } from '../header/utils';

const Sidebar = () => {
  const history = useHistory();

  const { getPath, list } = routeService;

  const {
    userPages: loggedinUserPages,
    isSidebarOpened,
    setIsSidebarOpened,
    globalLocation,
    globalCamera,
    cameraOptions,
    setWrongCameraDialog,
    masterFilter: mfToggle,
    setLocalStorage,
    setServerType,
  } = useAppContext();

  const { handleUnsavedClose } = useReload();
  const location = useLocation();
  const currentPath = location?.pathname;
  const [state, setState] = useState({});
  const [stateBackup, setStateBackup] = useState({});

  const openParentTree = (state, key) => {
    if (key) {
      const topParent = list.app.name;
      if (list[key].name === topParent) return;
      state[key] = {
        ...list[key],
        isOpen: true,
        isActive: true,
      };
      openParentTree(state, list[key].parent);
    }
  };

  useEffect(() => {
    const initState = {};
    let selectedPage;
    Object.keys(list).forEach(key => {
      const isEqualToCurrentPath = compareLinks(
        currentPath,
        getPath({ item: list[key] })
      );
      initState[key] = {
        ...list[key],
        isOpen: isEqualToCurrentPath,
        isActive: isEqualToCurrentPath,
      };
      const parentKey = list[key]?.parent;
      if (parentKey && isEqualToCurrentPath) {
        selectedPage = key;
      }
    });
    openParentTree(initState, list[selectedPage]?.parent);
    setState(initState);

    setServerType('va1'); // reset to va1 when changing pages
  }, [location]);

  const handleItemClick = item => {
    const itemKey = item.path;
    const { isRedirected } = state[itemKey];

    if (isRedirected) {
      setLocalStorage(
        'master_filter',
        `${mfToggle}_path`, // VA_path, SA_path
        getPath({ item: list[itemKey] })
      );

      const pathRoute = getPath({ item: state[itemKey] });
      if (pathRoute !== currentPath) {
        handleUnsavedClose(() => {
          const canSwitch = canSwitchLocation(
            cameraOptions,
            globalCamera,
            globalLocation,
            pathRoute
          );
          if (canSwitch) history.push(pathRoute);
          else
            setWrongCameraDialog({
              isOpen: true,
              camera: globalCamera.display_name,
              tool: item.name,
              isOpenedFromFloorplan: false,
              switchTo: pathRoute,
            });
        });
      }
      // force switch to selectedComponent when clicked item is same as before
      else history.push(pathRoute);
    } else {
      let copyItems;
      if (!isSidebarOpened) {
        copyItems = {
          ...stateBackup,
        };
        setIsSidebarOpened(!isSidebarOpened);
      } else {
        copyItems = {
          ...state,
        };
      }
      copyItems[itemKey].isOpen = !state[itemKey].isOpen;
      setState(copyItems);
    }
  };

  const closeAllItems = items => {
    const cloneItems = {};
    Object.keys(items).forEach(key => {
      cloneItems[key] = {
        ...items[key],
        isOpen: false,
      };
    });
    return cloneItems;
  };

  const handleSidebarToggleClick = () => {
    if (isSidebarOpened) {
      setStateBackup(state);
      setState(closeAllItems(state));
    } else {
      setState(stateBackup);
    }
    setIsSidebarOpened(!isSidebarOpened);
  };

  if (isEmpty(state)) {
    return null;
  }
  return (
    <SidebarContainer id='rxmnjeeutf' isOpen={isSidebarOpened}>
      <SidebarWrapper id='mwmvyhiedu' isOpen={isSidebarOpened}>
        <SidebarBody id='nfdrjzmjnz'>
          {SIDEBAR_PAGES.map(({ pageName, subPages, masterFilter }) => {
            if (
              (hasAccessToPage(loggedinUserPages, pageName) &&
                masterFilter?.includes(mfToggle)) ||
              (subPages?.some(subPage =>
                hasAccessToPage(loggedinUserPages, subPage.pageName)
              ) &&
                masterFilter?.includes(mfToggle))
            ) {
              return (
                <SidebarLink
                  id='vabfjmhtsu'
                  item={state[pageName]}
                  key={pageName}
                  onClick={handleItemClick}
                >
                  {subPages && (
                    <AnimateHeight
                      id='nhfsnoklws'
                      duration={300}
                      height={state[pageName]?.isOpen ? 'auto' : 0}
                    >
                      {subPages.map(subPage => {
                        if (
                          hasAccessToPage(
                            loggedinUserPages,
                            subPage.pageName
                          ) &&
                          subPage?.masterFilter?.includes(mfToggle)
                        ) {
                          return (
                            <SidebarSubLink
                              id='ccswxqgouk'
                              key={subPage.pageName}
                              item={state[subPage.pageName]}
                              onClick={handleItemClick}
                            />
                          );
                        }
                        return null;
                      })}
                    </AnimateHeight>
                  )}
                </SidebarLink>
              );
            }
            return null;
          })}
          <SidebarLink
            id='cuzvakrfrb'
            item={state?.help}
            onClick={handleItemClick}
          >
            <AnimateHeight
              id='jqjdjyjbtp'
              duration={300}
              height={state.help?.isOpen ? 'auto' : 0}
            >
              <SidebarSubLink
                id='tctcpdqcio'
                item={state.info_center}
                onClick={handleItemClick}
              />
            </AnimateHeight>
          </SidebarLink>
        </SidebarBody>
        <SidebarFooter id='whybpqbctu'>
          <IconBlockPointer
            id='khlnesqpwj'
            degree={isSidebarOpened ? '0' : '-180'}
          >
            <Icon
              id='xahmamfqob'
              size='small'
              iconName='ToggleSidebarIcon'
              fill={COLORS.TextBlack}
              hoverColor={COLORS.MainBlue}
              onClick={handleSidebarToggleClick}
            />
          </IconBlockPointer>
          {isSidebarOpened && (
            <SidebarVersion>
              {(process.env.REACT_APP_VERSION || '') + process.env.NODE_ENV ===
              'development'
                ? 'CENTRAL-39@next'
                : ''}
              {''}
              {/*:222: */}
              {/* process.env.REACT_APP_VERSION || ''}  */}
            </SidebarVersion>
          )}
        </SidebarFooter>
      </SidebarWrapper>
    </SidebarContainer>
  );
};

export default Sidebar;
