// Libs
import '@formatjs/intl-numberformat/polyfill';
import '@formatjs/intl-numberformat/locale-data/en.js';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { QueryClientProvider, QueryClient } from 'react-query';
// eslint-disable-next-line import/no-unresolved
import { LicenseInfo } from '@mui/x-license-pro';
// Context
import { AppProvider } from './AppContext';
// App
import App from './App';
// Auto
import Auth0ProviderWithHistory from './auth/Auth0Provider';
// Components
import ReloadWrapper from './components/reloadModal';

const client = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      //it will be reset inside the AppContext in case if we will get jwt token
      //what we sent in each request in the Headers Authorization
      enabled: false,
    },
  },
});

LicenseInfo.setLicenseKey(
  'ccbf09ffa355984b32eccfaed37581c5Tz01MjczOCxFPTE2OTc3NTQ3NjEyNjYsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
);

const renderVersion = () => {
  const data = {
    version: process.env.REACT_APP_VERSION || 'undefined',
    commit_id: process.env.REACT_APP_COMMIT_ID || 'undefined',
    branch: process.env.REACT_APP_BRANCH || 'undefined',
  };
  return <pre>{JSON.stringify(data, null, 2)}</pre>;
};

ReactDOM.render(
  <Router id='qxvdcvltci'>
    {window.location.pathname === '/version' ? (
      renderVersion()
    ) : (
      <Auth0ProviderWithHistory id='bjahjbsoct'>
        <QueryClientProvider id='ubnapigdfa' client={client}>
          <AppProvider id='baxtbbxjxv'>
            <ReloadWrapper id='ylrcidiazt'>
              <App id='hcsdyqhgwj' />
            </ReloadWrapper>
          </AppProvider>
        </QueryClientProvider>
      </Auth0ProviderWithHistory>
    )}
  </Router>,
  document.getElementById('root')
);
