import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import Lottie from 'react-lottie';
import { FlexContainer } from '../flexContainer';
import { FlexItem } from '../flexItem';
import animationData from './loader-lottie.json';
import { LoaderAlt } from './styles';

const Loader = forwardRef(function Loader(
  // eslint-disable-next-line no-unused-vars
  { alt = 'Loading', size, useBackdrop, className, height = '100%', ...rest },
  ref
) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
    eventListeners: null,
  };

  return (
    <FlexContainer
      id='nrubhwmilq'
      ref={ref}
      height={height}
      direction='column'
      justify='center'
      align='center'
      fluid
    >
      <FlexItem id='jbdusvjbpy'>
        <Lottie
          id='voxmjshisx'
          options={defaultOptions}
          height={size}
          width={size}
        />
      </FlexItem>
      {alt && (
        <FlexItem id='hqmzpwpljz'>
          <LoaderAlt id='hfvupjysoa'>{alt}</LoaderAlt>
        </FlexItem>
      )}
    </FlexContainer>
  );
});

Loader.propTypes = {
  alt: PropTypes.string.isRequired,
  className: PropTypes.string,
  size: PropTypes.number,
  useBackdrop: PropTypes.bool,
};

Loader.defaultProps = {
  className: '',
  size: 24,
  useBackdrop: false,
};

export default Loader;
