// Libs
import styled from 'styled-components';

export const BoldInputLabel = styled.label`
  font-weight: bold;
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
