// Libs
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import cookie from 'react-cookies';
import { useAuth0 } from '@auth0/auth0-react';
import { useQuery, useQueryClient } from 'react-query';
import dateFormat from 'dateformat';
import { routeService } from './routes/routesList';

// APIs
import apiFetch, { api } from './api/fetcher';
import { QUERY_KEYS } from './api/fetchHooks/QUERY_KEYS';
import { API_URL } from './api/apiURLs';
import { useFetchCamerasByLocation } from './api/fetchHooks/camera';
import { useFetchPagesActions } from './api/fetchHooks/roles';
import { useProxyQuery } from './api/fetchHooks/useProxyQuery';
import { useFetchLocationPipelineStatus } from './api/fetchHooks/locations';

// Constants
import {
  DEFAULT_OBJECTIVES_SELECTS,
  COOKIE_COMPANY_ID,
  COOKIE_MASTER_FILTER,
  RAI_CENTRAL_CACHE,
  USER_TYPE,
} from './constants';
import { PipelineSign } from './components/pipelineSign';
import { COLLAPSE_ACTIONS } from './components/floorPlan/constants';
import { CAMERA_VIEW_OPTIONS } from './pages/overlayTools/cameraViewAssist/constants';

function setLocalStorage(main_key, key, value) {
  const existingDataString = localStorage.getItem(RAI_CENTRAL_CACHE);
  const existingData = existingDataString ? JSON.parse(existingDataString) : {};
  existingData[main_key] = existingData[main_key] || {};
  existingData[main_key][key] = value;
  const updatedDataString = JSON.stringify(existingData);
  localStorage.setItem(RAI_CENTRAL_CACHE, updatedDataString);
}

function getLocalStorage(main_key, key) {
  const existingDataString = localStorage.getItem(RAI_CENTRAL_CACHE);
  const existingData = existingDataString ? JSON.parse(existingDataString) : {};
  return existingData[main_key]?.[key];
}

function removeLocalStorage(main_key, key) {
  const existingDataString = localStorage.getItem(RAI_CENTRAL_CACHE);
  const existingData = existingDataString ? JSON.parse(existingDataString) : {};
  if (key) {
    if (existingData[main_key] && existingData[main_key][key]) {
      delete existingData[main_key][key];
    }
  } else {
    delete existingData[main_key];
  }
  const updatedDataString = JSON.stringify(existingData);
  localStorage.setItem(RAI_CENTRAL_CACHE, updatedDataString);
}

function removeLocalStorageCacheKey() {
  localStorage.removeItem(RAI_CENTRAL_CACHE);
}

export const AppContext = createContext();

export const AppProvider = ({ children, ...rest }) => {
  const history = useHistory();
  const location = useLocation();
  const currentPage = location.pathname;
  const queryClient = useQueryClient();

  const { getIdTokenClaims, user } = useAuth0();
  const [stopInitCall, setStopInitCall] = useState(false); // use set func. at isError callbacks
  const [auth0AccessToken, setAuth0AccessToken] = useState();
  const [jwtToken, setJwtToken] = useState();
  const [userInfo, setUserInfo] = useState();
  const [termsStatus, setTermsStatus] = useState(false);
  const [isInternalRole, setInternalRole] = useState(false);
  const [isReadOnlyRole, setIsReadOnlyRole] = useState(false);
  const [company, setCompany] = useState();
  const [serverType, setServerType] = useState('va1');

  /* Transporting variables or objects from to another component  */
  const [swapPool, setSwapPool] = useState(null);

  /* Location what was selected in the header dropdown */
  const [globalLocation, setGlobalLocation] = useState();
  /* All locations what user has access to*/
  const [userLocations, setUserLocations] = useState();
  /* State to manage camera selection to*/
  const [cameraOptions, setCameraOptions] = useState();
  /* State to manage camera selection to*/
  const [locationOptions, setLocationOptions] = useState();

  /* Camera what was selected in the header dropdown */
  const [globalCamera, setGlobalCamera] = useState();
  const [globalCameraPipelineStatus, setGlobalCameraPipelineStatus] =
    useState();
  /* All cameras that user has access to*/
  const [userCameras, setUserCameras] = useState();
  /* Open up a dialog when camera selection is wrong based on location to*/
  const [wrongCameraDialog, setWrongCameraDialog] = useState(false);
  // For getting page id according to the page name and vise versa
  const [pageIdMap, setPageIdMap] = useState();
  // For getting action id according to the action name and vise versa
  const [actionIdMap, setActionIdMap] = useState();
  const [pagePermission, setPagePermission] = useState({});

  /* State to manage home page items */
  const [masterFilter, setMasterFilter] = useState('VA'); // 'SA', 'VA'
  const [hasPermissionForShopAssist, setHasPermissionForShopAssist] =
    useState(false);

  /* Camera Objectives selects values,
   * they come from Template Management
   * and use in the Camera Objectives page */
  const [cameraObjectivesSelects, setCameraObjectivesSelects] = useState(
    DEFAULT_OBJECTIVES_SELECTS
  );

  //SIDEBAR
  const [isSidebarOpened, setIsSidebarOpened] = useState(true);

  //NOTIFICATION
  const [activeNotify, setActiveNotify] = useState(null);

  //FLOORPLAN
  const [selectedComponent, setSelectedComponent] = useState(null);
  const [activeButton, setActiveButton] = useState('INT');
  const [cameraView, setCameraView] = useState(CAMERA_VIEW_OPTIONS.INTERIOR);
  const [userAction, setUserAction] = useState(
    COLLAPSE_ACTIONS.USER_NOT_INTERACTED
  );
  const [isCollapsed, setIsCollapsed] = useState(false);

  const {
    list: { select_page_panel },
    getPath,
  } = routeService;

  const token = window.Cypress
    ? window.localStorage.getItem('id_token')
    : auth0AccessToken;

  const { data: pipelineStatusData } = useFetchLocationPipelineStatus(
    globalLocation?.id,
    { enabled: !!globalLocation?.id && isInternalRole }
  );

  const findPipelineStatusForGivenCameraName = cameraName => {
    let status = null;
    pipelineStatusData?.data.forEach(element => {
      if (element.pipeline?.name.includes(cameraName))
        status = element.pipeline?.status;
    });
    let statusSign = null;
    if (status === 'healthy')
      statusSign = (
        <PipelineSign id='zegrmirhvo' variant='healthy' size='small'>
          HEALTHY
        </PipelineSign>
      );
    else if (status === 'Not Running')
      statusSign = (
        <PipelineSign id='znyhymoglg' variant='unhealthy' size='small'>
          NOT RUNNING
        </PipelineSign>
      );
    else if (status === 'not setup')
      statusSign = (
        <PipelineSign id='uiydxqxujl' variant='notsetup' size='small'>
          NOT SETUP
        </PipelineSign>
      );
    else if (status === 'unhealthy')
      statusSign = (
        <PipelineSign
          id='ngbnpanlgd'
          title='Camera Pipeline Status'
          variant='unhealthy'
          size='small'
        >
          UNHEALTHY
        </PipelineSign>
      );
    else if (status === 'restarting')
      // Live image not seen
      statusSign = (
        <PipelineSign
          id='ulbhjkeygu'
          title='Camera Pipeline Status'
          variant='unhealthy'
          size='small'
        >
          RESTARTING
        </PipelineSign>
      );
    else {
      let _txt = 'UNKNOWN';
      if (!isInternalRole) _txt = 'NO PERMISSION';
      statusSign = (
        <PipelineSign
          id='jtbegikfzb'
          title='Camera Pipeline Status'
          variant='unhealthy'
          size='small'
        >
          {_txt}
        </PipelineSign>
      );
    }

    return { sign: statusSign, status };
  };

  // Delete companyId from cookie on reload page
  useEffect(() => {
    cookie.remove(COOKIE_COMPANY_ID, { path: '/' });
    cookie.remove(COOKIE_MASTER_FILTER, { path: '/' });
  }, []);

  useEffect(() => {
    if (company !== undefined) {
      cookie.save(COOKIE_COMPANY_ID, company.id, { path: '/' });
      queryClient.invalidateQueries(QUERY_KEYS.USER_INFO);
      queryClient.invalidateQueries(QUERY_KEYS.GLOBAL_LOCATION);
      queryClient.invalidateQueries(QUERY_KEYS.COMPANY_ROLES);
      queryClient.invalidateQueries(QUERY_KEYS.SERVERS);
      queryClient.invalidateQueries(QUERY_KEYS.PLANS);
      queryClient.invalidateQueries(QUERY_KEYS.ACCESS_KEYS);
    }
  }, [company]);

  useEffect(() => {
    const isGlobalLocationFound_inUserLocations = userLocations?.some(
      location => location.id === globalLocation?.id
    );
    if (!isGlobalLocationFound_inUserLocations && masterFilter === 'SA') {
      setGlobalLocation(undefined); // get the first SA location if the Camera at VA is not found in the SA user locations.
    }
  }, [userLocations, globalLocation, masterFilter]);

  useEffect(() => {
    if (masterFilter !== undefined) {
      cookie.save(COOKIE_MASTER_FILTER, masterFilter, { path: '/' });
      setImmediate(() => {
        // 0 timeout is used, otherwise Master Filter Toggle Button is responding late!
        queryClient.invalidateQueries(QUERY_KEYS.USER_INFO); // Triggers the "init"
        queryClient.invalidateQueries(QUERY_KEYS.LOCATION); // Triggers the "/locations/list"
        queryClient.invalidateQueries(QUERY_KEYS.GLOBAL_LOCATION); // Triggers the "/locations/all-user"
        queryClient.invalidateQueries(QUERY_KEYS.COMPANY_ROLES); // Triggers the "/roles/all"
        queryClient.invalidateQueries(QUERY_KEYS.SERVERS); // Triggers the "/servers/list"
        queryClient.invalidateQueries(QUERY_KEYS.PLANS); // Triggers the "/plans/list"
        queryClient.invalidateQueries(QUERY_KEYS.ACCESS_KEYS); // Triggers the "/access-keys/list
        queryClient.invalidateQueries(QUERY_KEYS.CAMERAS); // Triggers the "/cameras/list"
      });

      setLocalStorage('master_filter', 'value', masterFilter); // VA, SA
      const url = getLocalStorage('master_filter', `${masterFilter}_path`);

      // Sorry Neptun: if we get a logout request, I need to handle that first
      if (currentPage === '/logout') {
        history.push('/logout');
      } else if (url) {
        history.push(url);
      } else if (
        currentPage !== '/home' &&
        currentPage !== '/' &&
        currentPage !== url
      ) {
        const selectPagePanel = getPath({ item: select_page_panel });
        history.push(selectPagePanel);
      }
    }
  }, [masterFilter]);

  useEffect(() => {
    if (!hasPermissionForShopAssist) setMasterFilter('VA');
  }, [hasPermissionForShopAssist, company]);

  useEffect(() => {
    if (globalCamera) {
      const cameraPipelineStatus = findPipelineStatusForGivenCameraName(
        globalCamera.display_name.split(' ')[1]
      );
      setGlobalCameraPipelineStatus(cameraPipelineStatus);
    }
  }, [pipelineStatusData, globalCamera]);

  useEffect(() => {
    if (userInfo) {
      const get_role = userInfo?.roles;
      const role_string = JSON.stringify(get_role);
      const isInternalRole =
        userInfo?.user_type === USER_TYPE.Admin ||
        userInfo?.user_type === USER_TYPE.Support ||
        role_string.includes('RAI_ADMIN') ||
        role_string.includes('Support');
      setInternalRole(isInternalRole);

      setIsReadOnlyRole(role_string.includes('Read-Only'));

      const pagesActions = userInfo.pages.reduce((acc, page) => {
        acc[page.name] = page.actions;
        return acc;
      }, {});
      setPagePermission(pagesActions);
    }
  }, [userInfo, globalLocation]);

  useEffect(() => {
    getIdTokenClaims().then(data => {
      setAuth0AccessToken(data?.__raw);
    });
    if (user?.email) localStorage.setItem('email', user?.email);
    if (user?.picture) localStorage.setItem('picture', user?.picture);
    if (user?.name) localStorage.setItem('name', user?.name);
  }, [user?.email]);
  const date = new Date();
  const client_datetime = dateFormat(date, 'yyyy-mm-dd HH:MM:ss');
  const {
    data,
    isLoading: isUserLoginLoading,
    refetch: refetchUserData,
    isRefetching: isUserInfoRefetching,
  } = useQuery(
    [QUERY_KEYS.USER_INFO, token],
    () =>
      apiFetch(API_URL.getUserInit(), {
        id_token: token,
        client_ts: client_datetime,
      }),
    {
      enabled: !stopInitCall && !!token && user?.email_verified,
    }
  );

  useEffect(() => {
    if (
      data?.user === 'not-found' &&
      !data?.status &&
      window.location.href.indexOf('user_is_not_available') === -1
    ) {
      // eslint-disable-next-line no-alert
      alert(
        'You are logged in, but the user is not found in this domain record.' +
          '\nProbably you are not at the right domain.' +
          '\nNothing is allowed to use!' +
          '\nPlease contact your administrator and provide the following ticket helper code: T-UM-231'
      );
      // T-UM-231 Description: Dear Admin: Add the user in the User page with the given email at the right domain.
      // This will fix the problem.
    } else if (
      data?.user === 'not-found' &&
      data?.status === 1 &&
      window.location.href.indexOf('user_is_not_available') === -1
    ) {
      // eslint-disable-next-line no-alert
      alert(
        'You are logged in as "Inactive" mode.' +
          '\nNothing is allowed to use!' +
          '\nPlease contact your admin, supervisor or lead.'
      );
    } else if (
      data?.user === 'not-found' &&
      data?.status === 2 &&
      window.location.href.indexOf('user_is_not_available') === -1
    ) {
      // eslint-disable-next-line no-alert
      alert(
        'You are logged in, but you are in "Archived" mode.' +
          '\nNothing is allowed to use!' +
          '\nPlease contact your admin, supervisor or lead.'
      );
    }
    if (
      data?.user === 'not-found' &&
      window.location.href.indexOf('user_is_not_available') === -1
    )
      window.location.href = window.location.origin + '/user_is_not_available/';

    const userJWT = data?.jwt_token;
    if (userJWT) {
      api.defaults.headers = {
        Authorization: `Bearer ${userJWT}`,
      };
      queryClient.setDefaultOptions({
        queries: {
          refetchOnWindowFocus: false,
          retry: false,
          enabled: !!userJWT,
        },
      });
      setJwtToken(userJWT);
      localStorage.setItem('jwt', userJWT);
    }

    if (data && data.company) {
      cookie.save(COOKIE_COMPANY_ID, data.company.id, { path: '/' });
      setUserInfo({ ...data });

      // Check if the user only has retail-app access and redirect to the retail app
      if (
        data.pages.length === 1 &&
        data.pages[0].name === 'retail_app_access'
      ) {
        if (data.company.id === 8) {
          window.location.replace('https://retail.radius.ai/retail');
        } else {
          window.location.replace('https://retail-app.radius.ai/retail');
        }
      }
    }

    if (
      data?.integrator_terms_confirm_needed !== undefined &&
      data.integrator_terms_confirm_needed
    ) {
      setTermsStatus(true);
    }
  }, [data]);

  const {
    data: fetchedUserLocations,
    isFetching: isUserLocationsFetching,
    isLoading: isUserLocationsLoading,
    refetch: refetchUserLocations,
  } = useProxyQuery([QUERY_KEYS.GLOBAL_LOCATION, auth0AccessToken], () =>
    apiFetch(API_URL.getAllUserLocations(), {})
  );

  useEffect(() => {
    if (!isUserLocationsFetching || !isUserLocationsLoading)
      setUserLocations(fetchedUserLocations);
  }, [fetchedUserLocations]);

  const {
    data: fetchedUserCameras,
    refetch: refetchUserCameras,
    isLoading: isUserCamerasLoading,
    isFetching: isUserCamerasFetching,
  } = useFetchCamerasByLocation(globalLocation?.id, {
    enabled: Boolean(globalLocation?.id),
  });

  useEffect(() => {
    if ((!isPageActionsLoading || !isUserCamerasFetching) && fetchedUserCameras)
      setUserCameras(fetchedUserCameras);
    if (isUserCamerasFetching) setCameraOptions([]);
  }, [fetchedUserCameras]);

  useEffect(() => {
    const isCameraExist = userCameras?.some(
      camera => camera?.id === globalCamera?.id
    );
    if (!isCameraExist) {
      setGlobalCamera(null);
    }
  }, [globalLocation]);

  const { data: pagesActions, loading: isPageActionsLoading } =
    useFetchPagesActions();

  useEffect(() => {
    if (pagesActions) {
      const { pages, actions } = pagesActions;
      const pageMap = pages.reduce((acc, page) => {
        acc[page.id] = page.name;
        acc[page.name] = page.id;
        return acc;
      }, {});
      const actionMap = actions.reduce((acc, action) => {
        acc[action.id] = action.name;
        acc[action.name] = action.id;
        return acc;
      }, {});

      setPageIdMap(pageMap);
      setActionIdMap(actionMap);
    }
  }, [pagesActions]);

  return (
    <AppContext.Provider
      id='gkbejbnshl'
      value={{
        //USER
        userInfo,
        setUserInfo,
        userPages: userInfo?.pages || [],
        refetchUserData,
        jwtToken,
        auth0AccessToken,
        termsStatus,
        setCompany,
        company,
        //LOCATION
        globalLocation,
        setGlobalLocation,
        userLocations,
        setUserLocations,
        refetchUserLocations,
        isUserLocationsFetching,
        isUserLocationsLoading,
        //CAMERAS
        globalCamera,
        setGlobalCamera,
        globalCameraPipelineStatus,
        userCameras,
        isUserCamerasLoading,
        isUserCamerasFetching,
        isUserLoginLoading,
        isUserInfoRefetching,
        refetchUserCameras,
        setCameraOptions,
        cameraOptions,
        setWrongCameraDialog,
        wrongCameraDialog,
        //SIDEBAR
        isSidebarOpened,
        setIsSidebarOpened,
        //CAMERA OBJECTIVES SELECTS VALUES
        cameraObjectivesSelects,
        setCameraObjectivesSelects,
        //REST
        swapPool,
        setSwapPool,
        pagesActions,
        isPageActionsLoading,
        pageIdMap,
        actionIdMap,
        pagePermission,
        activeNotify,
        setActiveNotify,
        // FLOORPLAN CAMERA SELECTION
        setSelectedComponent,
        selectedComponent,
        setActiveButton,
        activeButton,
        setCameraView,
        cameraView,
        setLocationOptions,
        locationOptions,
        userAction,
        setUserAction,
        setIsCollapsed,
        isCollapsed,
        stopInitCall,
        setStopInitCall,
        isInternalRole,
        isReadOnlyRole,
        // MASTER FILTER
        masterFilter,
        setMasterFilter,
        setLocalStorage,
        getLocalStorage,
        removeLocalStorage,
        removeLocalStorageCacheKey,
        hasPermissionForShopAssist,
        setHasPermissionForShopAssist,
        // serverType (default to 'va1')
        serverType,
        setServerType,
        ...rest,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export function useAppContext() {
  return useContext(AppContext);
}
