// File: routesList.js
// Permissions
import { PAGES_NAMES } from '../permissions';

class Routes {
  list = {
    home: {
      path: 'home',
      name: 'Home',
      icon: null,
      parent: null,
      isRedirected: false,
      pageName: PAGES_NAMES.home,
    },
    app: {
      path: 'app',
      name: 'App',
      icon: null,
      parent: null,
      isRedirected: false,
    },
    select_page_panel: {
      path: 'select_page_panel',
      name: 'Select a Page',
      icon: null,
      parent: 'app',
      isRedirected: false,
    },
    no_access_userandrole_page: {
      path: 'no-access-userandrole-page',
      name: 'No access to Users & Roles page',
      icon: null,
      parent: null,
      isRedirected: false,
    },
    no_access: {
      path: 'no-access',
      name: 'No access',
      icon: null,
      parent: null,
      isRedirected: false,
    },
    no_email_verified: {
      path: 'no-email-verified',
      name: 'Email not verified',
      icon: null,
      parent: null,
      isRedirected: false,
    },
    kiosk_management: {
      path: 'kiosk_management',
      name: 'Kiosk management',
      icon: 'mappin',
      parent: 'app',
      isRedirected: false,
      pageName: PAGES_NAMES.app,
    },
    tags: {
      path: 'tags',
      name: 'Tags',
      icon: 'tag',
      parent: 'app',
      isRedirected: false,
    },
    locations_and_cameras: {
      path: 'locations_and_cameras',
      name: 'Locations & Cameras',
      icon: 'ListIcon',
      parent: 'app',
      isRedirected: false,
      pageName: PAGES_NAMES.locations_and_cameras,
      masterFilter: ['VA', 'SA'],
    },
    locations_and_groups: {
      path: 'locations_and_groups',
      name: 'Locations & Groups',
      icon: null,
      parent: 'locations_and_cameras',
      isRedirected: true,
      pageName: PAGES_NAMES.locations_and_groups,
      masterFilter: ['VA', 'SA'],
    },
    cameras: {
      path: 'cameras',
      name: 'Cameras',
      icon: null,
      parent: 'locations_and_cameras',
      isRedirected: true,
      pageName: PAGES_NAMES.cameras,
      masterFilter: ['VA', 'SA'],
    },
    servers: {
      path: 'servers',
      name: 'Servers',
      icon: null,
      parent: 'locations_and_cameras',
      isRedirected: true,
      pageName: PAGES_NAMES.servers,
      masterFilter: ['VA', 'SA'],
    },
    access_keys: {
      path: 'access_keys',
      name: 'Access Keys',
      icon: null,
      parent: 'locations_and_cameras',
      isRedirected: true,
      pageName: PAGES_NAMES.access_keys,
      masterFilter: ['VA'],
    },
    user_management: {
      path: 'user_management',
      name: 'User Management',
      icon: 'UsersIcon',
      parent: 'app',
      isRedirected: false,
      pageName: PAGES_NAMES.user_management,
      masterFilter: ['VA'],
    },
    add_new_role: {
      path: 'role/add',
      name: 'Add New Role',
      icon: null,
      parent: 'users_and_roles',
      isRedirected: false,
      pageName: PAGES_NAMES.add_new_role,
      masterFilter: ['VA'],
    },
    update_role: {
      path: 'role/:id/edit',
      name: 'Edit Role',
      icon: null,
      parent: 'users_and_roles',
      isRedirected: false,
      pageName: PAGES_NAMES.update_role,
      masterFilter: ['VA'],
    },
    users_and_roles: {
      path: 'users_and_roles',
      name: 'Users & Roles',
      icon: null,
      parent: 'user_management',
      isRedirected: true,
      pageName: PAGES_NAMES.users_and_roles,
      masterFilter: ['VA'],
    },
    roles: {
      path: 'roles',
      name: 'Roles',
      icon: null,
      parent: 'users_and_roles',
      isRedirected: false,
      pageName: PAGES_NAMES.roles,
      masterFilter: ['VA'],
    },
    users: {
      path: 'users',
      name: 'Users',
      icon: null,
      parent: 'users_and_roles',
      isRedirected: false,
      pageName: PAGES_NAMES.users,
      masterFilter: ['VA'],
    },
    history: {
      path: 'history',
      name: 'History',
      icon: 'SimpleHistoryIcon',
      parent: 'app',
      isRedirected: false,
      pageName: PAGES_NAMES.history,
      masterFilter: ['VA'],
    },
    user_write_log: {
      path: 'user_write_log',
      name: 'User Write Log',
      icon: null,
      parent: 'history',
      isRedirected: true,
      pageName: PAGES_NAMES.user_write_log,
      masterFilter: ['VA'],
    },
    version_control: {
      path: 'version_control',
      name: 'Version Control',
      icon: null,
      parent: 'history',
      isRedirected: true,
      pageName: PAGES_NAMES.version_control,
      masterFilter: ['VA'],
    },
    template_management: {
      path: 'template_management',
      name: 'Template Management',
      icon: 'TemplateSimpleIcon',
      parent: 'app',
      isRedirected: false,
      pageName: PAGES_NAMES.template_management,
      masterFilter: ['VA'],
    },
    templates: {
      path: 'templates',
      name: 'Templates',
      parent: 'template_management',
      isRedirected: true,
      pageName: PAGES_NAMES.templates,
      masterFilter: ['VA'],
    },
    camera_objectives: {
      path: 'camera_objectives',
      name: 'Camera Objectives',
      icon: null,
      parent: 'template_management',
      isRedirected: true,
      pageName: PAGES_NAMES.camera_objectives,
      masterFilter: ['VA'],
    },
    floorplan_editor: {
      path: 'floorplan_editor',
      name: 'Floor Plan Editor',
      icon: null,
      parent: 'template_management',
      isRedirected: true,
      pageName: PAGES_NAMES.floorplan_editor,
      masterFilter: ['VA'],
    },
    overlay_tools: {
      path: 'overlay_tools',
      name: 'Overlay Tools',
      icon: 'CameraIcon',
      parent: 'app',
      isRedirected: false,
      pageName: PAGES_NAMES.overlay_tools,
      masterFilter: ['VA'],
    },
    plane_calibration: {
      path: 'plane_calibration',
      name: 'Plane Calibration',
      icon: null,
      parent: 'overlay_tools',
      isRedirected: true,
      pageName: PAGES_NAMES.plane_calibration,
      masterFilter: ['VA'],
    },
    plane_and_normal: {
      path: 'plane_and_normal',
      name: 'Plane and Normal',
      icon: null,
      parent: 'overlay_tools',
      isRedirected: true,
      pageName: PAGES_NAMES.plane_and_normal,
      masterFilter: ['VA'],
    },
    detection_polygon: {
      path: 'detection_polygon',
      name: 'Detection Polygon',
      icon: null,
      parent: 'overlay_tools',
      isRedirected: true,
      pageName: PAGES_NAMES.detection_polygon,
      masterFilter: ['VA'],
    },
    aoi_selection: {
      path: 'aoi_selection',
      name: 'AOI Selection',
      icon: null,
      parent: 'overlay_tools',
      isRedirected: true,
      pageName: PAGES_NAMES.aoi_selection,
      masterFilter: ['VA'],
    },
    floor_projection: {
      path: 'floor_projection',
      name: 'Floor Projection Zone',
      icon: null,
      parent: 'overlay_tools',
      isRedirected: true,
      pageName: PAGES_NAMES.floor_projection,
      masterFilter: ['VA'],
    },
    // Commented until we use this page
    // object_markers: {
    //   path: 'object_markers',
    //   name: 'Object Markers',
    //   icon: null,
    //   parent: 'overlay_tools',
    //   isRedirected: true,
    //   pageName: PAGES_NAMES.object_markers,
    // },
    installation_management: {
      path: 'installation_management',
      name: 'Installation Management',
      icon: 'DataIcon',
      parent: 'app',
      isRedirected: false,
      pageName: PAGES_NAMES.installation_management,
      masterFilter: ['VA'],
    },
    camera_view_assist: {
      path: 'camera_view_assist',
      name: 'Camera View Assist',
      parent: 'installation_management',
      isRedirected: true,
      pageName: PAGES_NAMES.camera_view_assist,
      masterFilter: ['VA'],
    },
    installation_support: {
      path: 'installation_support',
      name: 'Installation Support',
      icon: null,
      parent: 'installation_management',
      isRedirected: true,
      pageName: PAGES_NAMES.installation_support,
      masterFilter: ['VA'],
    },
    camera_validations: {
      path: 'camera_validations',
      name: 'Camera Validations',
      parent: 'installation_management',
      isRedirected: true,
      pageName: PAGES_NAMES.camera_validations,
      masterFilter: ['VA'],
    },
    server_provisioning: {
      path: 'server_provisioning',
      name: 'Server Provisioning',
      parent: 'installation_management',
      isRedirected: true,
      pageName: PAGES_NAMES.server_provisioning,
      masterFilter: ['VA'],
    },
    location_status: {
      path: 'location_status',
      name: 'Location Status',
      icon: 'LocationIcon',
      parent: 'app',
      isRedirected: true,
      pageName: PAGES_NAMES.location_status,
      masterFilter: ['VA'],
    },
    help: {
      path: 'help',
      name: 'Help',
      icon: 'NoticeIcon',
      parent: 'app',
      isRedirected: false,
      pageName: PAGES_NAMES.help,
      masterFilter: ['VA', 'SA'],
    },
    info_center: {
      path: 'info_center',
      name: 'Info Center',
      icon: null,
      parent: 'help',
      isRedirected: true,
      pageName: PAGES_NAMES.info_center,
      masterFilter: ['VA', 'SA'],
    },
    terms: {
      path: 'terms',
      name: 'Terms and Conditions',
      icon: null,
      parent: null,
      isRedirected: false,
      masterFilter: ['VA', 'SA'],
    },
    shopassist_health: {
      path: 'shopassist_health',
      name: 'ShopAssist Health',
      icon: 'ListIcon',
      parent: 'app',
      isRedirected: true,
      pageName: PAGES_NAMES.shopassist_health,
      masterFilter: ['SA'],
    },
  };

  getRelativePath = ({ path, item }) =>
    path === '/' ? `${path}${item.path}` : `${path}/${item.path}`;

  getPath = ({ item, stop, forwardSlash = true }) => {
    let parent = this.list[item.parent];
    let { path } = item;

    while (parent || parent !== stop) {
      path = `${parent.path}/${path}`;

      parent = this.list[parent.parent];
    }

    return forwardSlash ? `/${path}` : path;
  };

  getCategoryPath = ({ item, forwardSlash = true, star = true }) => {
    return forwardSlash
      ? `/${item.path}${star ? '/*' : ''}`
      : `${item.path}${star ? '/*' : ''}`;
  };
}

const routeService = new Routes();

export { routeService };
